<template>
  <div class="root" v-if="this.dados">
    <div class="header">
      <img class="imgLogo" :src="this.dados.logoHotel" alt="logo hotel" />
      <div style="width: 1px; display: flex;justify-content: center; align-items: center;">
        <div style="width: 1px; background-color: white;height: 80%;"></div>
      </div>
      <img class="imgLogo" src="./Imagens/logowebcheckin.svg" alt="logo web checkin" />
  </div>
      <div class="containerDescricao">
        <img style="margin-top: 20px; width: 100%; border-radius: 10px;" :src="this.dados.page === 1 ? dados.imagem_hotel : dados.imagem_hotel2" alt="imagem hotel" />
        <p class="h2">{{this.dados.page === 1 ? dados.titulo_1 : dados.titulo_2}}</p>
        <div class="descricao">
          <p class="p">{{this.dados.page === 1 ? dados.descricao_1 : dados.descricao_2}}</p>
        </div>
        <div class="containerButtonProximo">
          <img v-if="this.dados.page === 1" src="./Imagens/ARRASTAR.svg" />
          <img v-if="dados.page !== 1" src="./Imagens/ARRASTAR2.svg" />
          <button type="button" class="buttonProximo" >{{this.dados.page === 1 ? 'Próximo' : 'Iniciar Check-in'}}</button>
        </div>
      </div> 
  </div>
</template>

<script>
  export default {
    props:['dados'],
    data: () => ({
    })
  }
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');
.root {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(175, 175, 175);
  width: 320px;
  height: 500px;
  overflow-y: auto;
  white-space: pre-line;
}

.header {
  display: flex;
  background-color: #156CF7;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 0px 0px 25px 25px;
  padding: 10px;
}

.imgLogo {
  margin: 10px;
  width: 100px;
}

.containerDescricao {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.descricao {
  width: 100%;
  word-wrap: break-word;
  white-space: normal
}

.containerButtonProximo {
  background-color: #EFF0F2;
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  font-size: small;
  font-weight: 100;
}

.buttonProximo {
  background-color: #156CF7;
  border: none;
  border-radius: 20px;
  color:white;
  padding-right: 15px;
  padding-left: 15px;
  height: 30px;
}


.h2 {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #3F4849;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}

.p {
  margin: 0;
  padding: 0;
  color: #5D5D5D;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  white-space: pre-line;
}
</style>
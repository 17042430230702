// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
//import { Store } from 'vuex'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
    login({ commit }, userData) {
        //console.log(userData)
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/auth/login', { apelido: userData.username, senha: userData.password })
                .then(response => {
                    const token = response.data.token
                    const user = response.data.nome
                    const idioma = response.data.idioma
                    const avatar = response.data.avatar
                    const email = response.data.email
                    const id = response.data.id
                    const hotel = response.data.hotel
                    const idhotelpms = response.data.idhotelpms
                    const nomehotel = response.data.nomehotel
                    const ativarimportacoes = response.data.ativarimportacoes
                    const apelido = response.data.apelido
                    const tipointegracao = response.data.tipointegracao
                    const usarobo = response.data.usarobo
                    const rotulodatafim = response.data.rotulodatafim
                    //console.log('idHotel=' + response.data.hotel)
                    // storing jwt in localStorage. https cookie is safer place to store
                    localStorage.setItem('token', token)
                    localStorage.setItem('x-access-token', token)
                    localStorage.setItem('user', user)
                    localStorage.setItem('avatar', avatar)
                    localStorage.setItem('idioma', idioma)
                    localStorage.setItem('useremail', email)
                    localStorage.setItem('useremail', email)
                    localStorage.setItem('userid', id)
                    localStorage.setItem('idioma', idioma)
                    localStorage.setItem('hotel', hotel)
                    localStorage.setItem('idhotelpms', idhotelpms)
                    localStorage.setItem('nomehotel', nomehotel)
                    localStorage.setItem('ativarimportacoes', ativarimportacoes)
                    localStorage.setItem('apelido', apelido)
                    localStorage.setItem('tipointegracao', tipointegracao)
                    localStorage.setItem('usarobo', usarobo)
                    localStorage.setItem('rotulodatafim', rotulodatafim)
                    axios.defaults.headers.common['x-access-token'] = token
                    // mutation to change state properties to the values passed along
                    commit('auth_success', { token, user })
                    resolve(response)
                })
                .catch(err => {
                    console.log('login error')
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.clear()
                    reject(err)
                })
        })
    },

    passwordRecovery({ commit }, userData) {
        //console.log(userData)
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/auth/checkuser', { usuario: userData.email })
                .then(() => {
                    // 
                    // sendEmailPassword()
                    axios.post('/auth/recuperarsenha', { email: userData.email })
                        .then(response => {
                            resolve(response)
                        })
                        .catch(err => {
                            console.log('Erro ao enviar o email')
                            reject(err)
                        })
                })
                .catch(err => {
                    console.log('Email não encontrado')
                    reject(err)
                })
        })
    },

    sendEmailPassword({ commit }, userData) {
        //console.log(userData)
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/auth/recuperarsenha', { email: userData.email })
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log('Erro ao enviar o email')
                    reject(err)
                })
        })
    },



    logout({ commit }) {

        return new Promise((resolve) => {
            localStorage.clear()
            delete axios.defaults.headers.common['x-access-token']
            localStorage.removeItem('token')
            localStorage.removeItem('x-access-token')
            localStorage.removeItem('user')
            localStorage.removeItem('avatar')
            localStorage.removeItem('idioma')
            localStorage.removeItem('useremail')
            localStorage.removeItem('useremail')
            localStorage.removeItem('userid')
            localStorage.removeItem('idioma')
            localStorage.removeItem('hotel')
            localStorage.removeItem('idhotelpms')
            localStorage.removeItem('nomehotel')
            localStorage.removeItem('ativarimportacoes')
            localStorage.removeItem('apelido')
            localStorage.removeItem('tipointegracao')
            localStorage.removeItem('usarobo')
            localStorage.removeItem('rotulodatafim')
            axios.defaults.headers.common['x-access-token'] =  null
            commit('logout', commit)
            resolve()
        })
    },
    /*   refreshtoken ({ commit }) {
        axios.get('/refresh')
          .then(response => {
            const token = response.data.access_token
            localStorage.setItem('token', token)
            localStorage.setItem('x-access-token', token)
            axios.defaults.headers.common['x-access-token'] = token
            commit('auth_success', { token })
          })
          .catch(error => {
            console.log('refresh token error')
            commit('logout')
            localStorage.removeItem('token')
            console.log(error)
          })
      }, */
    getTableList({ commit }, tableName) {
        this.$http.get(`/${tableName}`)
            .then(response => {
                console.log(response)
                let tableList = response.data.Keys
                commit('setTableList', { tableList })
            })
            .catch(error => console.log(error))
    },
    updateTableItem(tableData) {
        return new Promise((resolve, reject) => {
            let httpmethod = tableData.method
            axios({ url: `/${tableData.endpoint}`, method: httpmethod, data: tableData.tableItem })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    // autoRefreshToken ({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     setInterval(function () {
    //       // code goes here that will be run every 20 minutes.
    //       axios.get('/refresh')
    //         .then(response => {
    //           const token = response.data.access_token
    //           localStorage.setItem('token', token)
    //           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    //           commit('auth_success', { token })
    //           resolve(response)
    //         })
    //         .catch(error => {
    //           console.log('refresh token error')
    //           console.log(error)
    //           reject(error)
    //         })
    //     }, 1200000)
    //   }
    //   )
    // },

    ,
    setHiddenDrawer({ commit }, payload) {
        commit('setHiddenDrawer', payload)
    },
    setPagina({ commit }, payload) {
        commit('setPagina', payload)
    },

    set_ultimostatus({ commit }, payload) { commit('mutate_ultimostatus', payload) },

    set_tipoacesso({ commit }, payload) { commit('mutate_tipoacesso', payload) },
}
<template>
  <v-dialog v-model="exibirModalEditarAPIWhatApp" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Configurações da API WhatsApp
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações da API WhatsApp"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalEditarAPIWhatsApp()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <!-- <v-card> -->
        <v-flex>
          <div
            style="
              margin-top: 20px;
              font-size: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <v-switch
            label="WhatsApp Ativo"
              v-model="ativo"
              data-cy="ativo"
            ></v-switch>
            <v-col>
              <v-text-field label="URL" v-model="url" data-cy="url" :disabled="!ativo">
              </v-text-field>
              <v-text-field label="Token" v-model="token" data-cy="token" :disabled="!ativo">
              </v-text-field>
              <v-text-field
                label="Instance ID"
                v-model="instanceId"
                data-cy="instanceId"
                :disabled="!ativo"
              >
              </v-text-field>
              <label>Texto Padrão WebCheckin WhatsApp</label>
              <v-textarea no-resize full-width counter="300" outlined v-model="textoPadraoWebCheckinWhatsApp" data-cy="textoPadraoWebCheckinWhatsApp" :disabled="!webCheckinWhatsAppAtivo" prepend-inner-icon="mdi-whatsapp">
              </v-textarea>
              <v-switch
              :disabled="!ativo"
              label="Enviar WebCheckin via WhatsApp"
                v-model="webCheckinWhatsAppAtivo"
                style="border-radius: 5px; padding: 5px"
                data-cy="webCheckinWhatsAppAtivo"
              ></v-switch>
              <label></label>
              <span
                >Não tenho ou não sei essas informações. Por favor, contate o
                <a
                  href="http://chat-api.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  >provedor de API</a
                >.</span
              >
            </v-col>
          </div>
        </v-flex>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn @click="salvarDadosAPI()" data-cy="salvarDadosAPI" color="primary"> Salvar </v-btn>
        </div>
        <!-- </v-card> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
export default {
  data: () => ({
    textoEmail:
      "Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
    isImg: null,
    imgSrc: null,
    imgTipo: null,
    mensagemPadrao: null,
    ativo: false,
    webCheckinWhatsAppAtivo : false,
    url: null,
    token: null,
    instanceId: null,
    EnviarMensagemAniversarioWhats: false,
    textoPadraoWebCheckinWhatsApp: "",
  }),
  props: ["exibirModalEditarAPIWhatApp"],
  components: {
    BotaoTutorial
},
  methods: {
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.ativo = resp.data[0].valor == "true" ? true : false;

            this.url = resp.data[0].valor2;

            this.token = resp.data[0].valor3;

            this.instanceId = resp.data[0].valor4;
            
            this.textoPadraoWebCheckinWhatsApp = resp.data[0].valor5

            this.webCheckinWhatsAppAtivo = resp.data[0].valor6 == "true" ? true : false;

          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salvarDadosAPI() {
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
          valor: this.ativo,
          valor2: this.url,
          valor3: this.token,
          valor4: this.instanceId,
          valor5: this.textoPadraoWebCheckinWhatsApp,
          valor6 : this.webCheckinWhatsAppAtivo
        })
        .then((resp) => {
          console.log(resp);
          this.setModalEditarAPIWhatsApp();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setModalEditarAPIWhatsApp() {
      this.$emit("setModalEditarAPIWhatsApp");
    },
  },
  mounted() {
    this.buscarDadosAPI();
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>
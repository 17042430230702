<template>
    <div>
        <div class="wout-login" v-if="componenteatual == 'avisoSelfie'">
            <div class="center">
                <div>
                    <img src="@/assets/img/prelogin.svg" 
                        width="auto" height="130" alt="Pré Login" crossorigin />
                </div>
                <span class="confirmar">Para melhor garantir a segurança desta transação, vamos tirar uma self, OK?</span>
                <button class="continuar" @click="avancar">
                    <span>Continuar</span>
                    <v-icon large class="icone-continuar">mdi-chevron-right</v-icon>
                </button>
            </div>
        </div>
        <SelecionarDocumento v-if="componenteatual == 'selecionarDoc'" @etapasConcluidas="capturaDeFotosConcluidas"/>
    </div>
</template>
<script>
    import SelecionarDocumento from './SelecionarDocumento.vue';
export default {
    components: {
      SelecionarDocumento
    },
    props:["dados"],
    data: () => {
        return {
            componenteatual: 'selecionarDoc'     
        }
    },
    methods: {
        capturaDeFotosConcluidas() {
            this.componenteatual = 'avisoSelfie';
        },
        avancar() {
            this.$emit('direcionar', {from: 'avisoself', to: 'self'})
        }
    },

    mounted() {
        console.log('selecionar doc')
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    width: 65vw;
    font-weight: normal;
    color: white!important;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 70vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 15px;
}
.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}
</style>
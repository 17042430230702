<template>
    <div class="captura_container" style="padding:0px;">
        <div class="captura_container_doc" style="padding:0px;margin:0px;width:100%;height:100%;">

            <div style="height:10%;margin-top:20px;">
                <h1>FOTO VERSO - {{tipoDocSelecionado}}</h1> 
            </div>

            <div style="height:60%;max-height:60%;">
                <template v-if="!imgSrcLocal">
                    <p>modelo</p>
                    <img v-if="tipoDocSelecionado == 'RG'" class="imgExemplo" src="/img/leitorweb/rg_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'OUTROS'" class="imgExemplo" src="/img/leitorweb/rg_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'CNH'" class="imgExemplo" src="/img/leitorweb/cnh_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'PASSAPORTE'" class="imgExemplo" src="/img/leitorweb/passaporte_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'DNI'" class="imgExemplo" src="/img/leitorweb/dni_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'RNE'" class="imgExemplo" src="/img/leitorweb/rne_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'OAB'" class="imgExemplo" src="/img/leitorweb/oab.jfif" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'MEDICINA'" class="imgExemplo" src="/img/leitorweb/rne_foto_verso.jpg" style="max-width:50vw;" >
                </template>
                <img v-if="imgSrcLocal" :src="'data:image.png;base64,' + imgSrcLocal" class="imgUpload" style="max-height:40vh;" />
            </div>

            <div style="height:15%;max-height:height:15%;   ">

                <label  v-if="!image" for="file-upload"  style="margin-top:10px;background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;cursor:pointer;">
                    <span style="font-size:18px;font-weight:bold;color:white;">{{btnMessage}}</span>
                </label>    
                <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" />
                
            </div>

             <div style="height:15%;max-height:15%;width:100%;">

                    <div style="display:flex;width:100%;padding:0px;margin:0px;width:100%;height:100%;justify-content:space-between;">

                        <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-enderecovoltar">
                                {{ $t('webcheckin.endereco.voltar') }}
                        </button>

                        <button v-if="imgSrcLocal && !lendoDoc" v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-enderecoavancar">
                                {{ $t('webcheckin.endereco.avancar') }}
                        </button>

                    </div>

            </div>

            <div style="height:15%;max-height:15%;width:100%;">
                
            </div>

            
        
            

            <!-- <button v-if="image" @click="removeImage" type="button" class="botaoRemover">Remover imagem</button>  -->

        </div>

        <!-- <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-enderecovoltar">
                    {{ $t('webcheckin.endereco.voltar') }}
            </button>

            <button v-if="imgSrcLocal && !lendoDoc" v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-enderecoavancar">
                    {{ $t('webcheckin.endereco.avancar') }}
            </button>
        </div>  -->

    </div>    
</template>

<script>
import {serializarImagemNew} from '../../utils/serializar'

export default {
    name: 'DocVerso',
    data: () => ({
        image: '',
        btnMessage:'Clique Aqui Para Enviar',
        lendoDoc: false,
        imgSrcLocal:null
    }),
    watch: {
        imgSrc(val) {
            this.imgSrcLocal = val;
        }
    },
    props:["tipoDocSelecionado","idHospede","idReserva","idReservaHospede","docverso","imgSrc"],
    methods: {
        setarPasso(valor){
            this.$emit('setarPasso',valor)
        },
        voltar() {
            this.$emit('direcionar','docfrente')
        },
        avancar() {
            if (this.docverso) {
                this.$emit('direcionar','nacionalidade')
            }else{
                alert('Envie a foto do verso para continuar')
            }
                
        },
        async manipularDirecionamento(idImagem){
            
            // se for DNI ou RNE irá fazer leitura OCR da frente
            if(this.tipoDocSelecionado == "DNI"  
                || this.tipoDocSelecionado == "RNE"
                || this.tipoDocSelecionado == "OAB"
                || this.tipoDocSelecionado == "MEDICINA"
            ){

                try {
                    
                
                    
                    //solicitar leitura OCR aqui
                    /// -->
                   
                    await this.$http.post('ocr/lerDocAuthSimples',{
                        token:'3c8478c895580214e0ff389448054854',
                        tipo_doc:this.tipoDocSelecionado,
                        lado:'VERSO',
                        id:idImagem,
                        idHospede:this.idHospede,
                        idReserva:this.idReserva,
                        idReservaHospede:this.idReservaHospede,
                    })
                    .then(resp =>{
                        if(resp.status == 200){
                            console.log("Leitura OCR REALIZADA")
                        }

                        this.btnMessage = 'Clique Aqui Para Enviar'
                        this.lendoDoc = false
                        
                        this.$emit('avancar')
                        this.$emit('direcionar','nacionalidade')
                        
                    }).catch(() =>{
                        this.btnMessage = 'Clique Aqui Para Enviar'
                        this.lendoDoc = false
                    })



                    // Finaliza
                    this.$emit('avancar')
                    this.setarPasso(0)

                } catch (error) {
                    console.log(' ocr error 2',error)
                    this.$emit('direcionar','nacionalidade')
                }

            }else{
                try {
                    
                
                    if(this.tipoDocSelecionado == "OUTROS"){
                        this.setarPasso(8)
                        this.btnMessage = 'Clique Aqui Para Enviar'
                        this.lendoDoc = false
                        this.$emit('direcionar','nacionalidade')
                    }else{
                
                        this.btnMessage = 'Clique Aqui Para Enviar'
                        this.lendoDoc = false

                        // se NÃO for DNI ou RNE então só finaliza
                        this.$emit('avancar')
                        this.setarPasso(0)
                        this.$emit('direcionar','nacionalidade')
                    }
                } catch (error) {
                    console.log(' ocr error 2',error)
                    this.$emit('direcionar','nacionalidade')
                }
            }
        },
        async setImage(e) {

            const file = e.target.files[0];

            if (!file.type.includes("image/")) {
                alert("Please select an image file");
                return;
            }

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                this.isImg = true;
                this.imgSrcLocal = event.target.result;
                let index = event.target.result.indexOf(",") + 1;
                this.imgSrcLocal = event.target.result.substring(index,event.target.result.length);
                if(this.imgSrcLocal){
                    this.salvaLogo(file.type)
                }
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        async salvaLogo(tipoImg) {
            try {
                
            
                if (!this.imgSrcLocal.length > 0) {
                    console.log("invalido");
                    return;
                }

                let dados =   {
                    // "id": this.docverso,
                    "token":'3c8478c895580214e0ff389448054854',
                    "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                    "tipo" : tipoImg,
                    "nome" : "DocumentoVerso",
                    "dados" : this.imgSrcLocal,
                    "tabela": "hospede",
                    "fk": "docverso",
                    "chave": "id",
                    "valor": this.idHospede
                }
                await this.$http.post("/imagem/upinsertAuthSimples",dados,
                    {timeout:100000 }
                    )
                    .then(async (response) => {

                        if(response.status == 200){
                            console.log("Documento Verso Salvo!")
                            this.manipularDirecionamento(this.docverso?this.docverso:response.data.result.imagem[0])
                            this.$emit('update')
                        }else{
                            console.log("Não foi possivel salvar Documento Verso!")
                        }
                    })
                    .catch(async (error) => {
                    console.log(error.response);
                    });

            } catch (error) {
                console.log('error ',error)
                this.$emit('direcionar','nacionalidade')
            }
        },
        onFileChange(e) {

            this.btnMessage = 'Aguarde, Carregando...'
            this.lendoDoc = true

            // serializarImagem(e, 0.7).then(resp =>{
            //     this.imgSrcLocal = resp.dados
            //     this.salvaLogo(resp.tipo);
            // }).catch(error =>{
            //     this.loading = false
            //     this.snack = true
            //     this.snackColor = "error" 
            //     this.snackText = error.message
            // })
            
            serializarImagemNew(e).then(async resp =>{
            
                this.imgSrcLocal = resp.dados
                await this.salvaLogo(resp.tipo);
                this.lendoDoc = false

            }).catch(error =>{
                this.btnMessage = 'Clique Aqui Para Enviar'
                this.lendoDoc = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })

        },
        createImage(file) {
            
            this.image = new Image();
            var reader = new FileReader();
            var vm = this;
        
            reader.onload = (e) => {
        
                //RESIZE
                var canvas_frente = document.createElement("canvas");
                var context_frente = canvas_frente.getContext("2d");
                const image_doc_frente = new Image();
                image_doc_frente.onload = () =>{

                        //se for deitado
                        if(image_doc_frente.width > image_doc_frente.height){
                            // canvas_frente.width = 1000;
                            // canvas_frente.height = 600;
                            canvas_frente.width = 1000;
                            canvas_frente.height = 600;
                            context_frente.drawImage(image_doc_frente, 0, 0, 1000, 600);
                        }else{
                            //se for em pe
                            // canvas_frente.width = 800;
                            // canvas_frente.height = 1000;

                            canvas_frente.width = 700;
                            canvas_frente.height = 800;
                            context_frente.drawImage(image_doc_frente, 0, 0, 700, 800);
                        }
                        

                        // this.realizarUpload(canvas_frente,this.$store.getters.get_id_web_checkin)
                        this.$emit('avancar')
                        this.setarPasso(0)
                        
                }
                image_doc_frente.src = e.target.result;
                //RESIZE
                vm.image = e.target.result;

            };
            reader.readAsDataURL(file);
        },
        removeImage: function () {
            this.image = '';
        }
    },
    mounted() {
        this.imgSrcLocal = this.imgSrc
        this.$store.dispatch("setHiddenDrawer", true)
    },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
         height: 80vh; 
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_doc{
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        margin-bottom: 1em;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
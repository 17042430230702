<template>
    <div>
        
        <div class="topoConfirmacao">
                <div class="topoCampoConfirmacao">
                    <!-- <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin /> -->
                    <img v-if="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" class="topoImglogo" alt="WEB CHECK-IN" crossorigin />
                </div>

                <div class="topoCampoConfirmacao">
                    <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin />
                    <!-- <img v-if="this.$store.state.img_hotel" :src="this.$store.state.img_hotel" class="topoImglogo" alt="HOTEL LOGO" crossorigin /> -->
                </div>
        </div>

        <div class="captura_container" >
            <h3 class="text-center" style="margin-bottom:20px;margin-left:10%;margin-right:10%;">Preencha o FORMULÁRIO COVID-19</h3>

            <div class="cardInputs">
                <label class="labels" style="font-size:16px!important;color:#1560E5!important;">{{perguntas_covid[0].texto}}</label>
                <v-select v-model="resposta1.texto" :items="possiveisrespostas" item-value="id" item-text="valor" return-object
                        class="" style="font-size:1.2em;" >
                        <!-- <option class="text-center" value="SELECIONE" >SELECIONE UMA OPÇÃO</option>
                        <option class="text-center" value="SIM" >SIM</option>
                        <option class="text-center" value="NÃO" >NÃO</option> -->
                </v-select>  
            </div>


            <div class="cardInputs">
                <label class="labels" style="font-size:16px!important;color:#1560E5!important;">{{perguntas_covid[1].texto}}</label>
                <v-select v-model="resposta2.texto" :items="possiveisrespostas" item-value="id" item-text="valor" return-object
                        class="" style="font-size:1.2em;padding-top:20px;" >
                        <!-- <option class="text-center" value="SELECIONE" >SELECIONE UMA OPÇÃO</option>
                        <option class="text-center" value="SIM" >SIM</option>
                        <option class="text-center" value="NÃO" >NÃO</option> -->
                </v-select>
            </div>

            <div class="cardInputs" >
                <label class="labels" style="font-size:16px!important;color:#1560E5!important;">{{perguntas_covid[2].texto}}</label>
                <v-select v-model="resposta3.texto"  :items="possiveisrespostas" item-value="id" item-text="valor" return-object
                        class="" style="font-size:1.2em;" >
                        <!-- <option class="text-center" value="SELECIONE" >SELECIONE UMA OPÇÃO</option>
                        <option class="text-center" value="SIM" >SIM</option>
                        <option class="text-center" value="NÃO" >NÃO</option> -->
                </v-select>
            </div>
            <button @click="avancar()"  type="button" class="botaoAzul" style="width:80%;max-width:400px;">Confirmar</button>
        </div>

    </div>

</template>

<script>
export default {
  data: () =>{
        return{
            perguntas_covid:[],
            respostas_covid:[],
            snack: false,
            snackColor: '',
            snackText: '',
            resposta1:'SELECIONE',
            resposta2:'SELECIONE',
            resposta3:'SELECIONE',
            possiveisrespostas : [  
                { 
                    id: "SELECIONE",
                    valor : "SELECIONE"
                },
                { 
                    id: "SIM",
                    valor : "SIM"
                },
                {
                    id : "NÃO",
                    valor: "NÃO"
                }
            ],
        }
  },
  mounted(){
        this.buscarPerguntas()
  },
  watch: {
    dados(val) {
      this.dados = val;
    },
  },
  props:["idHospede","idReserva","idReservaHospede","dados"],
  methods: {
        setarPasso(valor){
            this.$emit('setarPasso',valor)
        },
        buscarPerguntas(){
                this.$http.post("formulario/select",{id:1}).then(async resp =>{

                        this.perguntas_covid = await resp.data[0].perguntas.filter(p => {
                            return p.texto != undefined;
                        }).sort((a, b) => (a.texto > b.texto) ? 1 : -1);

                        this.perguntas_covid = await this.perguntas_covid.map(p => {
                            p.texto = p.texto.toUpperCase();
                            return p;
                        })

                        this.buscarRespostas()

                })
        },
        buscarRespostas(){
            this.$http.post("formulario/resposta/select",{id:1,resposta:{idrespondente:this.dados.idpessoa}}).then(resp =>{
                    this.respostas_covid = resp.data[0].respostas
                    this.respostas_covid = this.respostas_covid.map(p => {
                        p.texto = p.texto.toUpperCase();
                        return p;
                    })


                    this.resposta1 = 
                        this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[0].texto) ?
                            this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[0].texto): {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}



                    this.resposta2 = this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[1].texto) ?
                            this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[1].texto): {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta3 = this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[2].texto) ?
                            this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[2].texto): {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}


                    // alert(JSON.stringify(this.resposta1 ))
                    

            }).catch(()=>{
                    this.resposta1 = {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta2 = {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta3 = {id:null,idrespondente:this.dados.idpessoa ,ativo:true,texto:'SELECIONE'}

            })
        },
        validarFormulario(){

            // alert(JSON.stringify(this.resposta1))
            if(this.resposta1.texto == 'SELECIONE' || this.resposta2.texto == 'SELECIONE' || this.resposta3.texto == 'SELECIONE' ){
                // this.$swal(this.$t('Todas as respostas são obrigatórias'),this.$t('As respostas do formulário sobre a COVID-19 são obrigatórias.'),"error");
                alert("Todas as respostas são obrigatórias!")
                return false
            }

            return true
            
        },
        async salvarDados(){
            if(this.resposta1.id){
                let respostaobj1 = typeof this.resposta1.texto == 'string'? this.resposta1.texto :this.resposta1.texto.valor
                await this.$http.post("formulario/resposta/upinsert",
                        {id:this.resposta1.id,pergunta:this.perguntas_covid[0].id, resposta:{resposta:respostaobj1}, 
                        respondente:this.dados.idpessoa,ativo:this.resposta1.ativo, idioma:'pt-BR'})
            }else{
                let respostaobj1 = typeof this.resposta1.texto == 'string'? this.resposta1.texto :this.resposta1.texto.valor
                await this.$http.post("formulario/resposta/upinsert",
                        {pergunta:this.perguntas_covid[0].id, resposta:{resposta:respostaobj1}, respondente:this.dados.idpessoa,
                        ativo:this.resposta1.ativo, idioma:'pt-BR'})
            }

            if(this.resposta2.id){
                let respostaobj2 = typeof this.resposta2.texto == 'string'? this.resposta2.texto :this.resposta2.texto.valor
                await this.$http.post("formulario/resposta/upinsert",
                        {id:this.resposta2.id,pergunta:this.perguntas_covid[1].id, resposta:{resposta:respostaobj2}, 
                        respondente:this.dados.idpessoa,ativo:this.resposta2.ativo, idioma:'pt-BR'})
            }else{
                let respostaobj2 = typeof this.resposta2.texto == 'string'? this.resposta2.texto :this.resposta2.texto.valor
                await this.$http.post("formulario/resposta/upinsert",
                        {pergunta:this.perguntas_covid[1].id, resposta:{resposta:respostaobj2}, respondente:this.dados.idpessoa,
                        ativo:this.resposta2.ativo, idioma:'pt-BR'})
            }

            if(this.resposta3.id){
                let respostaobj3 = typeof this.resposta3.texto == 'string'? this.resposta3.texto :this.resposta3.texto.valor
                await this.$http.post("formulario/resposta/upinsert",
                        {id:this.resposta3.id,pergunta:this.perguntas_covid[2].id, resposta:{resposta:respostaobj3}, 
                        respondente:this.dados.idpessoa,ativo:this.resposta3.ativo, idioma:'pt-BR'})
            }else{
                let respostaobj3 = typeof this.resposta3.texto == 'string'? this.resposta3.texto :this.resposta3.texto.valor
                await this.$http.post("formulario/resposta/upinsert",
                        {pergunta:this.perguntas_covid[2].id, resposta:{resposta:respostaobj3}, respondente:this.dados.idpessoa,
                        ativo:this.resposta3.ativo, idioma:'pt-BR'})
            }

            // controle de direcionamento 
            this.setarPasso(6)
            
        },
        async avancar(){
           
            if( await this.validarFormulario() ){
                this.salvarDados()
            } 
 
        }

  },
  
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    .cardInputs{
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        width: 90%;
        max-width: 400px;
        margin-bottom: 20px;;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:2%;
        padding-right:2%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .captura_container_confirmacao_dados{
        /* background-color:#b82323; */
        border-radius:5px;
        width:80vw;
        max-width: 500px;
        min-height:60vh;
        max-height: 70vh;
        padding:5%;
        display:flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content:flex-start;
        overflow: auto;
    }

    .lblConfirmacaoDados{
        font-weight:bold;
        font-size:20px;
        font-family:Arial, Helvetica, sans-serif;
    }

    .textoConfirmacaoDados{
        padding-left:10px;
        font-size:18px;
        font-family:Arial, Helvetica, sans-serif;
        margin-bottom:10px;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoAzul{
        background-color:#1560e5;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
import { render, staticRenderFns } from "./DocVerso.vue?vue&type=template&id=057d7603&scoped=true&"
import script from "./DocVerso.vue?vue&type=script&lang=js&"
export * from "./DocVerso.vue?vue&type=script&lang=js&"
import style0 from "./DocVerso.vue?vue&type=style&index=0&id=057d7603&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057d7603",
  null
  
)

export default component.exports
<template>
    <div style="width:100%;"> 
        <div style="width:70%;margin: 0 auto;">
            <!-- TOPO -->
            <div style="height:50vh;max-height:280px;margin-bottom:20px;background-size: 100% 100%;background-image: url('');">
                
                <table style="width:100%;margin-top:20px;height:100%;">
                    <tr style="width:100%;">
                        <td style="width:40%;text-align:center;">
                            <div style="font-size:25px;color:white;color:#0784a9;width:80%;margin:0 auto;">Relatorio Mensal Ficha Digital</div>
                            <div style="font-size:15px;color:white;color:#013544;width:80%;margin:0 auto;">Periodo de 01/03/2022 a 31/03/2022</div>
                            <div style="font-size:12px;color:#004052;background-color:#ffeb3b;width:50%;margin: 10px auto;padding-top:8px;border-radius:5px;height:30px;">Confira o Resultado</div>
                             
                        </td>
                        <td style="width:60%;background-image: url('https://esmeraldapraiahotel.com.br/pt/imgemail/imgtopo.png');background-size: 100% 100%;">
                            
                        </td>
                    </tr>
                </table>
            </div>

            <!-- CONTEUDO -->
            <div style="margin-bottom:20px;">
               <table style="width:100%;margin-bottom:20px;margin:10px auto;">
                    <!-- ITEM -->
                    <tr style="width:100%;border:border:none;;">
                        
                            <td style="width:40%;text-align:center;">
                                <div style="width:100%;height:200px;background-color:#00AADC;border-radius:5px;">
                                    <table style="width:80%;margin:0 auto;">
                                        <tr>
                                            <td>
                                                <img src="https://esmeraldapraiahotel.com.br/pt/imgemail/reservasimportadas.png" style="margin-top:20px;width:100%;border-radius:5px;" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:white;">
                                                <div style="margin-top:0px;">
                                                    <span style="font-size:20px;">Reservas Importadas</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    
                                </div>              
                            </td>
                            <td style="width:60%;">
                                <table style="width:80%;margin:0 auto;">
                                        <tr>
                                            <td >
                                                <div style="color:#0784a9;font-size:25px;text-align:center;">Quantidade de Reservas Importadas</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:black;">
                                                <div style="text-align:center;margin-top:10px;font-size:30px;">
                                                    3154
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                            </td>
                       
                    </tr>
                    <br>
                    <!-- FIM ITEM -->
                    <!-- ITEM -->
                    <tr style="width:100%;border:none;">
                        
                            <td style="width:40%;text-align:center;">
                                <div style="width:100%;height:200px;background-color:#07bd98;border-radius:5px;">
                                    <table style="width:80%;margin:0 auto;">
                                        <tr>
                                            <td>
                                                <img src="https://esmeraldapraiahotel.com.br/pt/imgemail/reservasimportadas.png" style="margin-top:20px;width:100%;border-radius:5px;" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:white;">
                                                <div style="margin-top:0px;">
                                                    <span style="font-size:20px;">Hospedes Importados</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    
                                </div>              
                            </td>
                            <td style="width:60%;">
                                <table style="width:80%;margin:0 auto;">
                                        <tr>
                                            <td >
                                                <div style="color:#0784a9;font-size:25px;text-align:center;">Quantidade de Fichas Importadas</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:black;">
                                                <div style="text-align:center;margin-top:10px;font-size:30px;">
                                                    64587
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                            </td>
                       
                    </tr>
                    <br>
                    <!-- FIM ITEM -->

                    <!-- ITEM -->
                    <tr style="width:100%;border:none;">
                        
                            <td style="width:40%;text-align:center;">
                                <div style="width:100%;height:200px;background-color:#d76c06;border-radius:5px;">
                                    <table style="width:80%;margin:0 auto;">
                                        <tr>
                                            <td>
                                                <img src="https://esmeraldapraiahotel.com.br/pt/imgemail/reservasimportadas.png" style="margin-top:20px;width:100%;border-radius:5px;" alt="">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:white;">
                                                <div style="margin-top:0px;">
                                                    <span style="font-size:20px;">Checkins Realizados</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    
                                </div>              
                            </td>
                            <td style="width:60%;">
                                <table style="width:80%;margin:0 auto;">
                                        <tr>
                                            <td >
                                                <div style="color:#0784a9;font-size:25px;text-align:center;">Quantidade de Fichas Preenchidas</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color:black;">
                                                <div style="text-align:center;margin-top:10px;font-size:30px;">
                                                    3154 de  64587  
                                                    <br><span style="color:#6b7172;"> 58%</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                            </td>
                       
                    </tr>
                    <br>
                    <!-- FIM ITEM -->

                </table>
            </div>

            <!-- ECONOMIA -->
            <div style="background-color:#f9f9f9;margin-bottom:100px;text-align:center;color:#0784a9;">
                <span style="font-size:40px;">Economia Gerada</span>
                <div>
                    <table style="width:100%;">
                        <tr>
                            <td style="width:50%;">
                                <div style="font-size:20px;">Papeis Economizados</div>
                                <div style="font-size:30px;">3154</div>
                            </td>
                            <td style="width:50%;">
                                <div style="font-size:20px;">Valor Economizado</div>
                                <div style="background-color:#009906;color:white;height:50px;padding-top:10px;font-size:30px;">R$ 3215,25</div>
                            </td>
                        </tr>
                    </table>
                    
                    
                </div>
            </div>

            <div style="margin-bottom:100px;">
                <div>
                    <table style="width:100%;">
                        <tr>
                            <td style="height:150px;width:33.3%;">
                                <div style="background-color:#545454;height:150px;border-radius:10px;text-align:center;padding-top:30px;color:white;">
                                    <div style="height:50%;width:70%;margin: 0 auto;font-size:18px;">Tempo Médio Manual</div>
                                    <div style="height:50%;font-size:30px;">10 min</div>   
                                </div>
                            </td>
                            <td style="height:150px;width:33.3%;">
                                <div style="background-color:#337b91;height:150px;border-radius:10px;text-align:center;padding-top:30px;color:white;">
                                    <div style="height:50%;width:70%;margin: 0 auto;font-size:18px;">Tempo WebCheckin </div>
                                    <div style="height:50%;font-size:30px;">1,5 min</div>         
                                </div>
                            </td>
                            
                            <td style="height:150px;width:33.3%;">
                                <div style="background-color:#2e937e;height:150px;border-radius:10px;text-align:center;padding-top:30px;color:white;">
                                    <div style="height:50%;width:70%;margin: 0 auto;font-size:18px;">Tempo FD</div>
                                    <div style="height:50%;font-size:30px;">6 min</div>   
                                </div>
                            </td>

                        </tr>
                    </table>
                </div>
                <div>

                </div>
            </div>

            <!-- LEADS COLETADOS -->
            <div style="background-color:#f9f9f9;margin-bottom:50px;text-align:center;color:#0784a9;margin-top:20px;">
                <span style="font-size:40px;">LEADS COLETADOS</span>
                <div>
                    <table style="width:100%;" >
                        <tr style="background-color:white;">
                            <td style="width:20%;color:#5d676a;border:1px solid black;"><span style="font-size:20px;">Tipo</span></td>
                            <td style="width:50%;color:#5d676a;border:1px solid black;"><span style="font-size:20px;">Quantidade</span></td>
                            <td style="width:30%;color:#5d676a;border:1px solid black;"><span style="font-size:20px;">Porcentagem</span></td>
                        </tr>
                        <tr>
                            <td style="width:20%;color:#5d676a;"><div style="font-size:20px;height:50px;padding-top:10px;">Emails</div></td>
                            <td style="width:50%;color:#5d676a;"><div style="font-size:20px;height:50px;padding-top:10px;">5321 de 78451 Fichas</div></td>
                            <td style="width:30%;color:#5d676a;"><div style="font-size:20px;height:50px;padding-top:10px;">75%</div></td>
                        </tr>
                        <tr>
                            <td style="width:20%;color:#5d676a;"><div style="font-size:20px;height:50px;padding-top:10px;">Telefones</div></td>
                            <td style="width:50%;color:#5d676a;"><div style="font-size:20px;height:50px;padding-top:10px;">5321 de 78451 Fichas</div></td>
                            <td style="width:30%;color:#5d676a;"><div style="font-size:20px;height:50px;padding-top:10px;">75%</div></td>
                        </tr>
                        
                    </table>
                    
                    
                </div>
            </div>

             <!-- RODAPE -->
            <div style="background-color:#00AADC;height:100px;padding-top:20px;padding-bottom:20px;">
                <div style="min-height:90%;margin: 0 auto;text-align:center;color:white;">
                     <div style="font-size:30px;font-family:arial;">Economy Software</div>
                     <div style="font-size:15spx;font-family:arial;">Soluções Inteligente</div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>



export default {
  name: "ResumoMensal",
  components: {},
  data() {
    return {
        
    };
  },
  methods: {

  }
};
</script>

<style scoped>

</style>


const snack = (msg,ev=null)=>{
    let color
    switch (ev) {
      case 'warn': case'w':
        color = 'rgb(255,152,0)';
        break;
      case 'error': case'e':
        color = 'rgb(244,67,54)'
        break;
      default:
        color = 'rgb(76,175,80)'
        break;
    }
    let el = document.getElementById('snack-element')
    if (el) {
      //el.style.backgroundColor = color
      el.setAttribute('value', true)
      el.style.display = 'flex'
    }
    let elMsg = document.getElementById('snack-message')
    if (elMsg) {
      elMsg.innerHTML = msg
    }
    let elBtn = document.getElementById('snack-button')
    if (elBtn) {
      elBtn.style.backgroundColor = color
      elBtn.style.color = 'white'
    }
    setTimeout(()=>{
      el.style.display = 'none'
    },4000)
  }

  const snackE = (msg)=>{
    snack(msg,'e');
  }

  const snackW = (msg)=>{
    snack(msg,'w');
  }

  module.exports = {
      snack,
      snackE,
      snackW
  }
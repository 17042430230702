<template>
    <v-dialog v-model="exibirModalTaxasCheckout" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Taxas Checkout
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalTaxasCheckout()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="cobrartaxaservico" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Cobrar Taxas de Serviço?</label>    
                    </div>
                    <div style="font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >
                        <label>Percentual Taxas de Serviço?</label>   
                        <input type="number" step="0.1" class="inputs" v-model="pctaxaservico" placeholder="Informe o email que receberá notificações de webcheckin realizados" />
                    </div>

                    <br><br>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="cobrartaxaturismo" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Cobrar Taxa de Turismo?</label>    
                    </div>
                    <div style="font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >
                        <label>Percentual Taxas Turismo?</label>   
                        <input type="number" step="0.1" class="inputs" v-model="pctaxaturismo" placeholder="Ex:10" />
                    </div>

                     

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        textobotaosalvar:'Salvar',
        cobrartaxaservico:false,
        cobrartaxaturismo:false,
        pctaxaservico:0,
        pctaxaturismo:0
    }),
    props:["exibirModalTaxasCheckout"],
    components:{
        // ModalSolicitacoesResponder

    },
    methods: {
    
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarENVIOAUTOWEBCHECKOUT().then(()=>{
                    this.setModalTaxasCheckout()
            })
            
        },
      
        salvarENVIOAUTOWEBCHECKOUT(){
            return new Promise( (resolve,reject) =>{

                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'TAXASCHECKOUT',
                    "valor" : this.cobrartaxaservico ? 'true' : 'false',
                    "valor2" : this.pctaxaservico,
                    "valor3" : this.cobrartaxaturismo ? 'true' : 'false',
                    "valor4" : this.pctaxaturismo
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalTaxasCheckout(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalTaxasCheckout')
        },
    },
    mounted() {

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'TAXASCHECKOUT'
            }).then(resp =>{
                if(resp.status == 200){
                    this.cobrartaxaservico  = resp.data[0].valor === 'true' ? true : false
                    this.pctaxaservico      = resp.data[0].valor2
                    this.cobrartaxaturismo  = resp.data[0].valor3 === 'true' ? true : false
                    this.pctaxaturismo      = resp.data[0].valor4
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>
<template>
    <div class="wout-principal">
        <div class="center">
            <div>
                <img src="@/assets/img/processandopgto.svg" 
                    width="auto" height="130" alt="Processando pagamento..." crossorigin />
            </div>
            <span class="processando">Processando Pagamento...</span>
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding-top: 30vh;
}
.processando {
    color: #70708C!important;
    padding: 10px;
}
</style>
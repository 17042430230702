<template>
    <v-dialog v-model="exibirModalImportar" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Importar Reservas
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalImportar',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            <v-card-text>
                <h2>Informe o período para a importação:</h2>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <!-- Data Inicial -->
                    <v-col cols="4">
                        <v-text-field
                            class="mr-2"
                            style="max-width: 180px;"
                            type="date"
                            label="Check-IN Inicial"
                            data-cy="periodo-datainicial"
                            v-model="periodo.DataInicial"
                        >                    
                        </v-text-field>
                    </v-col>
                    <!-- Data Final -->
                    <v-col cols="4">
                        <v-text-field
                            class="mr-2"
                            style="max-width: 180px;"
                            type="date"
                            :label="rotuloDataFinal"
                            data-cy="periodo-datafinal"
                            v-model="periodo.DataFinal"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" style="color:white;font-weight:bold;" @click="$emit('importar', periodo)" title="Fechar">
                    <v-icon style="color:white; margin-right:5px;">mdi-account-arrow-left</v-icon>
                        Importar
                </v-btn>
            </v-card-actions>
            <v-card-text v-if="!processando&&resultado">
                <span>{{resultado}}</span>
            </v-card-text>
            <v-card-subtitle v-if="processando">
                <br>Processo de importação em andamento...
            </v-card-subtitle>
        </v-card>

    </v-dialog>
</template>          

<script>


export default {
    data: () => ({
        periodo: {}
    }),
    props:["exibirModalImportar","periodoDefault","processando","resultado"],
    components: {
        
    },
    methods: {
    },
    mounted() {
        if (this.periodoDefault) {
            this.periodo = {... this.periodoDefault}
        }
    },
    computed: {
        rotuloDataFinal() {
            let rot = localStorage.getItem('rotulodatafim') ? localStorage.getItem('rotulodatafim') : 'Check-IN Final';
            return rot;
        }
    }
};
</script>
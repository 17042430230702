<template>
        <div class="captura_container" style="padding-left:0px!important;padding-right:0px!important;height:100vh;">
            <div style="padding-top:10px;width:100%;padding-right:5%;padding-left:5%;display:flex;justify-content:space-between;position:fixed;top:0px;">
                  <div v-if="tagSincronizada" style="color:white;font-size:18px;background-color:#2c9700;padding:10px;">TAG SINC.: {{tagSincronizada}}</div>
                  <div v-else style="color:white;font-size:18px;background-color:#009797;padding:10px;">QR CODE: {{apelido}}</div>
                  <v-btn large color="error" @click="logout()">Sair do Sistema</v-btn>
            </div>
            <!-- <h5 style="text-align:center" class="">PRECISAMOS DE UM AUTÓGRAFO</h5> -->
            <div class="captura_container_confirmacao_dados" 
            style="padding-left:5px!important;padding-right:5px!important;width:95%;padding-bottom:5px;
                    padding-top:3px!important;margin-bottom:10px;height:80vh">
                <div style="margin-bottom:10px;width:100%;display:flex;justify-content:center;padding-top:10px;"> Assine no quadro abaixo </div>
                <VueSignaturePad
                                width="100%"
                                height="100%"
                                ref="signaturePad"
                                style="background:white;margin-bottom:0px;"
                                saveType="image/png"
                            />
                    
                <div class="" style="width:100%;text-align:center;margin-bottom:2px!important;">
                    <hr id="linha_assinatura" />
                    <small class="text-white">SUA ASSINATURA CONSTARÁ NOS DOCUMENTOS DE CHECK-IN</small>
                    
                </div>

            </div>
            <div style="display:flex;flex-wrap:wrap;align-items:center;justify-content:center;height:20vh;width:100%;">
                <button type="button" class="botaoVermelho"         style="margin-top:10px;margin-left:10px" v-on:click.stop.prevent="limpar_assinatura()">
                    Limpar Assinatura 
                </button>

                <button @click="salvarAssinatura()"  type="button"  style="margin-top:10px;margin-left:10px" class="botaoAzul">Confirmar</button>
            </div>

            <ModalRotacione @setModalRotacione="()=>setModalRotacione()" :exibirModalRotacao="exibirModalRotacao" />
        </div>
</template>

<script>

import ModalRotacione from '../Modals/ModalRotacione.vue'
export default {
  data: () => ({
    image: '',
    exibirModalRotacao:false,
    tagSincronizada:null,
    apelido:null,
  }),
  components: {
    ModalRotacione,
  },
  props:["idHospede","idReserva","idReservaHospede"],
  methods: {
    logout(){
         this.$store.dispatch("logout").then(() => {
              window.location.href = "/"
        });
    }, 
    setModalRotacione(){
        this.exibirModalRotacao = false
    },
    async salvarAssinatura(){
        let objetoAssinatura = this.$refs.signaturePad.saveSignature()
        if(!objetoAssinatura.isEmpty){
            let tipo = "image/png"
            let img = objetoAssinatura.data.split(',')[1]
            if (!img.length > 0) {
                console.log("Imagem invalida");
                return;
            }

            let dados =   {
                            "token":'3c8478c895580214e0ff389448054854',
                            "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                            "tipo" : tipo,
                            "nome" : "Assinatura",
                            "dados" : img,
                            "tabela": "hospede",
                            "fk": "assinatura",
                            "chave": "id",
                            "valor": this.idHospede
                        }
            await this.$http.post("/imagem/upinsertAuthSimples",dados,
                {timeout:60000 }
                ) 
                .then((response) => {
                    if(response.status == 200){
                        console.log("Assinatura Salva com Sucesso!")
                        this.$emit('avancar')
                        this.setarPasso(0)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        // setarPasso(0)
    },
    limpar_assinatura() {
        this.$refs.signaturePad.undoSignature();
    },
    setarPasso(valor){
        this.$emit('setarPasso',valor)
    }
  },
  mounted() {
      this.tagSincronizada = localStorage.getItem('tag')
      this.apelido = localStorage.getItem('apelido').toUpperCase()
      let _self = this
      setTimeout(function(){
        // This hides the address bar:
        window.scrollTo(0, 1);
        _self.exibirModalRotacao = true
    }, 500);
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .captura_container_confirmacao_dados{
        background-color:#f1f1f1;
        border-radius:5px;
        width:80vw;
        height:60vh;
        padding:5%;
        display:flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .lblConfirmacaoDados{
        font-weight:bold;
        font-size:16px;
        font-family:Arial, Helvetica, sans-serif;
    }

    .textoConfirmacaoDados{
        padding-left:10px;
        font-size:14px;
        font-family:Arial, Helvetica, sans-serif;
        margin-bottom:10px;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoAzul{
        background-color:#1560e5;
        height:30px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
        min-width: 200px;
    }

    .botaoVermelho{
        background-color:red;
        height:30px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
        min-width: 200px;
    }
</style>
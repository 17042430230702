function removerAcentuacao(str) 
{

    let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

    let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    
    let novastr="";
    
    for(var i=0; i< str.length; i++) {
        let troca=false;
        for (var a=0; a<com_acento.length; a++) {
            if (str.substr(i,1)==com_acento.substr(a,1)) {
                novastr+=sem_acento.substr(a,1);
                troca=true;
                break;
            }
        }
        if (troca==false) {
            novastr+=str.substr(i,1);
        }
    }

    return novastr;
}  

function ordenarArray(lista,nomedocampoordenado){
    
    lista.forEach((item) =>{
        // item.nomesemacento = this.removerAcentuacao(item.descricao)
        item.nomesemacento = this.removerAcentuacao(item[nomedocampoordenado])
    })

    lista.sort((a,b) => {
        return a.nomesemacento.toUpperCase() > b.nomesemacento.toUpperCase() ? 1: 
            a.nomesemacento.toUpperCase() < b.nomesemacento.toUpperCase() ? -1 : 0
    })

    return lista
}

export default {removerAcentuacao, ordenarArray}
<template>
  <div class="master-page-checkinpay">
    <Header @direcionar="(valor) => direcionar(valor)" :dados="hospede"/>
    <Pagamento v-if="componenteatual === 'pagamento' " @direcionar="(valor) => direcionar(valor)" :dados="hospede" @registrarPagamento="(dadoscartao) => registrarPagamento(dadoscartao)"/>
    <Doc        v-if="componenteatual == 'doc'"       v-bind:dados="hospede" @direcionar="(valor) => direcionar(valor)" />
    <AvisoSelf        v-if="componenteatual == 'avisoself'"       v-bind:dados="hospede" @direcionar="(valor) => direcionar(valor)" />
    <Self             v-if="componenteatual == 'self'"            v-bind:dados="hospede" @direcionar="(valor) => direcionar(valor)" @registrarPagamento="(dadoscartao) => registrarPagamento(dadoscartao)" />
    <FalhaReconhecimento  v-if="componenteatual == 'falhareconhecimento'"   v-bind:dados="hospede" @direcionar="(valor) => direcionar(valor)" />
    <PgtoAprovado v-if="componenteatual === 'pagamentoaprovado' " @direcionar="(valor) => direcionar(valor)"/>
    <PgtoNegado v-if="componenteatual === 'pagamentonegado' " @direcionar="(valor) => direcionar(valor)"/>
    <Processando v-if="componenteatual === 'processando' " @direcionar="(valor) => direcionar(valor)"/>
    <NotFound v-if="componenteatual === 'notfound' " @direcionar="(valor) => direcionar(valor)"/>
    <DadosPix         v-if="componenteatual == 'dadospix'"        v-bind:dados="hospede" @direcionar="(valor) => direcionar(valor)" />


    <ConfirmarDadosVoucher 
      v-if="componenteatual === 'confirmardadosvoucher'" 
      @direcionar="(valor) => direcionar(valor)" 
      @exibirModalTpPagamento="() => exibirModalTpPagamento()" 
      :dados="hospede"/>

    <ModalConfirmacao :showModal="exibirModalTipoPagamento" :titulo="'Como deseja Pagar?'" :exibeBtnCancelar="true"
          textosim="Crédito" textonao="Débito" textoCancelar="Pix" 
              @sim="()=>setTipoPagamento(1)" @nao="()=>setTipoPagamento(2)"
                                              @cancelar="() =>setTipoPagamento(3)"  />
  </div>
</template>
<script>
import Pagamento from './checkinpay/Pagamento.vue'
import PgtoAprovado from './checkinpay/PgtoAprovado.vue'
import PgtoNegado from './checkinpay/PgtoNegado.vue'
import Processando from './checkinpay/Processando.vue'
import NotFound from './checkinpay/NotFound.vue'
import ConfirmarDadosVoucher from './checkinpay/ConfirmarDadosVoucher.vue'
import Header from './checkinpay/Header.vue'

import ModalConfirmacao from '@/components/DashViews/Modals/ModalConfirmacao.vue'
import DadosPix from './checkinpay/DadosPix'

import Doc from './checkinpay/Doc'
import AvisoSelf from './checkinpay/AvisoSelf'
import Self from './checkinpay/Self'
import FalhaReconhecimento from './checkinpay/FalhaReconhecimento'


import dayjs from 'dayjs'

export default {
  data() {
    return {
      componenteatual: null,
      hospede: {
        nome: 'Nome Teste',
        uh: '102 teste',
        numeroreserva: '8064',
        valortarifa: 5000.00
      },
      dados: {},
      exibirModalTipoPagamento:false,
    }    
  },
  components: {
    PgtoAprovado, PgtoNegado, NotFound, Processando,
    ConfirmarDadosVoucher, Header, Pagamento,ModalConfirmacao,DadosPix,
    Doc,AvisoSelf,Self,FalhaReconhecimento,
  },
  methods: {
    exibirModalTpPagamento(){
      this.exibirModalTipoPagamento = !this.exibirModalTipoPagamento;
    },
    setTipoPagamento(tipo){
            this.tipoPagamento = tipo
            this.exibirModalTipoPagamento = false
            localStorage.setItem('tipoPgto',tipo)
            // this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
            if(tipo != 3){
                this.hospede.saldo =this.hospede.valor_total
                // this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
                // this.direcionar( {to:"pagamento"})
                // this.direcionar( {to:"avisoself"})
                this.direcionar( {to:"doc"}) 
            }else{
              // this.hospede.saldo =this.hospede.valortarifa
              this.hospede.saldo =this.hospede.valor_total
              this.direcionar({to:"dadospix"})
                // this.$emit('direcionar', {dados: this.dados, to:'dadospix', from:'prepagamento'})
            }
    },
    direcionar(rota){
      switch(rota.to){
        case 'pagamento':
            this.carregarDadosPagamento(rota)
            break;
        default:
          this.componenteatual = rota.to
      }
    },
    carregarHospede(token) {

        this.hospede = null
        var params = { hotel: 1, token: token, idioma: this.$i18n.locale }
        console.log('carregarHospede:'+JSON.stringify(params))
        this.$http.post("/checkinpay/getReserva", params, { headers: {'x-access-token': token}})
        .then((response) => {
            
            if (response.status == 200) {
              response.data[0].datacheckinprevisto  = dayjs( response.data[0].datacheckinprevisto).format('YYYY-MM-DD')
              response.data[0].datacheckoutprevisto  = dayjs( response.data[0].datacheckoutprevisto).format('YYYY-MM-DD')
              this.hospede = response.data[0]
              
              // this.hospede.datacheckinprevisto  = dayjs(this.hospede.datacheckinprevisto).format('YYYY-MM-DD')
              // this.hospede.datacheckoutprevisto = dayjs(this.hospede.datacheckoutprevisto).format('YYYY-MM-DD')
            } else {
              
                console.error(JSON.stringify(response))
                this.componenteatual = 'notfound'
            }
        })
        .catch((error) => {
            
            console.log(error);
            this.componenteatual = 'notfound'
        }); 
    }, 
    somarItensContestados(hospedes) {
        var totalItensContestados = 0
        if (hospedes && Array.isArray(hospedes)) {
            for (var i = 0; i < hospedes.length; i++) {
                var umHosp = hospedes[i]
                totalItensContestados += umHosp.itenscontestados
            }
        }
        return totalItensContestados
    },
    somarChecagens(hospedes) {
        var totalChecagens = 0
        if (hospedes && Array.isArray(hospedes)) {
            for (var i = 0; i < hospedes.length; i++) {
                var umHosp = hospedes[i]
                totalChecagens += umHosp.checagens
            }
        }
        return totalChecagens
    },
    importarConsumo() {
        this.mostrarCarregando = true
        this.$socket.disconnect();
        this.$socket.io.opts.query.usuario = localStorage.getItem('checkinpay-email')
        this.$socket.io.opts.query.tipo = 'CHECKINPAY'
        this.$socket.io.opts.query.tipo_acesso = 'CHECKINPAY_'+localStorage.getItem('checkinpay-email')
        this.$socket.connect();

        this.$socket.emit("checkinpay_back_importarConsumo", {
            idhotel:localStorage.getItem('checkinpay-hotel'),
            numeroreserva:localStorage.getItem('checkinpay-numeroreserva'),
            usuario:localStorage.getItem('checkinpay-email'),
            idreservahospede:localStorage.getItem('checkinpay-idreservahospede')
        });
        this.sockets.subscribe("fecharModalCarregando", (dados) => {
          this.mostrarCarregando=false
          this.sockets.unsubscribe("fecharModalCarregando")
          console.log(dados.msg)
          this.$emit('direcionar', {to:'inicio', from:'inicio'})
        });
    },
    carregarDadosPagamento (rota) {
        // var numeroReserva = localStorage.getItem('checkout-numeroreserva')
        // var idHotel = localStorage.getItem('checkout-hotel')
        var params = {
          "numeroreserva": this.hospede.numeroreserva,
          "idhotel": this.hospede.hotel
        }
        console.log(JSON.stringify(params));
        this.$http.post("/checkinpay/getHospedesdaReserva",params
          , { headers: {'x-access-token': this.hospede.token}})
            .then(async result => {
                
                var cabecalhos = []
                for (var i = 0; i < result.data.length; i++) {
                    var cabecalhos_hospede = result.data[i]['cabecalhos'].split(',')
                    for (var j = 0; j < cabecalhos_hospede.length; j++) {
                        cabecalhos.push(cabecalhos_hospede[j])
                    }
                }
                if (result.status === 200) {
                    this.dados = { hospedes: result.data }
                    // try {
                    
                    //     await this.$http.post("configuracao/buscarParametro", {  
                    //                 "idhotel" : this.hospede.hotel,"chave" : 'CHECKINPAYCHECARFRIGOBAR'})
                    //             .then(async resp =>{
                    //               
                    //                 this.dados.checarFrigobar = await resp.data[0].valor == 'true' ? true : false
                    //                
                    //             }).catch(error =>{ this.dados.checarFrigobar = false;console.log(error); })

                    // } catch (error) {
                    //     console.log(error)   
                    // }

                    //this.dados.saldo = this.calcularSaldo(this.dados.hospedes) 
                    this.hospede.cabecalhos = cabecalhos
                    this.hospede.itenscontestados = this.somarItensContestados(this.dados.hospedes)
                    this.hospede.checagens = this.somarChecagens(this.dados.hospedes)
                    console.log(rota.from)
                    console.log(process.env.NODE_ENV)
                    // if (rota.from == 'login') {
                    //     if (process.env.NODE_ENV !== 'development') {
                           
                            //this.importarConsumo()
                    //     }
                    // }
                    this.componenteatual = rota.to
                } else {
                    console.error(JSON.stringify(result))
                }
            })
        .catch((e) => {
            console.error(e)
        })
    },
    registrarPagamento(dadoscartao) {
        var params = {
            "idhotel": dadoscartao.idhotel,
            "valor": dadoscartao.valorapagar,
            "nucartao": dadoscartao.numerocartao,
            "nomecartao": dadoscartao.nome,
            "dtexpiracaocartao": dadoscartao.validade,
            "nucvvcartao": dadoscartao.cvccvv,
            "bandeiracartao": dadoscartao.bandeira,
            "cabecalhos": dadoscartao.cabecalhos,
            "consumos": dadoscartao.consumos,
            "idhospede": dadoscartao.idhospede,
            "idreservahospede": dadoscartao.idreservahospede,
            "numeroreserva": dadoscartao.numeroreserva,
            "tipoPagamento":localStorage.getItem('tipoPgto'),
            "parcelas":dadoscartao.parcelas,
            "nome":dadoscartao.nome,
            "sobrenome":dadoscartao.sobrenome
            }
        console.log(JSON.stringify(params))
        this.componenteatual = 'processando'
        this.$http.post("/checkinpay/efetuarPagamento",params, 
            { headers: {'x-access-token': this.hospede.token}})
            .then(result => {
                if (result.status == 200) {
                    this.componenteatual = 'pagamentoaprovado'
                } else {
                    this.dados = {... dadoscartao}
                    this.componenteatual = 'pagamentonegado'
                    console.error(JSON.stringify(result))
                }
            })
        .catch((e) => {
            console.error(e)
            this.componenteatual = 'pagamentonegado'
        })
    },
  },
  async mounted(){
   
    
    await this.carregarHospede(this.$route.params.token)
    this.componenteatual = 'confirmardadosvoucher'
    // this.componenteatual = 'pagamento'
  }
}
</script>

<style lang="scss" scoped>
.master-page-checkinpay{
  max-width: 100vw;
  background: #EBEBF5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
<template>
    <div class="cc-principal" style="">

        <div style="margin-top:20px;">
            <div v-if="etapaPagto=='DadosCartao'">
                <h2 style="padding-left:20px;">
                    Dados Cartão
                </h2>

                    <!-- Cartão -->
                    <div class="cc-cartao" style="z-index:-1000;">
                        <img v-if="bandeira == 'VISA'" src="@/assets/img/visa.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'MASTER'" src="@/assets/img/master.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'AMEX'" src="@/assets/img/amex.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'DINERS'" src="@/assets/img/diners.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'DISCOVER'" src="@/assets/img/discover.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'JCB'" src="@/assets/img/jcb.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'ELO'" src="@/assets/img/elo.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'HIPERCARD'" src="@/assets/img/hipercard.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                        <img v-if="bandeira == 'OUTRO'" src="@/assets/img/outro.png" width="auto" height="110" alt="Imagem Cartão" crossorigin />
                    </div>
                    <!-- Dados -->
                    <div class="cc-center" style="font-size:14px;margin-top:0px;">Insira os dados</div>
                    <div class="cc-center" style="font-size:14px;;margin-top:2px;">Cartão de {{tipoPagto == 1?'Crédito':'Débito'}}</div>
                    <div class="cc-form">
                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-nometitular">Nome no Cartão:</label>
                                <v-text-field id="i-nometitular" oninput="this.value = this.value.toUpperCase()" filled rounded dense hide-details v-model="nometitular" :rules="[validarNome]"></v-text-field>
                            </div>
                        </div>
                        <div class="cc-row" >
                            <div class="cc-col-12" style="">
                                <label for="i-numerocartao">Número do cartão:</label>
                                <v-text-field id="i-numerocartao" filled rounded dense hide-details v-model="numerocartao" :rules="[validarNumeroCartao]" @keyup="event => corrigirNumeroCartao(event)" @blur="formatarNumeroCartao" >
                                </v-text-field>
                            </div>
                        </div>
                        <div class="cc-row">
                            <div class="cc-col-4" style="margin-right:10px;width:30%;" >
                                <label for="i-mmaa">MM/AA:</label>
                                <v-text-field id="i-mmaa" filled rounded dense hide-details v-model="mmaa" :rules="[validarValidade]" @keyup="event => corrigirValidade(event)">
                                </v-text-field>
                            </div>
                            <div class="cc-col-4" style="margin-right:10px;width:30%;">
                                <label for="i-cvccvv">CVC/CVV:</label>
                                <v-text-field id="i-cvccvv" filled rounded dense hide-details v-model="cvccvv" type="number" :rules="[validarCVCCVV]" @keyup="event => corrigirCVCCVV(event)" >
                                </v-text-field>
                            </div>
                            <div class="cc-col-4" style="margin-right:10px;width:30%;">
                                <label for="i-cvccvv">PARCELAS:</label>
                                <v-text-field id="i-cvccvv" filled rounded dense hide-details v-model="parcelas" type="number" >
                                </v-text-field>
                            </div>
                        </div>
                        <!-- Saldo -->
                        <div class="wout-row-center" v-if="exibirvalor">
                            <div class="wout-campo-saldo-white">
                                <div class="wout-saldo">-R$ {{ valorapagar | formatarMoeda}}</div>
                                <div class="wout-label-saldo"><b>Valor a pagar</b></div>
                            </div>
                        </div>
                    </div>


       
            </div>

            <div v-if="etapaPagto=='DadosPessoais'">
                <h2 style="padding-left:20px;">
                    Dados do Titular
                </h2>
                <div class="cc-form">

                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-nometitular-antifraude">Nome Completo:</label>
                                <v-text-field id="i-nometitular-antifraude" oninput="this.value = this.value.toUpperCase()" filled rounded dense hide-details v-model="nomeTitularAntiFraude"></v-text-field>
                            </div>
                        </div>

                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-nometitular">Email:</label>
                                <v-text-field type="email" id="i-nometitular" filled rounded dense hide-details v-model="emailTitularAntiFraude"></v-text-field>
                            </div>
                        </div>

                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-nometitular">Celular (DDI + DDD + FONE):</label>
                                <v-text-field type="text" id="i-nometitular" 
                                    @blur="formatTel()"
                                    placeholder="+55 (11) 98888-7777" filled rounded dense hide-details 
                                    v-model="celularTitularAntiFraude"></v-text-field>
                            </div>
                        </div>

                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-nometitular">CPF:</label>
                                <v-text-field maxlength="15" type="text" id="i-nometitular" filled rounded dense hide-details v-model="cpfTitularAntiFraude"></v-text-field>
                            </div>
                        </div>

                </div>
            </div>


            <div v-if="etapaPagto=='DadosEndereco'">
                <h2 style="padding-left:20px;">
                    Endereço de Cobrança
                </h2>
                <div class="cc-form">

                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-cep-antifraude">CEP:</label>
                                <v-text-field id="i-cep-antifraude" filled rounded dense hide-details v-model="cepAntiFraude" @keyup="buscarCep()" ></v-text-field>
                            </div>
                        </div>

                        <div class="cc-row" >
                            <div class="cc-col-10" style="margin-bottom:0px;padding-bottom:0px;width:100%;">
                                <label for="i-nometitular">Endereço:</label>
                                <v-text-field id="i-nometitular" filled rounded dense hide-details v-model="enderecoTitularAntiFraude"></v-text-field>
                            </div>
                            <div class="cc-col-2" style="margin-left:10px;width:20%;" >
                                <label for="i-numerotitular-antifraude">Número</label>
                                <v-text-field id="i-numerotitular-antifraude" type="number" filled rounded dense hide-details v-model="numeroEnderecoAntiFraude">
                                </v-text-field>
                            </div>
                        </div>

                        <div class="cc-row">
                            <div class="cc-col-6" style="margin-right:0px;width:100%;">
                                <label for="i-cvccvv">Complemento:</label>
                                <v-text-field id="i-cvccvv" filled rounded dense hide-details v-model="complementoAntiFraude" >
                                </v-text-field>
                            </div>
                           
                            <div class="cc-col-6" style="margin-left:10px;margin-bottom:0px;padding-bottom:0px;width:100%;">
                                <label for="i-nometitular">Bairro:</label>
                                <v-text-field type="email" id="i-nometitular" filled rounded dense hide-details v-model="bairroAntiFraude"></v-text-field>
                            </div>
                        </div>

                        <div class="cc-row" >
                            <div class="cc-col-12" style="margin-bottom:0px;padding-bottom:0px;">
                                <label for="i-nometitular">Pais:</label>
                                <select v-model="pais" data-cy="select-estado" class="" 
                                    @change="listarEstados()"
                                    style="background-color:white;width:100%;border-radius:20px;height:40px;padding-left:25px;font-weight:300;"
                                >
                                    <option v-for="(pais, index) in paises" :key="index" :value="pais"  >
                                        {{pais.nome}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="cc-row">
                            <div class="cc-col-6" style="margin-right:10px;width:50%;">
                                <label for="i-cvccvv">Estado:</label>
                                <select v-model="estado" data-cy="select-estado" class="inputs"
                                    @change="listarCidades()"
                                    style="background-color:white;width:100%;border-radius:20px;height:40px;padding-left:25px;font-weight:300;"
                                >
                                    <option v-for="(estado, index) in estados" :key="index" :value="estado"  >
                                        {{estado.nome}}
                                    </option>
                                </select>
                               
                            </div>
                            <div class="cc-col-6" style="margin-right:0px;width:50%;" >
                                <label for="i-mmaa">Cidade:</label>
                                <select v-model="cidade" data-cy="select-cidade" class="inputs"
                                        style="background-color:white;width:100%;border-radius:20px;height:40px;padding-left:10px;font-weight:300;"
                                >
                                    <option v-for="(cidade, index) in cidades" :key="index" :value="cidade"  style=" " >
                                        {{cidade.nome}}
                                    </option>
                                </select>
                            </div>
                           
                        </div>

                </div>
            </div>



            <!-- {{
            {{
                {
                    enderecoTitularAntiFraude:enderecoTitularAntiFraude,
                    numeroEnderecoAntiFraude:numeroEnderecoAntiFraude,
                    complementoAntiFraude:complementoAntiFraude,
                    bairroAntiFraude:bairroAntiFraude,
                    pais:pais,
                    estado:estado,
                    cidade:cidade,
                    nomeTitularAntiFraude:nomeTitularAntiFraude,
                    emailTitularAntiFraude:emailTitularAntiFraude,
                    celularTitularAntiFraude:celularTitularAntiFraude,
                    cpfTitularAntiFraude:cpfTitularAntiFraude
                }
            }} -->
          
        <!-- Botão Pagar -->
        <div v-if="etapaPagto=='DadosEndereco'" style="display:flex;justify-content:center;padding-top:3vh;">
            <v-row>
                <v-col style="display:flex;justify-content:center;padding-top:3vh;padding-right:10px;">
                    <button class="cc-pagar-btn" @click="voltarEtapa()" >
                        <v-icon>mdi-arrow-left</v-icon>
                        Voltar
                    </button>
                </v-col>
                <v-col style="display:flex;justify-content:left;padding-top:3vh;padding-right:10px;">
                    <button class="cc-pagar-btn" @click="confirmar" :disabled="!validInput">
                        <div class="cc-row px-0">
                        <span class="cc-sinal-moeda">$</span>
                        <div class="px-1">Efetuar Pagamento</div>
                        <v-icon>mdi-arrow-right</v-icon>
                        </div>
                    </button>
                </v-col>
            </v-row>
        </div>

        <v-row>
            <v-col>
                <div v-if="etapaPagto!='DadosCartao'&&etapaPagto!='DadosEndereco'" style="display:flex;justify-content:center;padding-top:3vh;padding-right:10px;">
                    <button class="cc-pagar-btn" @click="voltarEtapa()" >
                        <v-icon>mdi-arrow-left</v-icon>
                        Voltar
                    </button>
                </div>
            </v-col>
            <v-col>
                <div v-if="etapaPagto!='DadosEndereco'" style="display:flex;justify-content:center;padding-top:3vh;padding-right:10px;">
                    <button class="cc-pagar-btn" @click="avancarEtapa()" >
                        Avançar
                        <v-icon>mdi-arrow-right</v-icon>
                    </button>
                </div>
            </v-col>
        </v-row>


        
        <!-- <div style="display:flex;justify-content:center;padding-top:2vh;">
            <button class="cc-pagar-btn" @click="checarNomesReserva(hospedes)" :disabled="!validInput">
                Validar Nome
            </button>
            <div>
                <span>{{validName}}</span>
            </div>
        </div> -->

        <!-- Modal de confirmação -->
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600" v-model="confirmMessage"
        >
            <template v-slot:default="confirmMessage">
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;padding:10px;">
                            <img src="@/assets/img/confirmarcartao.svg" 
                                width="150" height="auto"
                                alt="Confirmando..." crossorigin />
                                <br>
                            <div class="contest-message">Confirma os dados do seu cartão?
                                <br>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-spacer />
                        <!-- Confirmar (Sim) -->
                        <button class="sim-btn"
                            v-on:click="registrarPagamento"
                        >
                            <img src="@/assets/img/sim.svg" 
                                width="25" height="auto"
                                alt="Confirmar..." crossorigin />
                            <span class="px-2">Sim</span>
                        </button>
                        <v-spacer />
                        <!-- Cancelar (Não) -->
                        <button class="nao-btn"
                            v-on:click="confirmMessage.value = false"
                        >
                            <img src="@/assets/img/nao.svg" 
                                width="25" height="auto"
                                alt="Cancelar..." crossorigin />
                            <span class="px-2">Não</span>
                        </button>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        </div>

    </div>
</template>

<script>

import orto from '../../utils/ortografia'
import {buscarEnderecoViaCep, validarCPF} from '../../utils/geo'

export default {
    props:["dados"],
    data: function () {
        return {
        enderecoTitularAntiFraude:null,
        numeroEnderecoAntiFraude:null,
        complementoAntiFraude:null,
        bairroAntiFraude:null,
        nomeTitularAntiFraude:null, 
        emailTitularAntiFraude:null,
        celularTitularAntiFraude:null,
        cpfTitularAntiFraude:null,
        cepAntiFraude:null,

        // Para testar o reconhecimneto facial...
        // TODO: Para código em produção colocar strings vazias em 
        //   'nometitular', 'numerocartao', 'mmaa' e 'cvccvv'
        
        // nometitular: this.dados.dadoscartao&&this.dados.dadoscartao.nometitular?this.dados.dadoscartao.nometitular:'',
        // numerocartao: this.dados.dadoscartao&&this.dados.dadoscartao.numerocartao?this.dados.dadoscartao.numerocartao:'',
        // mmaa: this.dados.dadoscartao&&this.dados.dadoscartao.mmaa?this.dados.dadoscartao.mmaa:'',
        // cvccvv: this.dados.dadoscartao&&this.dados.dadoscartao.cvccvv?this.dados.dadoscartao.cvccvv:'',
        nometitular: this.dados.dadoscartao&&this.dados.dadoscartao.nometitular?this.dados.dadoscartao.nometitular:'',
        numerocartao: this.dados.dadoscartao&&this.dados.dadoscartao.numerocartao?this.dados.dadoscartao.numerocartao:'',
        mmaa: this.dados.dadoscartao&&this.dados.dadoscartao.mmaa?this.dados.dadoscartao.mmaa:'',
        cvccvv: this.dados.dadoscartao&&this.dados.dadoscartao.cvccvv?this.dados.dadoscartao.cvccvv:'',
        bandeira: 'VISA',
        bandeiraSrc: '@/assets/img/visa.png',
        confirmMessage: false,
        tipoPagto: localStorage.getItem('tipoPgto'),
        parcelas:1,
        parcelasMaxima:1,
        idreservahospede: null,
        hospedes: JSON.parse(localStorage.getItem('checkout-hospedes')),
        etapaPagto:'DadosCartao',
        paises:[],
        pais:null,
        estados:[],
        estado:null,
        cidades:[],
        cidade:null,
        //validName: false
        exibirvalor:false,
        msgValidacao: 'Todos os campos são obrigatórios.'
    }},
    components: {
		
	},
    computed: {
        validInput() {
            return (
                typeof(this.validarNome()) == 'boolean'
                && typeof(this.validarNumeroCartao()) == 'boolean'
                && typeof(this.validarValidade()) == 'boolean'
                && typeof(this.validarCVCCVV()) == 'boolean'
            )
        }
    },
    methods: {
        formatTel() {
            
            let phoneNumber = this.celularTitularAntiFraude

            // Remove todos os caracteres que não sejam números do número de telefone
            const cleaned = phoneNumber.replace(/\D/g, '');

            // Divide o número em seu código de país, código de área e número principal
            const countryCode = cleaned.slice(0, 2);
            const areaCode = cleaned.slice(2, 4);
            const mainNumber = cleaned.slice(4);

            // Formata o número de telefone usando as informações acima
            const formattedPhoneNumber = `+${countryCode} (${areaCode}) ${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;
            
            this.celularTitularAntiFraude = formattedPhoneNumber;

        },
        async buscarCep(){

            let cepLimpo = this.cepAntiFraude.replace(/\D/g, '')
            if (cepLimpo.length < 8){
                return
            }

            await buscarEnderecoViaCep(cepLimpo).then(async resp =>{

                console.error(JSON.stringify(resp.dados))

                const filterPais = this.paises.filter((e) => e.nome.toUpperCase() == resp.dados.pais.toUpperCase());

                if (filterPais.length > 0) {
                    this.pais = filterPais[0]
                    await this.listarEstados();
                    let filterEstado = this.estados.filter((e) => e.pais == filterPais[0].id && e.sigla == resp.dados.uf);
                    if(filterEstado.length === 0) {
                        filterEstado = this.estados.filter((e) => e.pais == this.pais.id);
                    }
                    if (filterEstado.length > 0) {
                        this.estado = filterEstado[0]
                        await this.listarCidades();
                        let filterCidade = this.cidades.filter((c) => c.nome.toUpperCase() == resp.dados.localidade.toUpperCase());
                        if (filterCidade.length > 0) {
                            this.cidade = filterCidade[0]
                        }
                    }
                }

                if(resp.dados.bairro.length > 0) {
                    this.bairroAntiFraude = resp.dados.bairro;
                }

                if(resp.dados.logradouro.length > 0) {
                    this.enderecoTitularAntiFraude = resp.dados.logradouro;
                }

                if(resp.dados.complemento.length > 0) {
                    this.complementoAntiFraude = resp.dados.complemento;
                }

            }).catch((e) => {
                console.error(e)
            })

        },
        buscarCep_anterior() {

            if (this.cepAntiFraude.replace(/\D/g, '').length < 8) {
                return
            }

            buscarEnderecoViaCep(this.cepAntiFraude).then(async resp =>{

                this.enderecoTitularAntiFraude = resp.dados.logradouro
                this.complementoAntiFraude = resp.dados.complemento
                this.bairroAntiFraude = resp.dados.bairro
                let paisEncontrado = this.paises.find((x)=>{return (x.nome == resp.dados.pais?.toUpperCase())})
                if (paisEncontrado) {
                    this.pais = paisEncontrado
                    await this.listarEstados()
                }
                let estadoEncontrado = this.estados.find((x)=>{return (x.sigla == resp.dados.uf?.toUpperCase())})
                if (estadoEncontrado) {
                    this.estado = estadoEncontrado
                    await this.listarCidades()
                }
                let cidadeEncontrada = this.cidades.find((x)=>{return (x.nome == resp.dados.localidade?.toUpperCase())})
                if (cidadeEncontrada) this.cidade = cidadeEncontrada

                document.getElementById('i-numerotitular-antifraude').focus()

                //console.error(JSON.stringify(resp.dados))

            }).catch((e) => {
                console.error(e)
            })
          
        },
        maskTel() {
            return '+## (##) #####-####'
            // if(this.celularTitularAntiFraude) {
            //     // return this.celularTitularAntiFraude.length == 14 ? '+## (##)#####-####' :'####-#####'
            //     return '+## (##) #####-####'
            // } else {
            //     return '####-####'
            // }
        },

        validaDadosCartao(){

            let validado = false 

            if(this.validInput){ validado = true}

            return validado

        },
        validarDadosPessoais(){

            let validado = true 

            if(this.nomeTitularAntiFraude == null || this.nomeTitularAntiFraude.trim() == '' ){ validado = false}
            if(this.emailTitularAntiFraude == null || this.emailTitularAntiFraude.trim() == '' ){ validado = false}
            if(this.celularTitularAntiFraude == null || this.celularTitularAntiFraude.trim() == '' ){ validado = false}
            //if(this.cpfTitularAntiFraude == null || this.cpfTitularAntiFraude.trim() == '' ){ validado = false}

            let cpfValido = validarCPF(this.cpfTitularAntiFraude)
            
            this.msgValidacao = cpfValido?'Todos os campos são obrigatórios.':'CPF inválido.'

            validado = validado && cpfValido;

            console.error(`validarDadosPessoais(): ${validado}`)
            
            return validado

        },
        validarDadosEndereco(){

            let validado = true 
            
            if(this.enderecoTitularAntiFraude == null || this.enderecoTitularAntiFraude.trim() == '' ){ validado = false}
            if(this.numeroEnderecoAntiFraude == null || this.numeroEnderecoAntiFraude.trim() == '' ){ validado = false}
            // if(this.complementoAntiFraude == null || this.complementoAntiFraude.trim() == '' ){ validado = false}
            if(this.bairroAntiFraude == null || this.bairroAntiFraude.trim() == '' ){ validado = false}
            if(this.cepAntiFraude == null || this.cepAntiFraude.trim() == '' ){ validado = false}
            if(this.pais == null || this.pais == {} ){ validado = false}
            if(this.estado == null || this.estado == {} ){ validado = false}
            if(this.cidade == null || this.cidade == {} ){ validado = false}
            
            return validado

        },



        voltarEtapa(){
            if(this.etapaPagto == 'DadosEndereco'){
                this.etapaPagto='DadosPessoais'
            }else{
                if(this.etapaPagto == 'DadosPessoais'){
                    this.etapaPagto='DadosCartao'
                }
            }
        },
        async avancarEtapa(){
            if(this.etapaPagto == 'DadosCartao'){

                if(this.validInput){
                    this.etapaPagto='DadosPessoais'
                    setTimeout(()=>{
                        document.getElementById('i-nometitular-antifraude')?.focus()
                    },1000)
                }else{
                    this.$snackW(this.msgValidacao)
                }
            }else{
                if(this.etapaPagto == 'DadosPessoais'){
                    if(this.validarDadosPessoais()){
                        this.etapaPagto='DadosEndereco'
                        setTimeout(()=>{
                            document.getElementById('i-cep-antifraude')?.focus()
                        },500)
                    }else{
                        this.$snackW(this.msgValidacao)
                    }
                }
            }
        },
        async listarPaises(){
            return new Promise((resolve, reject) => {
                this.$http.post("webcheckout/pais/select", {
                        hotel: this.dados.idhotel,
                        orderby: 'noaccent(nome)'})
                .then(async resp =>{
                    this.paises = resp.data
                    resolve(resp.data)
                })
                .catch(error =>{
                    console.error(error)
                    reject(error)
                })
            })            
        },
        async listarEstados(){
            return new Promise((resolve, reject) => {
                this.$http.post("webcheckout/uf/select",{pais:this.pais&&typeof this.pais === 'object'?this.pais.id:this.pais})
                .then(async resp =>{
                    this.estados = resp.data;
                    resolve(resp.data)
                })
                .catch(error =>{
                    reject(error)
                })
            })
        },
        async listarCidades(){
            return new Promise((resolve, reject) => {
                if (this.estado) {
                    this.$http.post("webcheckout/cidade/select",{
                        pais:typeof this.pais === 'object'?this.pais.id:this.pais,
                        uf: this.estado.id,
                        orderby: 'nome'
                    })
                    .then(async resp =>{
            
                            this.cidades = resp.data;

                            if(this.cidade){
                                if(typeof this.cidade === 'number'){
                                    this.cidade = await this.cidades.find(cidade => cidade.id == this.cidade)
                                }else{
                                    this.cidade = await this.cidades.find(cidade => orto.removerAcentuacao(cidade.nome.toUpperCase()) == orto.removerAcentuacao(this.cidade.nome.toUpperCase()))
                                }

                            }else{
                                this.cidade = this.cidades[0]
                            }

                            resolve(resp)

                    })
                    .catch(error =>{
                        reject(error)
                    })
                } else {
                    resolve([])
                }
            })
        },
        confirmar() {
            if(this.validarDadosEndereco()){

                if(localStorage.getItem('tipoPgto') == 1){
                    if(this.parcelas>0 && this.parcelasMaxima >= this.parcelas){
                        this.confirmMessage = true 
                    }else{ 
                        this.$snackW("A Quantidade de Parcela deve está entre 1 e "+this.parcelasMaxima+"!");
                    }
                }else{
                    this.confirmMessage = true
                }

            }else{
                this.$snackW(`Verifique o endereço.`)
            }

            
        },
        getBandeiraCartao(cardNumber){
            var regexVisa = /^4[0-9]{12}(?:[0-9]{3})?/;
            var regexMaster = /^5[1-5][0-9]{1,14}/;
            var regexAmex = /^3[47][0-9]{1,13}/;
            var regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{1,11}/;
            var regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{1,12}/;
            var regexJCB = /^(?:2131|1800|35\d{3})\d{1,11}/;
            var regexElo = /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/
            var regexHipercard = /^606282|^637095|^637599|^637568/

            if(regexVisa.test(cardNumber)){
                return 'VISA';
            }
            if(regexMaster.test(cardNumber)){
                return 'MASTER';
            }
            if(regexAmex.test(cardNumber)){
                return 'AMEX';
            }
            if(regexDiners.test(cardNumber)){
                return 'DINERS';
            }
            if(regexDiscover.test(cardNumber)){
                return 'DISCOVER';
            }
            if(regexJCB.test(cardNumber)){
                return 'JCB';
            }
            if(regexElo.test(cardNumber)){
                return 'ELO';
            }
            if(regexHipercard.test(cardNumber)){
                return 'HIPERCARD';
            }

            return 'OUTRO';
        },
        checarNomesReserva(peoples) {
            this.idreservahospede = localStorage.getItem('checkout-idreservahospede')
            let valid = 'Este campo é obrigatório.';
            let namesCard = this.nometitular.split(' ')
            if (namesCard && namesCard.length > 0 && peoples && peoples.length > 0) {
                valid = 'O nome do titular deve ser um dos hóspedes da reserva.';
                // Primeiro tentar bater primeiro nome e último sobrenome...
                let firstNameCard = namesCard[0]
                let lastNameCard = namesCard.length > 1 ? namesCard[namesCard.length-1] : ''
                for (var i = 0; i < peoples.length; i++) {
                    let aPerson = peoples[i]
                    let completPersonName = aPerson.nome + ' ' + aPerson.sobrenome
                    let personNames = completPersonName.split(' ')
                    if (personNames.length > 0) {
                        let personFirstName = personNames[0]
                        let personLastName = personNames.length > 1 ? personNames[personNames.length-1] : ''
                        if (firstNameCard == personFirstName && lastNameCard == personLastName) {
                            //console.error('nome sobrenome, id:'+aPerson.id)
                            this.idreservahospede = aPerson.id
                            //console.error('this.idreservahospede:'+this.idreservahospede)
                            valid = true;
                            break;
                        }
                    }
                }
                // Se não encontrou bater primeiro nome com primeiro nome...
                if (typeof(valid)=='string') {
                    for (i = 0; i < peoples.length; i++) {
                        let aPerson = peoples[i]
                        let completPersonName = aPerson.nome + ' ' + aPerson.sobrenome
                        let personNames = completPersonName.split(' ')
                        if (personNames.length > 0) {
                            let personFirstName = personNames[0]
                            if (firstNameCard == personFirstName) {
                                //console.error('primeiro nome, id:'+aPerson.id)
                                this.idreservahospede = aPerson.id
                                //console.error('this.idreservahospede:'+this.idreservahospede)
                                valid = true;
                                break;
                            }
                        }
                    }
                }
            }
            //this.validName = valid
            return valid
        },
        validarNome() {
            let result = this.checarNomesReserva(this.hospedes)
            if (result === false) this.msgValidacao = 'O nome deve ser um dos hóspedes da reserva.'
            return result
        },
        // corrigirNome() {
        //     this.nometitular = this.nometitular.replace(/[^a-zA-Z ]/g, '');
        //     this.nometitular = this.nometitular.replace(/ {2}/g, ' ');
        //     this.nometitular = this.nometitular.toUpperCase()
        // },
        validarNumeroCartao() {
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            var limiteInf = 13
            var limiteSup = 19
            if (this.bandeira == 'AMEX') {
                limiteInf = 15
                limiteSup = 15
            } else {
                if (this.bandeira == 'DINERS') {
                    limiteInf = 14
                    limiteSup = 14
                } else {
                    if (this.bandeira == 'JCB') {
                        limiteInf = 16
                        limiteSup = 19
                    } else {
                        if (this.bandeira == 'MASTER' 
                            || this.bandeira == 'DISCOVER'
                            || this.bandeira == 'ELO'
                            || this.bandeira == 'HIPERCARD'
                            || this.bandeira == 'VISA') {
                                limiteInf = 16
                                limiteSup = 16
                        }
                    }
                }
            }
            let result = !!numeroSemBarra && numeroSemBarra.length>=limiteInf && numeroSemBarra.length<=limiteSup || 'Este campo é obrigatório, verifique os números do cartão.'
            return result
        },
        corrigirNumeroCartao(event) {
            var code = (event.which) ? event.which : event.keyCode;
            // console.error(code)
            // Retirar tudo que não é digito ou traço...
            this.numerocartao = this.numerocartao.replace(/[^0-9-]/g, '');
            // Identificar a bandeira do cartão (somente dígitos)
            this.bandeira = this.getBandeiraCartao(this.numerocartao.replace(/[^0-9]/g, ''))
            this.bandeiraSrc = `@/assets/img/${this.bandeira.toLowerCase()}.png`
            // Se estiver editando não mexer...
            if (code == 8) { return }
            // Se digitar o traço, retirar...
            if (code == 109) {
                this.numerocartao = this.numerocartao.slice(0, -1)
                return
            }
            // Inserir os traços automaticamente (se for AMEX a formatação é diferente)...
            var posSegundaBarra = (this.bandeira == 'AMEX'||this.bandeira == 'DINERS') ? 10 : 8
            var posTerceiraBarra = (this.bandeira == 'AMEX'||this.bandeira == 'DINERS') ? 10 : 12
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            if ((numeroSemBarra.length == 4)
                || (numeroSemBarra.length == posSegundaBarra)
                || (numeroSemBarra.length == posTerceiraBarra)) {
                this.numerocartao +='-'
            }
            // Limitar a 16 números (existem cartões de 13 a 19 dígitos)
            var tamanhoLimite = 19
            if (this.bandeira == 'AMEX') {
                tamanhoLimite = 15
            } else {
                if (this.bandeira == 'DINERS') {
                    tamanhoLimite = 14
                } else {
                    if (this.bandeira == 'MASTER' 
                    || this.bandeira == 'DISCOVER'
                    || this.bandeira == 'ELO'
                    || this.bandeira == 'HIPERCARD'
                    || this.bandeira == 'VISA') {
                        tamanhoLimite = 16
                    }
                }
            }
            if (numeroSemBarra.length > tamanhoLimite) {
                this.numerocartao = this.numerocartao.slice(0, -1)
            }
            // Retirar eventuais duplicidades do traço...
            this.numerocartao = this.numerocartao.replace('--', '-')
        },
        formatarNumeroCartao() {
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            if (numeroSemBarra) {
                if (this.bandeira=='AMEX'||this.bandeira == 'DINERS') {
                    this.numerocartao = numeroSemBarra.substring(0,4)
                        + '-' + numeroSemBarra.substring(4,10)
                        + '-' + numeroSemBarra.substring(10)
                } else {
                    this.numerocartao = numeroSemBarra.substring(0,4)
                        + '-' + numeroSemBarra.substring(4,8)
                        + '-' + numeroSemBarra.substring(8,12)
                        + '-' + numeroSemBarra.substring(12)
                }
            }
        },
        validarValidade() {
            var validDateYear = true
            if (this.mmaa) {
                var parts = this.mmaa.split('/')
                if (parts.length == 2) {
                    try {
                        var month = parseInt(parts[0])
                        var year = parseInt(parts[1])
                        validDateYear = month >= 1 && month <= 12
                        validDateYear = validDateYear && year > 20 && year < 99
                    } catch (e) {
                        // console.error(e)
                        validDateYear = false
                    }
                } else {
                    validDateYear = false
                }
            }
            let result = (!!this.mmaa && validDateYear) || 'Digite mês e ano de validade.'
            return result
        },
        corrigirValidade(event) {
            var code = (event.which) ? event.which : event.keyCode;
            // console.error(code)
            // Retirar tudo que não é digito ou '/'...
            this.mmaa = this.mmaa.replace(/[^0-9/]/g, '');
            // Se estiver editando não mexer...
            if (code == 8) { return }
            // Se digitar a barra, retirar...
            if (code == 111) {
                this.mmaa = this.mmaa.slice(0, -1)
                return
            }
            // Inserir a barra automaticamente...
            var mmaaSemBarra = this.mmaa.replace(/[/]/g, '')
            if (mmaaSemBarra.length == 2) {
                this.mmaa +='/'
            }
            // Limitar a 4 números
            if (mmaaSemBarra.length > 4) {
                this.mmaa = this.mmaa.slice(0, -1)
            }
            // Retirar eventuais duplicidades da barra...
            this.mmaa = this.mmaa.replace('//', '/')
        },
        validarCVCCVV() {
            var re
            if (this.bandeira == 'AMEX') { 
                re = /\b[0-9]{4}\b/g 
            } else {
                re = /\b[0-9]{3}\b/g 
            }
            let result = !!(this.cvccvv && this.cvccvv.match(re)) || 'Verifique o CVC/CVV do cartão.'
            return result
        },
        corrigirCVCCVV() {
            // Limitar a 3 ou 4 números
            var limit = (this.bandeira == 'AMEX') ? 4 : 3
            if (this.cvccvv.length > limit) {
                this.cvccvv = this.cvccvv.slice(0, -1)
            }
        },
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
        registrarPagamento() {
            var mmaaParts = this.mmaa.split('/')
            if (mmaaParts.length == 2) {
                var ano = parseInt(mmaaParts[1]) + 2000
                var validade = mmaaParts[0] + '/' + ano
                //console.error('this.idreservahospede antes do emit: '+this.idreservahospede)
                this.$emit('registrarPagamento', {
                    nometitular: this.nometitular,
                    numerocartao: this.numerocartao.replace(/[-]/g, ''),
                    validade: validade,
                    mmaa: this.mmaa,
                    cvccvv: this.cvccvv,
                    valorapagar: this.dados.valorapagar,
                    bandeira: this.bandeira=='OUTRO'?'VISA':this.bandeira,
                    parcelas: this.parcelas,
                    idreservahospedeacomparar: this.idreservahospede,
                    
                    enderecoTitularAntiFraude:this.enderecoTitularAntiFraude,
                    numeroEnderecoAntiFraude:this.numeroEnderecoAntiFraude,
                    complementoAntiFraude:this.complementoAntiFraude,
                    bairroAntiFraude:this.bairroAntiFraude,
                    cepAntiFraude:this.cepAntiFraude,
                    pais:this.pais,
                    estado:this.estado,
                    cidade:this.cidade,
                    nomeTitularAntiFraude:this.nomeTitularAntiFraude,
                    emailTitularAntiFraude:this.emailTitularAntiFraude,
                    celularTitularAntiFraude:this.celularTitularAntiFraude.replace(/\D/g, ''),
                    cpfTitularAntiFraude:this.cpfTitularAntiFraude
                })
            }
            this.confirmMessage = false
        }
    },
    watch: {
    },
    async created() {
        await this.listarPaises()
        await this.listarEstados()
        await this.listarCidades()
    },
    async mounted(){
        //await this.listarPaises()
        // await this.listarEstados()
        // await this.listarCidades()
        this.$http.post("webcheckout/buscarParametro", {
            idhotel: localStorage.getItem("hotel"),
            chave: "DEBCREDCONFIG",
        }).then((resp) => {
            if (resp.status == 200) {
                this.parcelasMaxima = resp.data[0]?resp.data[0].valor2:1
            } else {
                this.$snackW("Não foi possivel buscar os dados");
            }
        })
        .catch((error) => {
          console.log(error);
        });      
    }
}
</script>

<style scoped>
.cc-principal {
  margin: 0px;
  background-color:rgb(235, 235, 245)!important;
  width:100%;
  min-height:100%;
}
.cc-cartao {
  width: 100%;
  margin: auto;
  margin-bottom: 0px;
  padding: 0.9rem;
  /* background-color: #1560E5; */
  display:flex;
  flex-direction:row;
  justify-content: center;
  border-radius: 0rem 0rem 0.9rem 0.9rem;
}
.cc-pagar-btn {
  font-size: 1rem!important;
  font-weight: bold;
  background-color: white!important;
  padding: 1.5vh;
  border-radius: 2vh;
}
.cc-pagar-btn:disabled {
  background-color: #8d8db4!important;
}
.cc-sinal-moeda {
  color: white!important;
  background-color: rgb(40, 163, 63)!important;
  padding: 1.65vh;
  padding-left: 1.9vh;
  padding-right: 1.9vh;
  margin-right: 0.7vh;
  border-radius: 50%;
}
.cc-green {
  color: green!important;
}
.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label{
    color: #70708C !important;
}
.i-nometitular-antifraude{
    text-transform: uppercase;
}
    </style>
<template>
        
        <div class="" style="flex-wrap:wrap;width:100%;">
            
            <h2 style="text-align:center;padding-top:3vh;margin-bottom:50px;font-size:20px!important;" >{{ "Clique no Hospede Principal da Sua Reserva" }}</h2>

                    <div class="resumoInicialHospedes"  v-for="hospede in hospedesprincipais" :key="hospede.id"
                        style="margin-bottom:30px;padding-bottom:0px!important;" @click="direcionarParaReservas(hospede.numeroreserva)">
                        <div style="margin-bottom:10px;display:flex;">
                            <div style="margin-right:20px;font-weight:bold!important;">Numero da Reserva:</div>
                            <div>{{hospede.numeroreserva}}</div>
                        </div>
                        <div style="margin-bottom:10px;display:flex;">
                            <div style="margin-right:20px;font-weight:normal!important;"><b>Nome: </b></div>
                            <div>{{hospede.nome+' '+hospede.sobrenome}}</div>
                        </div>
                        <div style="margin-bottom:20px;display:flex;justify-content:space-between;">
                            <div style="margin-bottom:10px;">
                                <div><b>Chegada</b></div>
                                <div>{{dados.datacheckinprevisto | formatarData}}</div>
                            </div>
                            <div style="margin-bottom:10px;">
                                <div><b>Saída</b></div>
                                <div>{{dados.datacheckoutprevisto | formatarData}}</div>
                            </div>
                        </div>
                        
                        
                    </div>


           
        </div>

</template>

<script>

// import moment from 'moment'
export default { 
    props:["dados"],
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            hospedesprincipais:[],
            hospedeprincipal:null

        }
    },
    methods:{
        direcionarParaReservas(numeroreserva){


            var dadossalvar     = this.dados
            dadossalvar.numeroreserva   = numeroreserva

           
            this.$emit('atualizarDados',dadossalvar,'Reservas')
        },
        listarHospedesPrincipais(){
       
            var nome = null
            var sobrenome = null
         

            if(this.dados.nome){
             
                var nomedividido = this.dados.nome.split(' ')
                if(nomedividido.length > 1){
                    nome = nomedividido[0]
                    sobrenome = nomedividido[1]
                }else{
                    nome = nomedividido[0]
                }
            }



            this.$http.post("reserva/select",{
                principal:true,
                idempresareservante:this.dados.canal,
                datacheckinprevisto:this.dados.datacheckinprevisto,
                datacheckoutprevisto:this.dados.datacheckoutprevisto,
                hotel:1,
                nome:nome,
                sobrenome:sobrenome
                // hotel:localStorage.getItem('hotel'),
                })
            .then(resp =>{
          
                    // alert(JSON.stringify(resp.data))
                    this.hospedesprincipais = resp.data.filter(p => {
                        return p.nome != undefined;
                    }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);

                    this.hospedeprincipal = this.hospedesprincipais[0]
                    

            }).catch(error =>{
                alert(error)
            })
        },
        
        salvarDados(){
            
            // var dadossalvar     = this.dados
            // dadossalvar.canal   = this.canal.idempresareservante
           
            // this.$emit('atualizarDados',dadossalvar,'Reservas')
            

        },
        async avancar(){

            if( await this.validarFormulario() ){
                this.salvarDados()
            }  
            
        }
    },
    mounted(){
        this.listarHospedesPrincipais()
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
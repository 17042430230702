<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <div class="wout-topo-pgto">
            <div style="width:15%;">
                <button class="menu-voltar-btn" @click="voltar()">
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </button>
            </div>
            <div class="text-subtitle-2 px-2" style="width:70%;color:white!important;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" style="color:white!important;" v-if="dados">
                    
                    <v-row>
                        <v-col style="font-size:14px;font-weight:200;">
                             Apto.{{dados.uh}}
                        </v-col>
                        <v-col style="font-size:14px;font-weight:200;">
                            <span >Reserva</span>: {{dados.numeroreserva}}
                        </v-col>
                    </v-row>
                    
                </div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" @click="logout">mdi-power</v-icon>
            </div>
        </div>
        <!-- Conteúdo -->
        <pgto-cartao :dados="dados" 
            @registrarPagamento="(dadoscartao) => registrarPagamento(dadoscartao)" />
    </div>
</template>

<script>
import PgtoCartao from './PgtoCartao.vue'

export default {
    props:["dados"],
    data: () =>{
        return{
            exibirDetalhes: true
        }
    },
    components: {
		PgtoCartao
	},
    computed: {
    },
    methods: {
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
        registrarPagamento(dadoscartao) {
            const dados = {
                ...dadoscartao,
                idreservahospede: this.dados.idreservahospede,
                saldo: this.dados.saldo,
                idhotel: this.dados.idhotel,
                nomehotel: this.dados.nomehotel,
                cabecalhos: this.dados.cabecalhos,
                consumos: this.dados.consumos
            }
            this.$emit('direcionar', {to: 'avisoself', from: 'pagamento', dados})
            // this.$emit('registrarPagamento', {
            //     ...dadoscartao,
            //     saldo: this.dados.saldo,
            //     idhotel: this.dados.idhotel,
            //     nomehotel: this.dados.nomehotel,
            //     cabecalhos: this.dados.cabecalhos,
            //     consumos: this.dados.consumos
            // })
        }
    },
    mounted(){
        //        
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
<template>
    <div  >
        <div class="captura_container" v-if="passo == 0">
            <br>
            <br>
            <button @click="solicitarCapturaDoc()" type="button" >CAPTURAR DOC</button>
            <br>
            <br>
            <br>
            <button @click="solicitarCapturaAssinatura()" type="button" >CAPTURAR ASSINATURA</button>
        </div>
        
        
        
    </div>
    

    
</template>

<script>


export default {
  data: () => ({
    image: '',
    passo:0,
    tipoDocSelecionado:'RG'
  }),
  components:{
       
  },
  methods: {
    testeSocket(){
        this.$socket.emit('enviar_notificacao', {msg: 'teste' })  
    },
    setPasso(valor){
        this.passo = valor
    },
    setTipoDocSelecionado(valor){
        this.tipoDocSelecionado = valor
    },
    solicitarCapturaDoc(){
        this.$socket.emit('dash_to_leitor_solicitar_captura_doc', {
                          usuario: localStorage.getItem('useremail'), 
                          msg: '',
                          idHospede: 3,
                          idReserva: 45,
                          idReservaHospede: 9
                    })  
    },
    solicitarCapturaAssinatura(){
        this.$socket.emit('dash_to_leitor_solicitar_captura_assinatura', {
                          usuario: localStorage.getItem('useremail'), 
                          msg: '',
                          idHospede: 3,
                          idReserva: 45,
                          idReservaHospede: 9
                    })  
    }
  },
  mounted() {
    console.log('PASS')
    //Conectar ao WebSocket
    this.$socket.disconnect();
    this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
    this.$socket.io.opts.query.tipo = 'DASHBOARD'
    this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
    this.$socket.connect();
    this.$store.dispatch("setHiddenDrawer", true)

    //METODOS SOCKETS
    

    

   

    
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        /* color:#808080; */
        margin-bottom:10px;
        font-size:30px!important;
        font-weight:bold;
        text-align: center;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
<template>
    <v-dialog v-model="exibirModalDirectMail" width="800px" max-width="800px">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        <v-icon large>mdi-email</v-icon> Email 
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalDirectMail')" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                
                <v-card>
                    <div style="padding:10px;">
                        <v-text-field
                            readonly
                            label="Para:"
                            v-model="email"
                        >
                        </v-text-field>
                        <v-text-field
                            label="Assunto:"
                            v-model="assunto">
                        </v-text-field>
                    </div>
                    <div style="display:flex;justify-content:center;">
                        <v-textarea
                        outlined
                        no-resize
                        class="mr-2 ml-2"
                        v-model="mensagem"
                        label="Mensagem"

                        ></v-textarea>
                    </div>
                    <v-card-actions>
                        
                        <span style="min-width: 250px;"> <strong>{{ respostaAcao }}</strong> </span>
                        <v-spacer />
                        
                        <v-btn color="green" class="mb-2 mr-2" @click="enviarEmail()">
                            <v-icon small color="white">mdi-send</v-icon>
                            <span style="color: white;">Enviar</span>
                        </v-btn>
                        
                        
                    </v-card-actions>
                    
                    
                </v-card>
                
            </v-card-text>

            
        </v-card>
    </v-dialog>
</template>          

<script>


export default {
    data: () => ({      
        mensagem : "",
        assunto : "",
        respostaAcao : ""
    }),
    props:["exibirModalDirectMail", "email"],
    components:{
        // ModalSolicitacoesResponder
        
    },
    methods: {
        setModalDirectMail(){
            
            this.$emit('setModalDirectMail')
        },
        enviarEmail(){
            if(this.mensagem.length > 0 && this.assunto.length > 0){
                let body = {
                    destinatario : this.email,
                    assunto : this.assunto,
                    text : this.mensagem,
                    hotel:localStorage.getItem("hotel")
                }
                this.$http.post('/email/enviaremail', body).then(resp =>{
                    console.log(resp)
                    this.respostaAcao = "Email enviado"
                    setTimeout(() => { 
                        this.setModalDirectMail()
                    }, 2000);    
                        })
            }else{
                this.respostaAcao = "Conteúdo do Assunto e Mensagem não podem ser vazios."
            }
        }
    },
    mounted() {

        this.respostaAcao = ""
        this.mensagem = ""
        this.assunto = ""

    },
};
</script>
<template>
    
    <div class="py-4" style="flex-wrap:wrap;width:100%; overflow:auto;">


        <div class="cardInputs my-0" style="align-items:center;">
            
            <h5 class="py-2">{{ $t('webcheckin.assinatura.autografo') }}</h5>

            <VueSignaturePad data-cy="VueSignaturePad"  id="VueSignaturePad" width="95%" height="50vh" ref="signaturePad" style="background:white" saveType="image/png" />

            <div class="col-lg-12" style="text-align:center">
                <hr id="linha_assinatura" />
                <small>{{ $t('webcheckin.assinatura.suaassinaturaconstara') }}</small>
                <br />
                <button class="botaoLimpar" data-cy="btn-limparass" v-on:click.stop.prevent="limpar_assinatura()">
                    {{ $t('webcheckin.assinatura.apagar') }}
                    <i class="fa fa-trash"></i>
                </button>
            </div>

        </div>

        <div v-if="temtermo == 's'"  style="display:flex;flex-direction:column;justify-content:center;align-items:center;margin:0px;">
            <a  target="blank_" style="text-decoration:underline!important;font-size:16px;" @click="exibirModalTermo = true" >
                {{ $t('webcheckin.assinatura.vertermo') }}
            </a>
            <label style="margin-top:20px;font-size:18px;cursor:pointer;">
                <input type="checkbox" data-cy="input-termo" id="aceitetermo" name="aceitetermo"  
                    v-model="aceitetermo" true-value="sim" false-value="nao" 
                    style="transform:scale(2);margin:10px;cursor:pointer;"
                >
                <span class="checkmarkCheck" ></span>{{ $t('webcheckin.assinatura.li') }}
            </label>
        </div>


        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-assvoltar">
                    {{ $t('webcheckin.assinatura.voltar') }}
            </button>

            <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-assavancar">
                    {{ $t('webcheckin.assinatura.avancar') }}
            </button>
        </div> 
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>
        <v-dialog v-model="exibirModalTermo" width="90%"  style="min-height:90vh;">
            <v-card style="min-height:90vh;">
                
               <v-toolbar color="blue" class="mb-8">
                    <v-flex  style="display: flex" class="pa-2">
                        <span style="width:95%;color:white;font-weight:bold;" >
                            Termo
                        </span>
                        <span style="width:5%;padding-right:30px;"> 
                            <v-btn x-small color="red" @click="exibirModalTermo = false" title="Fechar">
                                <v-icon x-small style="color:white;">mdi-close</v-icon>
                            </v-btn>
                        </span>
                    </v-flex>
                </v-toolbar>
    
                
                <div style="display:flex;align-items:center;flex-direction:column;">
                    <div style="margin-bottom:20px;"> 
                            <v-btn @click="()=>{ this.pagina > 1 ? this.pagina -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                            <span style="font-size:20px;">Pagina {{pagina}}</span>
                            <v-btn @click="()=>{ this.pagina += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                        
                    </div>
                    <pdf :src="termoSrc" style="width:80%!important;"
                        :page="pagina" >
                    </pdf>
                    <div style="margin-bottom:20px;margin-top:20px;"> 
                            <v-btn @click="()=>{ this.pagina > 1 ? this.pagina -= 1: null }" small elevation="" class="success" style="margin-right:10px;" >Voltar</v-btn>
                            <span style="font-size:20px;">Pagina {{pagina}}</span>
                            <v-btn @click="()=>{ this.pagina += 1 }" small elevation="" class="info" style="margin-left:10px;" >Avançar</v-btn>
                        
                    </div>
                </div>
                
                
            </v-card>
        </v-dialog>


        <v-dialog v-model="loading" width="50%"  style="min-height:90vh;">
            <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                
                <h1>Aguarde!</h1>
                <div>Salvando seus dados...</div>
                
            </v-card>
        </v-dialog>
        
    </div>

    


</template>

<script>

// import CONSTANTS from '../constantes'
// import axios from 'axios'
import pdf from 'vue-pdf'
import dayjs from 'dayjs';
import {BUILD_TIPO_INTEGRACAO} from '@/constantes.js'

export default {
    props:["dados","dadosAcompanhantes","hospedePrincipal"],
    components: {
		pdf: pdf
	},
    data: () =>{
        return{
            pagina:1,
            arquivo_termo:null,
            aceitetermo:null,
            temtermo:null,
            id_termo:null,
            // img_assinatura:null,
            // id_web_checkin:null,
            // nome:null,
            // tokenWebCheckin:null,
            // tokenJWT:null,
            // step_web_checkin:null
            snack: false,
            snackColor: '',
            snackText: '',
            exibirModalTermo:false,
            termoSrc:null,
            termoTipo:null,
            loading:false,
        }
    },
    mounted(){
      //console.log('this.dadosaasdadasd', this.dados)

            this.$http.post("perfil/hotel/select",{id:this.dados.hotel})
            .then(resp => {
                     
                    if(resp.data[0].termo){

                        
                        this.id_termo = resp.data[0].termo
                        if(this.id_termo){
                            this.temtermo = 's'
                        }

                        this.$http.post("/imagem/select", {id:this.id_termo}, { timeout: 60000 })
                                    .then((response) => {

                                        const uint8Array = new Uint8Array(response.data[0].dados.data);
                                        this.termoSrc = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');

                                        this.termoTipo = response.data[0].tipo;

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });

                    }

            })
            .catch(error => {
                    console.log(error)
            })

    },
    methods: {
        limpar_assinatura() {
            this.$refs.signaturePad.undoSignature();
        },
        validarFormulario(){
           
            let { isEmpty } = this.$refs.signaturePad.saveSignature();

            if (isEmpty) {
                alert("A assinatura é obrigatoria")
                return false
            }

            if(this.temtermo == 's' && (this.aceitetermo == "nao" || this.aceitetermo == undefined || this.aceitetermo == "")){ 
                
                // this.$swal(this.$t('alert.notification_term_accept.title'),this.$t('alert.notification_term_accept.content'),"error"); 
                alert("A aceitação do Termo e Obrigatoria")
                return false
            }

            return true
   
        },
        async salvarDados(){

           //pega a assinatura
            let objetoAssinatura = this.$refs.signaturePad.saveSignature()

            if(!objetoAssinatura.isEmpty){

                let tipo = "image/png"
                let img = objetoAssinatura.data.split(',')[1]
                if (!img.length > 0) {
                    console.log("Imagem invalida");
                    return;
                }

                let dados =   {
                                "tipo" : tipo,
                                "nome" : "Assinatura",
                                "dados" : img,
                                "tabela": "hospede",
                                "fk": "assinatura",
                                "chave": "id",
                                "valor": this.dados.idhospede
                            }
                await this.$http.post("/imagem/upinsert",dados, {timeout:60000 }) 
                                .then((response) => {
                                    if(response.status == 200){
                                        console.log("Assinatura Salva com Sucesso!")
                                        console.log(response.data.result.imagem[0])

                                        var dadossalvar = this.dados
                                        dadossalvar.datafimwc = dayjs().format()
                                        dadossalvar.aceitetermo = (this.aceitetermo == "sim")
                                        dadossalvar.assinatura = response.data.result.imagem[0]
                                        dadossalvar.webcheckinfinalizado = true
                                        this.loading = false
                                        // this.$emit('salvarDados',dadossalvar,'formulariocovid')
                                        dadossalvar.assinado = true
                                        dadossalvar.webcheckinfinalizado = true
                                        // alert(1)
                                        // controle de direcionamento 
                                        if(this.dados.exibirPassosCovid){
                                           this.$emit('salvarDados',dadossalvar,'formulariocovid')
                                        }else{
                                            if(this.dados.exibirPassosObservacao){
                                                // this.$emit('direcionar','observacoes')
                                                this.$emit('salvarDados',dadossalvar,'observacoes')
                                            }else{
                                                if(this.dados.exibirPassosPerguntaExtra){
                                                    this.$emit('direcionar','perguntaextra')
                                                }else{

                                                    if(this.dados.exibirPassosHorario){ 
                                                        // this.$emit('direcionar','horario')
                                                        this.$emit('salvarDados',dadossalvar,'horario')
                                                    }else{
                                                        // if(this.hospedePrincipal && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                                                        if( this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                                                            // this.$emit('direcionar','hospedes') 
                                                            this.$emit('salvarDados',dadossalvar,'hospedes')
                                                        }else{
                                                            // this.$emit('direcionar','fim') 
                                                            this.$emit('salvarDados',dadossalvar,'fim')
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        // this.finalizarFicha()
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
            }else{
                alert("Realize a Assinatura")
            }



            

            // let { data } = this.$refs.signaturePad.saveSignature();

            // //criando canvas
            // var image  = document.createElement("img");

            // image.onload = () =>{
            //     var canvas = document.createElement("canvas");

            //     canvas.width  = image.width;
            //     canvas.height = image.height;
            //     var context = canvas.getContext("2d");
            //     context.drawImage(image, 0, 0);

            //     this.sendToStorage(canvas)
            // }
            // image.src = data 


            
        },
        async avancar(){
            
            if( await this.validarFormulario() ){
                this.loading = true
                this.salvarDados()
            }  
            
        },
        voltar(){
           
            // this.$router.push('/Checkin/DadoPessoal')
            this.$emit('direcionar','dadopessoal')
            
        },
        finalizarFicha(){
            var dadossalvar = this.dados

            dadossalvar.usuarioemail = localStorage.getItem('useremail')
            if(BUILD_TIPO_INTEGRACAO == "HITS" || BUILD_TIPO_INTEGRACAO == "SILBECK"){
                dadossalvar.ufnome = this.dados.ufsigla
            }

            if(BUILD_TIPO_INTEGRACAO == "HITS" ){

                this.$http.post("/integracao/finalizarFicha/HITS/"+localStorage.getItem("hotel"), {reserva: dadossalvar} ).then((dados) => {
                    this.finalizarAux(dados.data)
                }).catch(()=>{
                    // this.$emit('update')
                    // this.$emit('fechar')
                })
            }else{
                //continuar daqui endenter pq nao esta chegando até this.finalizarAux

                this.$socket.emit('dash_to_backend_finalizarficha', {reserva: dadossalvar})  
                this.sockets.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
                    this.sockets.unsubscribe("backend_to_dash_finalizarficharetorno")
                    this.finalizarAux(dados)
                });

            }

                

            //  this.$socket.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
            //     this.qtdeNotificacoes += 1
            //     console.log(dados)
            // });
        },
        finalizarAux(dados){
    
            if(dados.statusCode == 200){

                let body = {
                    reserva: {
                        id: this.dados.idreserva,
                        hotel: localStorage.getItem("hotel"),
                        fimfd:true
                    },
                    hospede: {
                        id: this.dados.idhospede,
                        idhospedepms: this.dados.idhospedepms,
                        enviadopmstimestamp: dayjs().format()
                    },
                };

                this.$http.post("/reserva/upinsert", body).then((response) => {
                    if (response.status == 200) {
                        this.btnFinalizarHabilitado = false
                        this.dados.enviadopms = true
                    
                        

                        let bodyRemoverTempMerge = {
                            numeroreserva:this.dados.numeroreserva,
                            idhospedepms:this.dados.idhospedepms
                        } 
                

                        this.$http.post("/reserva/removerReservaTemp", bodyRemoverTempMerge).then(() => {
                            // this.$emit('update')
                            // this.$emit('fechar')
                        }).catch(()=>{
                            // this.$emit('update')
                            // this.$emit('fechar')
                        })

                        
                    } else {
                        console.log('error in assinatura', JSON.stringify(response))
                    }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(JSON.stringify(body));
                    });

            }else{
                // alert(dados.resposta)
                alert(`Robô não respondeu de Forma Satisfatoria: \n - ${dados.resposta} `)
            }

            this.textobtnFinalizar = "Finalizar Ficha"

        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
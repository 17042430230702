<template>
    <v-dialog v-model="exibirModalFollowUpWebCheckin" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Envios Automáticos de Web Checkin
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Envios Automáticos de Web Checkin"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalFollowUpWebCheckin()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="enviarwebcheckin" data-cy="nodia" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Envio de WebCheckin liberado?</label>
                        <div style="padding-left:1.5rem;" v-if="enviarwebcheckin">
                            <label>Apenas para este endereço de email (teste):</label>
                            <v-text-field dense v-model="emailwebcheckin"></v-text-field>
                        </div>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="nodia" data-cy="nodia" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente no dia do Checkin?</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="umdia" data-cy="umdia" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 1 dia antes do Checkin?</label>    
                    </div>

                     <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="doisdias" data-cy="doisdias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 2 dias antes do Checkin?</label>      
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="cincodias" data-cy="cincodias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 5 dias antes do Checkin?</label>      
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="dezdias" data-cy="dezdias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 10 dias antes do Checkin?</label>      
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="trintadias" data-cy="trintadias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 30 dias antes do Checkin?</label>      
                    </div>

                    

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" data-cy="salvar" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';



export default {
    data: () => ({
        enviarwebcheckin:false,
        emailwebcheckin:'',
        nodia:false,
        umdia:false,
        doisdias:false,
        cincodias:false,
        dezdias:false,
        trintadias:false,
        textobotaosalvar:"SALVAR",
    }),
    props:["exibirModalFollowUpWebCheckin"],
    components:{
    BotaoTutorial
},
    methods: {
        mudarCorBotao(cor){

            if(cor == 'AZUL'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERDE'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #15e57d;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERMELHO'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #e51515;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

        },
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarENVIOAUTOWEBCHECKIN().then(()=>{
                this.salvarENVIOAUTOWEBCHECKIN1().then(()=>{
                    this.salvarENVIOAUTOWEBCHECKIN2().then(()=>{
                        this.setModalFollowUpWebCheckin()
                    })
                })
            })
            
        },
      
        salvarENVIOAUTOWEBCHECKIN(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOAUTOWEBCHECKIN',
                    "valor" : this.nodia ? 'true' : 'false',
                    "valor2" : this.umdia ? 'true' : 'false',
                    "valor3" : this.doisdias ? 'true' : 'false',
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarENVIOAUTOWEBCHECKIN1(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOWEBCHECKINLIBERADO',
                    "valor" : this.enviarwebcheckin ? 'true' : 'false',
                    "valor2" : this.enviarwebcheckin ? this.emailwebcheckin : ''
                }).then(resp =>{
                    console.error('salvarENVIOAUTOWEBCHECKIN1')
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarENVIOAUTOWEBCHECKIN2(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOAUTOWEBCHECKIN2',
                    "valor" : this.cincodias ? 'true' : 'false',
                    "valor2" : this.dezdias ? 'true' : 'false',
                    "valor3" : this.trintadias ? 'true' : 'false',
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalFollowUpWebCheckin(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalFollowUpWebCheckin')
        },
    },
    mounted() {

        this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOAUTOWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){
                    this.nodia      = resp.data[0].valor === 'true' ? true : false
                    this.umdia   = resp.data[0].valor2   === 'true' ? true : false
                    this.doisdias  = resp.data[0].valor3 === 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOAUTOWEBCHECKIN2'
            }).then(resp =>{
                if(resp.status == 200){
                    this.cincodias      = resp.data[0].valor  === 'true' ? true : false
                    this.dezdias        = resp.data[0].valor2 === 'true' ? true : false
                    this.trintadias     = resp.data[0].valor3 === 'true' ? true : false
                    // alert(this.cincodias)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOWEBCHECKINLIBERADO'
            }).then(resp =>{
                if(resp.status == 200){
                    if (resp.data.length > 0) {
                        this.enviarwebcheckin = resp.data[0].valor === 'true' ? true : false
                        this.emailwebcheckin  = resp.data[0].valor2
                    }
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>
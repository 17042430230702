<template>
  <v-dialog v-model="show" width="90vw" max-width="700px" persistent>
    <Loading v-if="loading"/>
    <div style="display: flex; flex-direction: column; background-color: white;" v-if="!loading">
      <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
        <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
          <span class="toolbar-title" style="width:80%;padding:10px;"> Telas de agrupamento de perguntas </span>
          <span style="width:5%"> 
            <v-btn x-small color="red" @click="() => this.$emit('fecharModalTelaDeAgrupamento')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <div style="display: flex; width: 100%; justify-content: flex-end; padding: 15px;">
        <v-btn title="Adicionar nova tela" color="#1377f2" elevation="2" icon fab @click="adicionarTela"> 
          <v-icon size="28s" color="#1377f2" >mdi-plus-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-data-table
              v-if="!loading"
              :headers="headers"
              :items="telasDeAgrupamento"
              class="elevation-1"
            >
  
            <template v-slot:[`item.editar`]="{ item }">
              <button class="buttonsalvar" @click="() => editarTela(item)">Editar</button>
            </template>
        
            </v-data-table> 
    </div>
    <v-dialog v-model="modalAdicionarTela" width="60vw" max-width="500px" persistent>
    <div style="display: flex; flex-direction: column; background-color: white;">
      <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
        <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
          <span class="toolbar-title" style="width:80%;padding:10px;"> {{ textoModalAddTela }} </span>
          <span style="width:5%"> 
            <v-btn x-small color="red" @click="() => modalAdicionarTela = false" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <div style="padding: 10px; display: flex; flex-direction: column; align-items: flex-end; margin-top: 15px;">
        <v-text-field v-model="tela.descricao" style="width: 100%;" label="Nome da tela de agrupamento" outlined></v-text-field>
        <button class="buttonsalvar" @click="salvarEditarTela">{{ textoBotaoSalvar }}</button>
      </div>
    </div>
  </v-dialog>
   </v-dialog>
  </template>
  
  <script>
    import Loading from '../ConfiguracoesWebCheckin/Loading.vue';
   export default {
    props:['show'],
    components: {
      Loading
    },
    data() {
      return {
        headers: [
            { text: "Descrição", value: "descricao" },
            { text: "Editar", value: "editar" },
        ],
        telasDeAgrupamento: null,
        loading: true,
        modalAdicionarTela: false,
        tela: {
          id: null,
          descricao: null,
        },
        textoModalAddTela: 'Nova tela',
        textoBotaoSalvar: 'Salvar'
      }
    },
    methods: {
  
      adicionarTela() {
        this.textoModalAddTela = 'Nova tela de agrupamento'
        this.tela = {
          id: null,
          descricao: null,
        }
        this.modalAdicionarTela= true
      },
  
      async requisitarTelas() {
        try {
         const result = await this.$http.post('/formulario/buscar/telasdeagrupamento')
         console.log(result.data)
         this.telasDeAgrupamento = result.data;
        } catch (error) {
          console.log(error)
        }
      },
  
      async salvarEditarTela() {
        if(!this.tela.descricao) {
          return;
        }
        this.textoBotaoSalvar = 'Aguarde...'
        await this.$http.post('formulario/criar/telasdeagrupamento', 
        [this.tela])
        .then(async () => {
          this.modalAdicionarTela = false;
          await this.requisitarTelas();
          this.tela = {
            id: null,
            descricao: null,
          }
          this.textoBotaoSalvar = 'Salvar';
        })
        .catch((error) => {
          console.log(error)
          this.textoBotaoSalvar = 'Salvar';
        })
      },
  
      editarTela(tela) {
        this.textoModalAddTela = 'Editar tela de agrupamento'
        this.tela = {
          id: tela.id,
          descricao: tela.descricao,
        }
        this.modalAdicionarTela = true;
      },
  
      async salvar() {
        this.loading = true;
        await this.$http.post('configWebCheckin/salvarContatosWebCheckin', 
        this.contatos)
        .then(async () => {
          this.loading = false;
          this.tela = {
            id: null,
            descricao: null,
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error)
        })
      }
    },
    async mounted() {
      await this.requisitarTelas();
      this.loading = false;
    }
   }
  </script>
  
  
  <style scoped>
  .buttonsalvar {
    background-color: #2B81D6;
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
    font-weight: 100;
    width: 100px;
  }
  </style>
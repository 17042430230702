<template>
  <v-dialog
    v-model="exibirModalWebcheckout"
    width="1000"
    max-width="80vw"
  >
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Integração Webcheckout
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações Webcheckout"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalWebcheckout()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <v-flex>
          <div
          >
          <v-row dense>
            <v-col cols="12">
              <v-card-text>
                Mapeamento dos códigos de pagamento para integração com o HITS<br>
                Esses serão usados como padrão caso o tipo exato não seja encontrado.
              </v-card-text>
            </v-col>
            <!-- Crédito -->
            <v-col cols="12">
              <v-select
                data-cy="tipoPgtoCredito"
                append-icon="mdi-card"
                label="Tipo de pagamento para crédito"
                :items="tiposPgto"
                item-value="paymentTypeId"
                item-text="name"
                v-model="tipoPgtoCredito"
                return-object
              >
              </v-select>
            </v-col>
            <!-- Débito -->
            <v-col cols="12">
              <v-select
                data-cy="tipoPgtoDebito"
                append-icon="mdi-credit-card"
                label="Tipo de pagamento para débito"
                :items="tiposPgto"
                item-value="paymentTypeId"
                item-text="name"
                v-model="tipoPgtoDebito"
                return-object
              >
              </v-select>
            </v-col>
            <!-- Pix -->
            <v-col cols="12">
              <v-select
                data-cy="tipoPgtoPix"
                append-icon="mdi-cash"
                label="Tipo de pagamento para PIX"
                :items="tiposPgto"
                item-value="paymentTypeId"
                item-text="name"
                v-model="tipoPgtoPix"
                return-object
              >
              </v-select>
            </v-col>
          </v-row>

          </div>
        </v-flex>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn
            @click="salvar()"
            data-cy="salvar"
            color="primary"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
//import tipos from './ModalWebcheckout.js';

export default {
  data: () => ({
    tipoPgtoCredito: null,
    tipoPgtoDebito: null,
    tipoPgtoPix: null,
    //tiposPgto: tipos.paymentTypes,
    tiposPgto: []
  }),
  props: ["exibirModalWebcheckout"],
  components: {
    BotaoTutorial
},
  methods: {

    async buscarParametro(chave) {
      return new Promise((resolve,reject)=>{
        this.$http
          .post("configuracao/buscarParametro", {
            idhotel: localStorage.getItem("hotel"),
            chave: chave,
          })
          .then((resp) => {
            if (resp.status == 200) {
              if (resp.data.length > 0) {
                resolve(parseInt(resp.data[0].valor));
              }
            } else {
              reject({message:"Não foi possivel buscar os dados"});
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    buscarTiposPgtosHits() {
        this.$http
        .post("/webcheckout/getPaymentTypes",{idhotel: localStorage.getItem("hotel")})
        .then((response) => {
          if (response.status == 200) {
            this.tiposPgto = response.data
          }
        })
        .catch((error) => {          
          console.log(error)
        });

    },
    async carregar() {
      this.tipoPgtoCredito = await this.buscarParametro("TIPO_PAGTO_HITS_CREDITO");
      this.tipoPgtoDebito = await this.buscarParametro("TIPO_PAGTO_HITS_DEBITO");
      this.tipoPgtoPix = await this.buscarParametro("TIPO_PAGTO_HITS_PIX");
      this.buscarTiposPgtosHits();
    },

    salvar() {
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "TIPO_PAGTO_HITS_CREDITO",
          valor: this.tipoPgtoCredito.paymentTypeId
        })
        .then(() => {
          this.$http
            .post("configuracao/atualizarParametro", {
              idhotel: localStorage.getItem("hotel"),
              chave: "TIPO_PAGTO_HITS_DEBITO",
              valor: this.tipoPgtoDebito.paymentTypeId
            })
            .then(() => {
              this.$http
                .post("configuracao/atualizarParametro", {
                  idhotel: localStorage.getItem("hotel"),
                  chave: "TIPO_PAGTO_HITS_PIX",
                  valor: this.tipoPgtoPix.paymentTypeId
                })
                .then(() => {
                  this.setModalWebcheckout();
                })
              .catch((error) => {
                console.log(error);
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });

    },

    setModalWebcheckout() {
      this.$emit("setModalWebcheckout");
    },

  },
  async mounted() {
    this.carregar();
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>
<template>

    <div class="" style="flex-wrap:wrap;width:100%; overflow:auto;">

        <div style="text-align:center;margin-top:20px;">
           <img id="icone1" style="max-height:10vh;" src="@/assets/img/icone1.png" alt="">
        </div>
       
        <h1 style="text-align:center;">{{ $t('webcheckin.observacoes.algoquedeseja') }}</h1>
        <div style="display:flex;flex-wrap:wrap;justify-content:center;" >

            <div 
              :style="(solicitacao.selecionado ?'background-color:#31B44A;color:white;':'background-color:#c5c5c5;color:black;')+'cursor:pointer;min-height:50px;max-width:40vw;min-width:200px;margin-right:10px;margin-top:10px;border-radius:5px;font-size:16px;font-family:Arial, Helvetica, sans-serif;display:flex;flex-direction:column;justify-content:center;align-items:center;'"
              v-for="solicitacao in solicitacoesTipo" :key="solicitacao.id"
              @click="marcarDesmarcarSolicitacao(solicitacao.id)"
              data-cy="obervacao-hospede"
              
              >
              {{solicitacao.descricao}}

            </div>
        </div>

        <div class="cardInputs" style="margin-top:40px;">
            <textarea id="texto-obs" name="" data-cy="textarea-observacao" v-model="obs" style="background-color:white;border-radius:10px;padding:10px;"  cols="30" rows="5">
            </textarea>
        </div>


        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-obsvoltar">
                    {{ $t('webcheckin.observacoes.voltar') }}
            </button>

            <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-obsavancar">
                    {{ $t('webcheckin.observacoes.avancar') }}
            </button>
        </div> 
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>
    </div> 

</template>

<script>

// import CONSTANTS from '../constantes'
// import axios from 'axios'

export default {
    props:["dados","dadosAcompanhantes","hospedePrincipal"],
    data: () =>{
        return{
            solicitacoesTipo:[],
            solicitacoesUsuario:[],
            obs:null,
            // id_hotel:null,
            // id_web_checkin:null,
            // opcoes: [],
            // observacoes_hospede: "",
            // observacao_enviada: false,
            // opcoes_selecionadas:[],
            // cnpj:null,
            // tokenJWT:null
            snack: false,
            snackColor: '',
            snackText: '',
        }
    },
    
    mounted(){
       this.listarSolicitacoes();
       this.obs = this.dados.obs
    },
    methods: {
        marcarDesmarcarSolicitacao(id) {
      
            this.solicitacoesTipo.find(x => x.id === id).selecionado = !this.solicitacoesTipo.find(x => x.id === id).selecionado
            let objetonovo = this.solicitacoesTipo.find(x => x.id === id)
            let index = this.solicitacoesTipo.findIndex(x => x.id === id)
            this.solicitacoesTipo.splice(index, 0)
            this.solicitacoesTipo[index] = objetonovo 

        },

        listarSolicitacoes() {
            this.$http.post("/reserva/solicitacaotipo/select").then(async (response) => {

                if(response.status == 200){

                    await this.listarSolicitacoesUsuario()
                    this.solicitacoesTipo = await response.data

                    for(var i=0; i < this.solicitacoesTipo.length; i++){
                        let objetosoliticacao = this.solicitacoesUsuario.find(sol => sol.solicitacao == this.solicitacoesTipo[i].id)
                        if(objetosoliticacao){
                            this.solicitacoesTipo[i].selecionado = true
                            this.solicitacoesTipo[i].idsolicitacaousuario = objetosoliticacao.id
                        }
                    }

                }
            }).catch(error =>{
                console.log(error) 
            });
        },

        async listarSolicitacoesUsuario() {
            await this.$http.post("/reserva/solicitacao/select",{reservahospede:this.dados.id}).then((response) => {

                if(response.status == 200){
                    this.solicitacoesUsuario = response.data
                }

            });
        },

        async salvarSolicitacoes() {
            var concluidoComSucesso = false
            var descricaoErro = ""
            var contadorAuxiliar = 0;

            for(var i =0; i < this.solicitacoesTipo.length; i++){
                contadorAuxiliar = i
                if(Object.keys(this.solicitacoesTipo[i]).length > 0){
                        

                        if(this.solicitacoesTipo[i].selecionado){
                            
                            let solicitacao = {
                                reservahospede:this.dados.id,
                                solicitacao:this.solicitacoesTipo[i].id
                            }
                            
                            if(this.solicitacoesTipo[i].idsolicitacaousuario){
                                solicitacao.id = this.solicitacoesTipo[i].idsolicitacaousuario
                            }

                            await this.$http.post("/reserva/solicitacao/upinsert", solicitacao)
                                            .then((response) => {
                                                if(response.status == 200){
                                                    concluidoComSucesso = true
                                                }else{
                                                    concluidoComSucesso = false
                                                    descricaoErro = "Não foi possível salvar a solicitacao "+this.solicitacoesTipo[contadorAuxiliar].descricao+".";
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error)
                                                concluidoComSucesso = false
                                                descricaoErro = "Não foi possível salvar a solicitacao "+this.solicitacoesTipo[contadorAuxiliar].descricao+".";
                                            });

                        }
                        else{

                            if(this.solicitacoesTipo[i].idsolicitacaousuario){
                                
                            
                                let solicitacao = {
                                    reservahospede:this.dados.id,
                                    solicitacao:this.solicitacoesTipo[i].id
                                }

                                solicitacao.id = this.solicitacoesTipo[i].idsolicitacaousuario

                                await this.$http.post("/reserva/solicitacao/delete", solicitacao)
                                                .then((response) => {
                                                    if(response.status == 200){
                                                        concluidoComSucesso = true
                                                    }else{
                                                        concluidoComSucesso = false
                                                        descricaoErro = "Não foi possível deletar a solicitacao "+this.solicitacoesTipo[contadorAuxiliar].descricao+".";
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                    concluidoComSucesso = false
                                                    descricaoErro = "Não foi possível deletar a solicitacao "+this.solicitacoesTipo[contadorAuxiliar].descricao+".";
                                                });
                            
                            }
                        }
                }

            }


            console.log("concluidoComSucesso "+concluidoComSucesso)
            console.log("descricaoErro "+descricaoErro)
        },
        // adicionarObservacao( observacao ) {
        //     this.opcoes = this.opcoes.filter( (it)=>{ 
        //         if(it == observacao){
        //             if(it.selecionado == undefined){
        //                 it.selecionado = true
        //             }else{
        //                 it.selecionado = !it.selecionado
        //             }
        //         }

        //         return it
        //     })

        //     this.opcoes_selecionadas = this.opcoes.filter( (it) => {
        //         return it.selecionado == true
        //     })
        // },
        async avancar(){

                await this.salvarSolicitacoes() 
                var dadossalvar = this.dados
                dadossalvar.obs = this.obs

                // this.$emit('salvarDados',dadossalvar,'horario')
                if(this.dados.exibirPassosHorario){
                    this.$emit('salvarDados',dadossalvar,'horario')
                }else{
                    if(this.dados.exibirPassosPerguntaExtra){
                        this.$emit('direcionar','perguntaextra')
                    }else{

                        // if(this.hospedePrincipal && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                        if(this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                            this.$emit('salvarDados',dadossalvar,'hospedes')   
                        }else{
                            this.$emit('salvarDados',dadossalvar,'fim')   
                        }
                    }
                }



                // const body = {
                //     id_hotel: this.id_hotel,
                //     id_web_checkin: this.id_web_checkin,
                //     observacao: this.observacoes_hospede,
                //     lista_necessidades: this.opcoes_selecionadas,
                //     step_web_checkin:9
                // }

                // axios.post(CONSTANTS.API_URL + '/wcheckin/cadastrarObservacao', body, {
                //     headers: {
                //         'Authorization': `Bearer ${this.tokenJWT}`
                //     }, timeout: 120000
                // })
                // .then((response) => {
                //     if(response.status == 200){

                //         this.observacao_enviada = true
                //         // this.$router.push(`/Checkin/Horario`)
                //         this.$emit('direcionar','horario')

                //     }
                // })
                // .catch((error) => {
                //     this.$store.dispatch("setar_erromsg", "Falha ao Salvar na Tela de Observações: "+error.message)
                //     this.$swal("Falha ao Cadastrar", 
                //                 " msg: "+JSON.stringify(error.message)
                //                 // +" <br> --- dt:"+JSON.stringify(error.config.data)
                //                 // +" <br> --- at:"+JSON.stringify(error.config.headers.Authorization)
                //                 // +" <br> --- tk: "+JSON.stringify(this.tokenJWT)
                //                 ,"error");
                //     this.$router.push('/Checkin/Reiniciar')
                // })
        },
        voltar(){
            
            // this.$router.push('/Checkin/FormularioCovid')
            // this.$emit('direcionar','formulariocovid')
            if(this.dados.exibirPassosCovid){
                this.$emit('direcionar','formulariocovid')
            }else{
                this.$emit('direcionar','assinatura')
            }
            
        }


    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <!-- Coluna da Esquerda - Hotel selecionado ou novo -->
      <v-flex xs12 md8>
        <v-card>
          <v-card-title>
            <span class="toolbar-title" style="width:80%;padding:6px;">Hotel Selecionado</span>
            <!-- Botão para ajuste do leiaute -->
            <v-btn small class="mr-2" style="opacity:0;color:white;font-weight:bold;background-color:#0e5b7d;height:40px!important;">
              + Novo Hotel
            </v-btn>
            <span style="width:5%;padding:5px;"> 
                <BotaoTutorial style="margin-right:10px;" urlVideo="" categoria="" legenda="Dúvidas Hotel Selecionado"/>
            </span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-container py-2>
                <v-layout wrap>
                  <v-flex xs12>
                    <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" />
                    <v-avatar slot="offset" class="mx-auto d-block" size="130">
                      <v-icon v-if="!imgSrc" x-large style="cursor:default;color: rgb(33, 150, 243);">mdi-domain</v-icon>
                      <!-- <img v-if="!imgSrc" src="https://media-cdn.tripadvisor.com/media/photo-s/1b/5a/2e/9e/exterior.jpg" /> -->
                      <img v-if="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" class="imgUpload" />
                    </v-avatar>
                    <div style="display:flex;justify-content:center;margin-top:20px;">
                      <label for="file-upload" >
                        <div style="cursor:pointer;background-color:#2196f3!important;border-radius:3px;width:100px;height:30px;display:flex;justify-content:center;align-items:center;color:white;font-weight:bold;font-size:16px;">
                          Foto
                        </div>
                      </label>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field id="nomeText" data-cy="input-nomefantasia" v-model="hotel.nome" label="Nome Fantasia" 
                      required :rules="regrasNome"
                    />
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field v-model="hotel.razaosocial" data-cy="input-razaosocial" label="Razão Social"
                      required :rules="regrasRazaoSocial"
                    />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field id="cnpjField" data-cy="input-cnpj" v-model="cnpj" label="CNPJ" 
                      required :rules="regrasCNPJ" type="numeric"
                    />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field id="idhotelpms" data-cy="input-cnpj" v-model="hotel.idhotelpms" label="ID HOTEL PMS" 
                      required  type="numeric"
                    />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field v-model="hotel.registroembratur" data-cy="input-registroembratur" label="Registro Embratur" 
                      type="text"
                    />
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field
                      data-cy="input-cep"
                      label="CEP"
                      v-model="hotel.cep"
                      @blur="buscarEndereco()"
                      required :rules="nameRules"
                    />
                  </v-flex>
                  <v-flex xs12 md3>
                    <span data-cy="select-pais-span">
                      <v-select
                        data-cy="select-pais"
                        append-icon="mdi-map"
                        label="País"
                        :items="paises"
                        item-value="id"
                        item-text="nome"
                        v-model="hotel.pais"
                        @change="[carregarEstados()]"
                        required
                        :rules="paises.length>0?regrasPais:[true]"
                        return-object
                      >
                      </v-select>
                    </span>
                  </v-flex>
                  <v-flex xs12 md2>
                    <span data-cy="select-estado-span">
                      <v-select
                        data-cy="select-estado"
                        append-icon="mdi-map-marker-multiple"
                        label="UF"
                        v-model="hotel.uf"
                        :items="ufs"
                        item-text="sigla"
                        item-value="id"
                        @change="[carregarCidades()]"
                        required
                        :rules="ufs.length>0?selectsRules:[true]"
                        return-object
                      >
                      </v-select>
                    </span>
                  </v-flex>
                  <v-flex xs12 md4>
                    <span data-cy="select-cidade-span">
                      <v-select
                        label="Cidade"
                        v-model="hotel.cidade"
                        data-cy="select-cidade"
                        :items="cidades"
                        item-text="nome"
                        item-value="id"
                        required
                        :rules="cidades.length>0?selectsRules:[true]"
                        return-object
                      >
                      </v-select>                  
                    </span>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field label="Bairro" data-cy="input-bairro" v-model="hotel.bairro"
                      required :rules="nameRules"
                    />
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field v-model="hotel.endereco" data-cy="input-endereco" label="Endereço" 
                      required :rules="nameRules"
                    />
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field id="numeroresidencia" label="Nº" data-cy="input-numero" v-model="hotel.numero"
                      required :rules="nameRules"
                    />
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field v-model="hotel.complemento" data-cy="input-complemento" label="Complemento" />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field v-model="hotel.telefone" label="Telefone" data-cy="input-telefone"
                      required :rules="nameRules" type="numeric" @keyup="clearNonMumeric(hotel.telefone,'telefone')"
                    />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field v-model="hotel.whatsapp" label="Whatsapp" data-cy="input-wtp"
                      required :rules="nameRules" type="numeric" @keyup="clearNonMumeric(hotel.whatsapp,'whatsapp')"
                    />
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field v-model="hotel.email" label="E-mail" data-cy="input-email"
                      required :rules="emailRules"
                    />
                  </v-flex>
                  <!-- <v-flex xs12 md5>
                    <v-text-field v-model="hotel.sitereserva" label="Site Reservas" data-cy="input-site"
                      required :rules="nameRules"
                    />
                  </v-flex> -->
                  <!-- <v-flex xs12>
                    <v-textarea class="purple-input" label="Apresentação"
                      data-cy="textarea-apresentacao"
                      placeholder="Entre com uma apresentação do hotel."
                      required :rules="nameRules" v-model="hotel.apresentacao"
                    />
                  </v-flex> -->
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn class="mx-0 font-weight-light" data-cy="btn-salvar"
              color="primary" @click="salvarHotel" :disabled="!this.valid">
              Salvar
            </v-btn>            
          </v-card-actions>
        </v-card>
                
      </v-flex>
      <!-- Coluna da Direita - Hotéis cadastrados -->
      <v-flex xs12 md4>
        <v-card>
          <v-card-title>
            <span class="toolbar-title">Hotéis Cadastrados</span>
            <v-spacer/>
            <v-btn @click="novoHotel()" data-cy="btn-criarhotel" small class="mr-2" style="color:white;font-weight:bold;background-color:#0e5b7d;height:40px!important;">
              + Novo Hotel
            </v-btn> 
            <span style="width:10%;padding:10px;"> 
                <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Hotéis Cadastrados"/>
            </span>
          </v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            data-cy="input-buscar"
          />
          <v-data-table
            :headers="headers"
            :items="hoteis"
            :rows-per-page="5"
            :search="search"
            item-key="nome"
          >
          <template v-slot:item="{item}">
            <tr :class="item.nome==hotel.nome?'table-row selected':'table-row'" :data-cy="`hotel_${item.nome}`" @click="selecionarHotel(item)">
              <td data-cy="td-nome">
                {{item.nome}}
              </td>
              <td>
                {{item.cnpj}}
              </td>
              <td style="text-align:center;">
                {{item.idhotelpms}}
              </td>
            </tr>
          </template>
          </v-data-table>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
              </div>
          </v-snackbar>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {serializarImagemNew} from '../../utils/serializar'
import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
    data: () => ({
        valid: false,
        regrasNome: [(v) => !!v || "Campo Obrigatório"],
        regrasRazaoSocial: [(v) => !!v || "Campo Obrigatório"],
        regrasPais: [(v) => !!v || "Campo Obrigatório"],
        nameRules: [(v) => !!v || "Campo Obrigatório"],
        selectsRules: [(v) => !!v || "Campo Obrigatório"],
        emailRules: [
            v => !!v || "E-mail is required",
            v => /.+@.+/.test(v) || "E-mail must be valid"
        ],
        cnpj: "",
        idhotelpms: null,
        imgSrc: null,
        imgTipo: null,
        snack: false,
        snackColor: "",
        snackText: "",
        search: "",
        hoteis: [],
        headers: [
            { text: "Nome", value: "nome" },
            { text: "CNPJ", value: "cnpj" },
            { text: "ID Hotel PMS", value: "idhotelpms", align: "center" },
        ],
        paises: [],
        ufs: [],
        cidades: [],
        hotel: {},
        hotelDefault: {
            id: 0,
            nome: "",
            razaosocial: "",
            cnpj: "",
            registroembratur: "",
            cep: "",
            pais: null,
            uf: null,
            cidade: null,
            bairro: "",
            endereco: "",
            complemento: "",
            numero: "",
            telefone: "",
            whatsapp: "",
            email: "",
            //sitereserva: '',
            //apresentacao: ''
        },
    }),
    computed: {
        regrasCNPJ() {
            const rules = [];
            const rule = v => this.validarCNPJ(v) || "CNPJ inválido.";
            rules.push(rule);
            return rules;
        }
    },
    watch: {
        cnpj: "validateForm"
    },
    methods: {
        clearNonMumeric(fieldValue, fieldName) {
            this.hotel[fieldName] = fieldValue.replace(/\D/g, "");
            return fieldValue;
        },
        validateForm() {
            if (this.$refs.form) {
                this.$refs.form.validate();
            }
        },
        validarCNPJ(cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g, "");
            if (cnpj == "")
                return false;
            if (cnpj.length != 14)
                return false;
            // Elimina CNPJs invalidos conhecidos
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999")
                return false;
            // Valida DVs
            var tamanho = cnpj.length - 2;
            var numeros = cnpj.substring(0, tamanho);
            var digitos = cnpj.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;
            return true;
        },
        novoHotel() {
            this.hotel = Object.assign({}, this.hotelDefault);
            this.imgSrc = null;
            this.imgTipo = null;
            this.idhotelpms = null;
            this.cnpj = this.hotel.cnpj;
            this.hotel.pais = this.buscarPaisPadrao();
            this.carregarEstados();
            this.carregarCidades();
            var el = document.getElementById("nomeText");
            if (el)
                el.focus();
        },
        carregarPaises() {
            this.$http.post("/geo/pais/select", { 
              hotel: localStorage.getItem("hotel"),
              orderby: "noaccent(nome)" 
            }).then((response) => {
                this.paises = response.data;
            })
                .catch((error) => {
                console.log(error);
            });
        },
        carregarEstados() {
            var params = {
                pais: this.hotel.pais.id
            };
            this.$http.post("/geo/uf/select", params)
                .then((response) => {
                this.ufs = response.data;
            })
                .catch((error) => {
                console.log(error);
            });
        },
        buscarCidade(codigoibge) {
            var result = {};
            for (var i = 0; i < this.cidades.length; i++) {
                if (this.cidades[i]["codigoibge"] == codigoibge) {
                    result = this.cidades[i];
                    break;
                }
            }
            return result;
        },
        translate(texto, find, subst) {
            var result = texto;
            if (find.length == subst.length) {
                for (var j = 0; j < find.length; j++) {
                    result = result.replace(find[j], subst[j]);
                }
            }
            return result;
        },
        noaccent(texto) {
            var aux = "";
            aux = this.translate(texto, "áàâãä", "aaaaa");
            aux = this.translate(aux, "ÁÀÂÃÄ", "AAAAA");
            aux = this.translate(aux, "éèêë", "eeee");
            aux = this.translate(aux, "ÉÈÊË", "EEEE");
            aux = this.translate(aux, "íìîï", "iiii");
            aux = this.translate(aux, "ÍÌÎÏ", "IIII");
            aux = this.translate(aux, "óòõôö", "ooooo");
            aux = this.translate(aux, "ÓÒÕÔÖ", "OOOOO");
            aux = this.translate(aux, "úùûü", "uuuu");
            aux = this.translate(aux, "ÚÙÛÜ", "UUUU");
            aux = this.translate(aux, "ç", "c");
            aux = this.translate(aux, "Ç", "C");
            return aux;
        },
        prepare(x) {
            var y = "";
            if (x) {
                y = x.trim();
                y = this.noaccent(x);
                y = y.toLowerCase();
            }
            return y;
        },
        buscarCidadePeloNome(nome) {
            var result = {};
            for (var i = 0; i < this.cidades.length; i++) {
                var leftVal = this.prepare(this.cidades[i]["nome"]);
                var rightVal = this.prepare(nome);
                if (leftVal == rightVal) {
                    result = this.cidades[i];
                    break;
                }
            }
            return result;
        },
        carregarCidades(codigoibge, nomecidade) {
            var params = {
                uf: this.hotel.uf.id,
                orderby: "nome"
            };
            this.$http.post("/geo/cidade/select", params)
                .then((response) => {
                this.cidades = response.data;
                if (codigoibge) {
                    this.hotel.cidade = this.buscarCidade(codigoibge);
                    if (!this.hotel.cidade || (Object.keys(this.hotel.cidade).length === 0)) {
                        this.hotel.cidade = this.buscarCidadePeloNome(nomecidade);
                    }
                }
            })
                .catch((error) => {
                console.log(error);
            });
        },
        buscarUF(ufsigla) {
            var result = {};
            for (var i = 0; i < this.ufs.length; i++) {
                if (this.ufs[i]["sigla"] == ufsigla) {
                    result = this.ufs[i];
                    break;
                }
            }
            return result;
        },
        set(endereco) {
            if (endereco) {
                this.hotel.uf = this.buscarUF(endereco.uf);
                this.hotel.endereco = endereco.logradouro;
                this.hotel.bairro = endereco.bairro;
                this.hotel.complemento = endereco.complemento;
                this.carregarCidades(endereco.ibge, endereco.localidade);
                var el = document.getElementById("numeroresidencia");
                if (el)
                    el.focus();
            }
            else {
                this.hotel.pais = this.buscarPaisPadrao();
                this.hotel.uf = null;
                this.hotel.cidade = null;
                this.hotel.bairro = "";
                this.hotel.endereco = "";
                this.hotel.complemento = "";
            }
        },
        buscarEndereco() {
            var cep = null;
            if (this.hotel.cep) {
                cep = this.hotel.cep.replace(/\D/g, "");
            }
            else {
                return;
            }
            if (cep.length == 8) {
                var validacep = /^[0-9]{8}$/;
                if (validacep.test(cep)) {
                    var req = new XMLHttpRequest();
                    req.open("GET", `https://viacep.com.br/ws/${cep}/json/`);
                    var end = null;
                    req.onreadystatechange = () => {
                        if (req.readyState === 4) {
                            if (req.status === 200)
                                end = JSON.parse(req.response);
                            this.set(end);
                        }
                    };
                    req.send();
                }
                else {
                    this.snack = true;
                    this.snackColor = "orange";
                    this.snackText = "Formato de CEP inválido.";
                }
            }
            else {
                this.set(null);
            }
        },
        carregarImagem(id) {
            this.imgSrc = null;
            this.imgTipo = null;
            this.$http.post("/imagem/select", { id: id })
                .then((response) => {
                if (response.status == 200) {
                    var imgObj = response.data[0];
                    if (imgObj) {
                        this.imgSrc = Buffer.from(imgObj.dados).toString();
                        this.imgTipo = imgObj.tipo;
                    }
                }
            })
                .catch((error) => {
                console.log(error);
            });
        },
        buscarPaisPadrao() {
            var result = null;
            for (var i = 0; i < this.paises.length; i++) {
                if (this.paises[i]["padrao"]) {
                    result = this.paises[i];
                    break;
                }
            }
            return result;
        },
        selecionarHotel(item) {
            this.hotel = Object.assign({}, item);
            this.cnpj = this.hotel.cnpj;
            this.carregarImagem(item.logo);
            if (!this.hotel.pais) {
                this.hotel.pais = this.buscarPaisPadrao();
            }
            this.carregarEstados();
            this.carregarCidades();
        },
        async salvaLogo(tipoImg) {
            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }
            let dados = {
                "tipo": tipoImg,
                "nome": "LogoMarcaHotel",
                "dados": this.imgSrc,
                "tabela": "hotel",
                "fk": "logo",
                "chave": "id",
                "valor": this.hotel.id
            };
            await this.$http.post("/imagem/upinsert", dados, { timeout: 60000 })
                .then((response) => {
                if (response.status == 200) {
                    this.hotel.logo = response.data.result.imagem[0];
                }
                else {
                    console.log(JSON.stringify(response));
                }
            })
                .catch((error) => {
                console.log(error.response);
            });
        },
        async setImage(e) {
            const file = e.target.files[0];
            if (!file.type.includes("image/")) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    let index = event.target.result.indexOf(",") + 1;
                    this.imgSrc = event.target.result.substring(index, event.target.result.length);
                    if (this.imgSrc) {
                        this.salvaLogo(file.type);
                    }
                };
                reader.readAsDataURL(file);
            }
            else {
                alert("Sorry, FileReader API not supported");
            }
        },
        onFileChange(e) {
            // this.setImage(e)
            // serializarImagem(e, 0.5).then(resp => {
            //     this.imgSrc = resp.dados;
            //     this.salvaLogo(resp.tipo);
            // }).catch(error => {
            //     this.loading = false;
            //     this.snack = true;
            //     this.snackColor = "error";
            //     this.snackText = error.message;
            // });
            serializarImagemNew(e).then(resp =>{ 
              this.imgSrc = resp.dados
              this.salvaLogo(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
        },
        carregarHoteis() {
            this.$http.post("/perfil/hotel/select").then(async (response) => {
                this.hoteis = response.data;
                console.log(response.data)
                // Selecionar o primeiro
                let indicehotel = await response.data.findIndex((x) => {
                    return x.nome === localStorage.getItem("nomehotel");
                });
                this.selecionarHotel(indicehotel !== null && indicehotel !== undefined ? response.data[indicehotel] : 0);
            });
        },
        salvarHotel() {
            var params = {
                nome: this.hotel.nome,
                razaosocial: this.hotel.razaosocial,
                cnpj: this.cnpj,
                cep: this.hotel.cep,
                pais: this.hotel.pais.id,
                estado: this.hotel.uf.id,
                cidade: this.hotel.cidade.id,
                bairro: this.hotel.bairro,
                endereco: this.hotel.endereco,
                complemento: this.hotel.complemento,
                numero: this.hotel.numero,
                telefone: this.hotel.telefone,
                whatsapp: this.hotel.whatsapp,
                email: this.hotel.email,
                idhotelpms: this.hotel.idhotelpms,
                //sitereserva: this.hotel.sitereserva,
                //apresentacao: this.hotel.apresentacao,
                registroembratur: this.hotel.registroembratur
            };
            console.log(JSON.stringify(params));
            if (this.hotel.id > 0) {
                params.id = this.hotel.id;
            }
            this.$http
                .post("/perfil/hotel/upinsert", params)
                .then((response) => {
                if (response.status == 200) {
                    this.snack = true;
                    this.snackColor = "success";
                    this.snackText = "Dados salvos com sucesso.";
                    // Recarregar a lista de hotéis
                    this.carregarHoteis();
                }
                else {
                    if (response.status == 208) {
                        this.snack = true;
                        this.snackColor = "error";
                        this.snackText = "O CNPJ informado já existe!";
                        var textField = document.getElementById("cnpjField");
                        if (textField)
                            textField.focus();
                    }
                    else {
                        console.log(JSON.stringify(response));
                    }
                }
            })
                .catch((error) => {
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "O servidor retornou um erro interno.";
                console.log(JSON.stringify(params));
                console.log(JSON.stringify(error));
            });
        },
    },
    mounted() {
        this.carregarPaises();
        this.carregarHoteis();
    },
    components: { BotaoTutorial }
};
</script>
<style soped>
.table-row {
  cursor: pointer;
}
.selected {
  background-color: rgb(154, 209, 253)!important;;
}
.table-row:hover {
  background-color: rgb(33, 150, 243)!important;
  color: white;
  font-weight: bold;
}
</style>
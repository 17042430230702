<template>
    <v-dialog v-model="exibirModalEditarEmailAniversario" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar o Email que será enviado ao hospede no dia do seu Aniversario
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas E-mail Aniversário"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalEditarEmail()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                <!-- <v-card> -->
                    <div>
                        Inclua a tag <strong>@nome</strong> para que ela seja substituida automaticamente pelo nome do Hóspede
                    </div>
                    <div style="display:flex;justify-content:center;padding-bottom:10px;">
                        <textarea v-model="textoEmail"  data-cy="textoEmail" style="width:100%;min-height:200px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                        </textarea>
                    </div>
                    <div style="display:flex;justify-content:flex-end;">
                        <v-btn @click="salvarEmailAniversario()" data-cy="salvarEmailAniversario" color="primary">
                            Salvar
                        </v-btn>
                    </div>
                <!-- </v-card> -->

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';



export default {
    data: () => ({
        textoEmail:"Olá @nome, te desejamos um feliz aniversário repleto de felicidades."    
    }),
    props:["exibirModalEditarEmailAniversario"],
    components:{
    BotaoTutorial
},
    methods: {
        salvarEmailAniversario(){
            this.$http.post("configuracao/atualizarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EMAILANIVERSARIANTE',
                "valor" : this.textoEmail,
             }).then(resp =>{
                 if(resp.status == 200){
                     this.setModalEditarEmail()
                 }else{
                     alert("Não foi possivel alterar o texto do Email")
                 }
             })
             .catch(error =>{
                 console.log(error)
             })
        },
        setModalEditarEmail(){
            this.$emit('setModalEmailAniversario')
        },
    },
    mounted() {
        
        //busca o email de aniversariante configurado
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EMAILANIVERSARIANTE'
            }).then(resp =>{
                if(resp.status == 200){
                    this.textoEmail = resp.data[0].valor
                }else{
                    alert("Não foi possivel alterar o texto do Email")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>
<template>
        <div class="es_container" >
            <div class="py-3" style="display:flex;align-items:center;justify-content:center;">
                <h1 class="es_titulo" style="text-align:center;">{{nomehotel}}</h1>
            </div>
            <!-- <div class="es_apps" > -->
                <div class="es_links my-3 pa-2"
                    v-for="(tool,index) in tools" :key="index"
                    @click="direcionar(tool)" 
                >
                    <img width="auto" height="70" style="max-width:200px;"
                        :src="tool.image" :alt="tool.name"
                    />
                    <h3 class="pa-2">{{tool.description}}</h3>
                    <a style="color:white;font-size:1em!important;" 
                        :href="tool.link" target="_blank" :id="'link_'+index">Clique aqui</a>
                </div>
            <!-- </div> -->
        </div>
</template>

<script>
  export default {
    name: 'ESApps',

    components: {
    },
    data: () =>{
        return{
            tools:[
                {
                    name: 'Cardápio Digital', 
                    description: 'O Cardápio Digital foi projetado para dar o máximo de comodidade para seus hóspedes durante sua estadia no hotel.',
                    image: '/img/logocd.png',
                    link: 'https://cd.esmeraldapraiahotel.com.br/'
                },
                {
                    name: 'FD Guest', 
                    description: 'O FD Guest trás o que há de mais moderno no atendimento dos hotéis.',
                    image: '/img/guestfd-login.png',
                    link: 'https://dev.fdguest.economysoftware.com.br/'
                },
                {
                    name: 'Web Checkout', 
                    description: 'Com o Web Checkout você não enfrenta filas para pagar!',
                    image: '/img/logowout.png',
                    link: 'https://fichadigital.economysoftware.com.br/webcheckout'
                }
            ],
            nomehotel: localStorage.getItem('nomehotel')
        }
    },
    async created(){
        this.loadTools()
    },
    mounted(){

    },
    methods: {
        loadTools(){
            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'TOOLS'
            }).then(resp =>{
                if(resp.status == 200){
                    if (resp.data&&resp.data.length>0) {
                        let result = JSON.parse(resp.data[0].valor);
                        this.tools = result
                    }
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        },
        direcionar(tool){
            window.open(tool.link,'_blank')
        },
    },
    watch: {

    }

  }
</script>

<style scoped>
    .es_container {
        /* background-color:rgb(29, 29, 29); */
        background-color: white;
        width:100vw;
        height:100vh;
        display:flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
        opacity: 0.8;
        overflow: auto;
    }
    .es_titulo {
        font-size: 2rem!important;
        /* color:white; */
        color:black;
    }
    .es_apps {
        /* background-color: rgb(16, 76, 136); */
        background-color:black;
        color: white;
        width:80vw;
        /* height:70vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        flex-wrap:wrap;
        align-items: center;
        justify-content: center;
        border-radius: 1em;
    }
    .es_links {
        color: white;
        width: 80%;
        border: auto;
        background-color: rgb(25, 118, 210);
        cursor: pointer;
        border-radius: 1em;
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
</style>

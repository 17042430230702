<template>
    <v-dialog v-model="exibirModalBemVindos" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Envios Automáticos de Boas Vindas
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Envios Automáticos de Web Checkin"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalBemVindos()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="enviarwebcheckin"  data-cy="nodia" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Envio de Boas Vindas Liberado?</label>
                        <div style="padding-left:1.5rem;" v-if="enviarwebcheckin">
                            <label>Apenas para este endereço de email (teste):</label>
                            <v-text-field dense v-model="emailwebcheckin"></v-text-field>
                        </div>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="nodia" @change="setBotoes('nodia')" data-cy="nodia" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente no dia do Checkin?</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="umdia" @change="setBotoes('umdia')" data-cy="umdia" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 1 dia antes do Checkin?</label>    
                    </div>

                     <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="doisdias" @change="setBotoes('doisdias')" data-cy="doisdias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 2 dias antes do Checkin?</label>      
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="cincodias" @change="setBotoes('cincodias')" data-cy="cincodias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 5 dias antes do Checkin?</label>      
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="dezdias" @change="setBotoes('dezdias')" data-cy="dezdias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 10 dias antes do Checkin?</label>      
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="trintadias" @change="setBotoes('trintadias')" data-cy="trintadias" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Enviar Automaticamente 30 dias antes do Checkin?</label>      
                    </div>


                    <div style="font-size: 16px;width:50%;" >
                        <H2>Idioma:</H2>          
                        <v-select item-value="id" item-text="valor" color v-model="idiomaselecionado" :items="listaidiomas" return-object label=""></v-select>
                        
                    </div>


                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;margin-bottom:10px;" >
                        <H2>Titulo do email em {{idiomaselecionado.valor}} </H2>
                    </div>
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        Tags de Substituição Automáticas: @nomecliente, @numeroreserva, @datacheckin e @datacheckout
                    </div>
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;width:90%;" >
                          <v-text-field v-if="idiomaselecionado.id == 1" placeholder="@nomecliente, Seja Bem Vindo ao Hotel Exemplo!" v-model="tituloBoasVindasEspanhol" outlined color></v-text-field>
                          <v-text-field v-if="idiomaselecionado.id == 2" placeholder="@nomecliente, Seja Bem Vindo ao Hotel Exemplo!" v-model="tituloBoasVindasIngles" outlined color></v-text-field>
                          <v-text-field v-if="idiomaselecionado.id == 3" placeholder="@nomecliente, Seja Bem Vindo ao Hotel Exemplo!" v-model="tituloBoasVindasPortugues" outlined color></v-text-field>
                          
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;margin-bottom:10px;" >
                        <H2>Texto Para ser Enviado em {{idiomaselecionado.valor}}</H2>
                    </div>
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        Tags de Substituição Automáticas: @nomecliente, @numeroreserva, @datacheckin e @datacheckout
                    </div>
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <vue-editor v-if="idiomaselecionado.id == 1" v-model="textoBoasVindasEspanhol"></vue-editor>
                        <vue-editor v-if="idiomaselecionado.id == 2" v-model="textoBoasVindasIngles"></vue-editor>
                        <vue-editor v-if="idiomaselecionado.id == 3" v-model="textoBoasVindasPortugues"></vue-editor>
                          <!-- <v-textarea outlined color="" v-if="idiomaselecionado.id == 1"
                                    placeholder="Exemplo: Olá @nomecliente, seja bem vindo ao Hotel Exemplo, sua reserva de numero @numeroreserva está confirmada para o dia @datacheckin com saida no dia @datacheckout" append-icon=""
                                  name="" label="" v-model="textoBoasVindasEspanhol" ></v-textarea> 
                          <v-textarea outlined color="" v-if="idiomaselecionado.id == 2"
                                    placeholder="Exemplo: Olá @nomecliente, seja bem vindo ao Hotel Exemplo, sua reserva de numero @numeroreserva está confirmada para o dia @datacheckin com saida no dia @datacheckout" append-icon=""
                                  name="" label="" v-model="textoBoasVindasIngles" ></v-textarea> 
                          <v-textarea outlined color="" v-if="idiomaselecionado.id == 3"
                                    placeholder="Exemplo: Olá @nomecliente, seja bem vindo ao Hotel Exemplo, sua reserva de numero @numeroreserva está confirmada para o dia @datacheckin com saida no dia @datacheckout" append-icon=""
                                  name="" label="" v-model="textoBoasVindasPortugues" ></v-textarea>  -->
                    </div>

                    
                    
                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" data-cy="salvar" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
import { VueEditor } from "vue2-editor";


export default {
    data: () => ({
        enviarwebcheckin:false,
        emailwebcheckin:'',
        nodia:false,
        umdia:false,
        doisdias:false,
        cincodias:false,
        dezdias:false,
        trintadias:false,
        textobotaosalvar:"SALVAR",
        textoBoasVindas:null,
        tituloBoasVindas:null,
        idiomaselecionado:{id:3,valor:'Português'},
        tituloBoasVindasPortugues:null,
        tituloBoasVindasIngles:null,
        tituloBoasVindasEspanhol:null,
        textoBoasVindasEspanhol:null,
        textoBoasVindasIngles:null,
        textoBoasVindasPortugues:null,
        listaidiomas:[
            {id:1,valor:'Espanhol'},
            {id:2,valor:'Inglês'},
            {id:3,valor:'Português'},
        ]
    }),
    props:["exibirModalBemVindos"],
    components:{
        BotaoTutorial,
        VueEditor
    },
    methods: {
        setBotoes(botao){

            if(botao == 'nodia' && this.nodia){
                this.umdia=false
                this.doisdias=false
                this.cincodias=false
                this.dezdias=false
                this.trintadias=false
            }

            if(botao == 'umdia' && this.umdia){
                this.nodia=false
                this.doisdias=false
                this.cincodias=false
                this.dezdias=false
                this.trintadias=false
            }

            if(botao == 'doisdias' && this.doisdias){
                this.nodia=false
                this.umdia=false
                this.cincodias=false
                this.dezdias=false
                this.trintadias=false
            }

            if(botao == 'cincodias' && this.cincodias){
                this.nodia=false
                this.umdia=false
                this.doisdias=false
                this.dezdias=false
                this.trintadias=false
            }

            if(botao == 'dezdias' && this.dezdias){
                this.nodia=false
                this.umdia=false
                this.doisdias=false
                this.cincodias=false
                this.trintadias=false
            }

            if(botao == 'trintadias' && this.trintadias){
                this.nodia=false
                this.umdia=false
                this.doisdias=false
                this.cincodias=false
                this.dezdias=false
            }

        },
        mudarCorBotao(cor){

            if(cor == 'AZUL'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERDE'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #15e57d;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

            if(cor == 'VERMELHO'){
                this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #e51515;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`
            }

        },
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarENVIOAUTOBOASVINDAS().then(()=>{
                this.salvarENVIOAUTOBOASVINDAS1().then(()=>{
                    this.salvarENVIOAUTOBOASVINDAS2().then(()=>{
                        this.setModalBemVindos()
                    })
                })
            })
            
        },
      
        salvarENVIOAUTOBOASVINDAS(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOAUTOBOASVINDAS',
                    "valor" : this.nodia ? 'true' : 'false',
                    "valor2" : this.umdia ? 'true' : 'false',
                    "valor3" : this.doisdias ? 'true' : 'false',
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarENVIOAUTOBOASVINDAS1(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOAUTOBOASVINDASLIBERADO',
                    "valor" : this.enviarwebcheckin ? 'true' : 'false',
                    "valor2" : this.enviarwebcheckin ? this.emailwebcheckin : '',
                    "valor3" : this.tituloBoasVindasPortugues,
                    "valor4" : this.textoBoasVindasEspanhol,
                    "valor5" : this.textoBoasVindasIngles,
                    "valor6" : this.textoBoasVindasPortugues
                    // "valor4" : JSON.stringify({titulo:this.tituloBoasVindasEspanhol,texto:this.textoBoasVindasEspanhol}),
                    // "valor5" : JSON.stringify({titulo:this.tituloBoasVindasIngles,texto:this.textoBoasVindasIngles}),
                    // "valor6" : JSON.stringify({titulo:this.tituloBoasVindasPortugues,texto:this.textoBoasVindasPortugues}),
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        salvarENVIOAUTOBOASVINDAS2(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'ENVIOAUTOBOASVINDAS2',
                    "valor" : this.cincodias ? 'true' : 'false',
                    "valor2" : this.dezdias ? 'true' : 'false',
                    "valor3" : this.trintadias ? 'true' : 'false',
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalBemVindos(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalBemVindos')
        },
    },
    mounted() {

        this.layoutbotaoselecionado = `<button onclick="alert('teste')" title="Faça agora seu Checkin Digital"
                                        style="position: fixed;bottom: 20px;right: 30px;
                                        z-index: 9999;border: none;outline: none;background-color: #1560e5;color: white;
                                        cursor: pointer;padding: 15px;border-radius: 10px;height:4em;width:10em;
                                        display:flex;justify-content: center;align-items: center;">Fazer Check-In</button>`

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOAUTOBOASVINDAS'
            }).then(resp =>{
                if(resp.status == 200){
                    this.nodia      = resp.data[0].valor === 'true' ? true : false
                    this.umdia   = resp.data[0].valor2   === 'true' ? true : false
                    this.doisdias  = resp.data[0].valor3 === 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOAUTOBOASVINDAS2'
            }).then(resp =>{
                if(resp.status == 200){
                    this.cincodias      = resp.data[0].valor  === 'true' ? true : false
                    this.dezdias        = resp.data[0].valor2 === 'true' ? true : false
                    this.trintadias     = resp.data[0].valor3 === 'true' ? true : false
                    // alert(this.cincodias)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'ENVIOAUTOBOASVINDASLIBERADO'
            }).then(resp =>{
                if(resp.status == 200){
                    if (resp.data.length > 0) {
                        this.enviarwebcheckin = resp.data[0].valor === 'true' ? true : false
                        this.emailwebcheckin  = resp.data[0].valor2
                        this.textoBoasVindas  = resp.data[0].valor3

                        if(resp.data[0].valor4){
                            let textoEspanhol = resp.data[0].valor4
                            this.textoBoasVindasEspanhol = textoEspanhol
                        }
                        if(resp.data[0].valor5){
                            let textoIngles = resp.data[0].valor5
                            this.textoBoasVindasIngles = textoIngles
                        }
                        if(resp.data[0].valor6){

                            let textoPortugues = resp.data[0].valor6
                            this.textoBoasVindasPortugues = textoPortugues
                        }
                    }
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>
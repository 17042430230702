<template>
  <div>
    <v-card>
      <v-toolbar color="blue">
        <span style="width:95%">Observações</span>
        <span style="width:5%;padding:10px;"> 
            <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Observação"/>
        </span>
        <span style="width:5%"> 
            <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
        </span>        
      </v-toolbar>
      <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
      <v-card-text style="background-color:#ececec">
        <span><b>Observação Registradas PMS</b></span>
        <div>{{observacaopms}}</div>
      </v-card-text>

      <v-card-text>
        <span>Observações a partir do Ficha Digital</span>

        <v-textarea
          style="width: 400px"
          label="Observações"
          no-resize
          v-model="observacao"
          data-cy="observacao-textarea"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn style="color:white;"
          color="light-green"
          small
          @click="salvarPasso()"
          :disabled="!valid"
          >Salvar</v-btn
        > -->
        <v-btn style="color:white;" v-if="btnSalvarHabilidado&&possuiEmpresaReservante"
              color="light-green"
               class="mb-4 mt-4"
              medium
              @click="salvarPasso()"
              data-cy="observacao-btnsalvar"
              :disabled="!valid"
              >Salvar</v-btn>
        <v-btn style="color:white;font-weight:bold" v-else
          color="light-green"
          class="mb-4 mt-4"
          medium
          :disabled="true"
          >{{possuiEmpresaReservante?'FICHA ESTÁ FINALIZADA':'FICHA BLOQUEADA'}}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
            <span>{{ snackText }}</span>
            <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
          </div>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
  import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  props: ["idreserva", "idhospede", "possuiEmpresaReservante"],
  name: "ObservacaoFd",
  components: {
    BotaoTutorial
  },
  data: () => ({
    idreservapms:null,
    idhospedepms:null,
    principal:null,
    observacao: "",
    observacaopms: "",
    snack: false,
    snackColor: "",
    snackText: "",
    valid: false,
    btnSalvarHabilidado:true
  }),
  methods: {
    listarDados() {
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: this.idhospede,
        })
        .then((response) => {
          this.btnSalvarHabilidado = !response.data[0].enviadopms
          this.principal = response.data[0].principal
          console.log(response.data[0])
          this.observacao = response.data[0].obs;
          this.observacaopms = response.data[0].obspms ? response.data[0].obspms.replace("null-",'').replace("null",""):''
          this.idreservapms = response.data[0].idreservapms;
          this.idhospedepms = response.data[0].idhospedepms;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salvarPasso() {
      let body = {
        reserva: {
          id: this.idreserva,
          hotel: localStorage.getItem("hotel"),
          idreservapms:this.idreservapms
        },
        hospede: {
          id: this.idhospede,
          obs: this.observacao.trim(),
          principal:this.principal,
          idhospedepms:this.idhospedepms
        }
      };
/* 
      if (this.observacao.length <= 0) {
        setTimeout(() => {
          this.$emit("avancar");
        }, 1000);
        return;
      } */
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Salvo com sucesso!";
              //setTimeout(() => {
                this.$emit("avancar");
              //}, 1500);
            this.$emit("update")
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Não foi possível salvar os dados. Verifique.";
        });
    },
    
  },
  watch: {
      observacao: function () {
        if (this.observacao.length > 0) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      },
    },
  mounted(){
    this.listarDados()
  }
};
</script>

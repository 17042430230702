<template>
    <v-dialog v-model="exibirModalFaqConfigListagem" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:70%;color:white;font-weight:bold;" >
                        Lista de FAQs Cadastradas para {{dados.nome}}
                    </span>
                    <span style="width:30%;"> 
                        <v-btn x-medium style="margin-right:20px;" color="green" @click="novaPergunta()" title="Fechar">
                            <span x-small style="color:white;">NOVA PERGUNTA</span>
                        </v-btn>
                        <v-btn x-medium color="red" @click="setModalFaqConfigListagem()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card v-for="faq in listaDeFaqs" :key="faq.id" style="padding:10px;margin-bottom:10px;display:flex;">
                    <div style="width:70%">
                        <div style="display:flex;justify-content:flex-start;padding-bottom:10px;">
                            <h3>{{faq.pergunta}}</h3>
                        </div>
                        <div style="display:flex;justify-content:flex-start;padding-left:30px;">
                            <p style="font-size:18px;">{{faq.resposta}}</p>
                        </div>
                    </div>
                    <div style="width:30%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                        <v-btn style="background-color:#2196F3!important;color:white;font-weight:bold;margin-right:10px;" @click="editarFaq(faq)">
                            Editar
                        </v-btn>
                        <v-btn :style="'background-color:'+(faq.ativo?'red':'green')+'!important;color:white;font-weight:bold;'" @click="suspenderAtivarFaq(faq)">
                            {{faq.ativo? 'Suspender':'Ativar' }}
                        </v-btn>
                    </div>
                </v-card>

                

            </v-card-text>
            
            
        </v-card>

        <ModalFaqPergunta 
            @setModalFaqPergunta="() => setModalFaqPergunta()"
            :exibirModalFaqPergunta="exibirModalFaqPergunta"
            :faq="faq"
            @listarFaqs="()=> listarFaqs()"
            />

    </v-dialog>
</template>          

<script>


import ModalFaqPergunta from '@/components/DashViews/Modals/ModalFaqPergunta'
export default {
    data: () => ({
        textoEmail:"Olá @nome, te desejamos um feliz aniversário repleto de felicidades."    ,
        exibirModalFaqPergunta:false,
        listaDeFaqs:[],
        faq:{}
    }),
    props:["exibirModalFaqConfigListagem","dados"],
    components:{
        ModalFaqPergunta
    },
    watch:{
        dados: function (){
            this.listarFaqs()
            
        }
    },
    methods: {
        novaPergunta(){
            this.faq = {
                menu:this.dados.menu,
                pergunta:'',
                resposta:'',
                ativo:true,
                idioma:"pt-BR"
            }
             this.setModalFaqPergunta()
        },
        suspenderAtivarFaq(faq){
             let faqLocal = faq
             faqLocal.ativo = !faq.ativo
             this.$http.post("faq/inserirAtualizarFaq", faqLocal).then(resp =>{
                 if(resp.status == 200){
                    //  this.listarFaqs()
                 }else{
                     alert("Não foi possivel alterar o FAQ")
                 }
             })
             .catch(error =>{
                 console.log(error)
             })
        },
        editarFaq(faq){
            this.faq = faq
            this.setModalFaqPergunta()
        },
        setModalFaqPergunta(){
           this.exibirModalFaqPergunta = !this.exibirModalFaqPergunta
        },
        setModalFaqConfigListagem(){
            this.$emit('setModalFaqConfigListagem')
        },
        listarFaqs(){
            let dados = this.dados
            this.$http.post("/faq/listarFaqs", { menu: dados.menu })
            .then((response) => {
                this.listaDeFaqs = response.data;
            })
            .catch((error) => {
            console.log(error);
            });
        }
    },
    mounted() {
        
    },
};
</script>
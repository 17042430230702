<template>
 <div class="wrapper">
      <div class="page">
            <div class="cc-form">
            
            <div style="width:15%;margin-top:20px;">
                <button class="menu-voltar-btn" style="display:flex;" @click="voltar()">
                    <v-icon color="blue" style="margin-right:10px;">mdi-arrow-left</v-icon> Voltar
                </button>
            </div>
    <div class="wout-login" style="padding-top:20%;">
        <div class="center">
           
            <div class="contentarea">
                <div class="confirmar" style="color:#8C8B99!important;width:80%;max-width:500px;">
                    <span >Tire uma Foto do Documento Com Foto</span>
                </div>
                <div class="camera">
                    <input id="file-upload" type="file" data-cy="btn-uploadfrente" style="display: none" accept="image/*" @change="onFileChange"/>
                    <div style="display:flex;justify-content:center;">
                        <label for="file-upload" style="background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                            <span data-cy="span-cliqueaqui" style="margin-right:10px;font-size:18px;font-weight:bold;color:white;">{{textobotao}}</span>
                            <i style="color:white!important;" class="fa fa-search"></i>
                        </label> 
                    </div>
                </div>
                <canvas id="canvas">
                </canvas>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
</template>
<script>
import {serializarImagem} from '../../utils/serializar'

export default {
    name:"DOC",
    props:["dados"],
    data: () => {
        return {
            textobotao:"Clique Aqui",
            streaming: false,
            width: 320,
            height: 240,
            imagePoolSize: 6,
            imagePool: [],
            previewPhoto: false,
            lastFace: {
                imagem: null
            }
        }
    },
    methods: {
        voltar() {
            this.$emit('direcionar', {from: 'self', to: 'confirmardadosvoucher'})
        },
        avancar() {
            this.compararFaces()
        },
        async onFileChange(e) {
            this.textobotao = "Salvando... Aguarde..."
            this.loading =  true
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaFotoFrente(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
        },
        async salvaFotoFrente(tipoImg) {

            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }

            let dados = {
                "tipo" : tipoImg,
                "nome" : "DocumentoFrente",
                "dados" : this.imgSrc,
                "tabela": "hospede",
                "fk": "docfrente",
                "chave": "id",
                "valor": this.dados.idhospede
            };
            await this.$http.post("/imagem/upinsert", dados, { timeout: 60000 })
                .then((response) => {
                    if (response.status == 200) {
                        console.log("Foto do Usuario Salvo com Sucesso!");
                        this.textobotao = "Clique Aqui"
                        this.$emit("direcionar", {from: 'doc', to: "avisoself"})
                    } else {
                        this.textobotao = "Clique Aqui"
                        console.log("Não foi possivel salvar a Foto do Usuario!");
                    }
                    this.loading = false
                })
                .catch((error) => {
                    this.textobotao = "Clique Aqui"
                    this.loading = false
                    console.log(error.response);
                });

        },
           
    },
    mounted() {
        window.scrollTo(0, 0);
        // this.startup()
    }
}
</script>

<style lang="scss" scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    font-weight: normal;
    color: white!important;
    margin-bottom: 1.5rem;
    width: 90vw;
    align-self: center;
}

.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

#video {
  /* border: 1px solid white;
  box-shadow: 0px 0px 30px 20px blue;
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: relative; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:250px;
  height:250px;
  border-radius: 50%;
  position: relative;
}

#photo {
  /* border: 1px solid black;
  box-shadow: 0px 0px 30px 20px blue;
  width: 90vw;
  height: auto;
  border-radius: 50%; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:300px;
  height:300px;
  border-radius: 50%;
  margin-top:-50px;

}

#canvas {
  display:none;
}

.camera {
    position: relative;
    width: 100%;
    height:50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.output {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 90vw;
    display:inline-block;
    vertical-align: top; */
    position: absolute;
    top:10px;
    width: 70vh;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#startbutton {
  display:block;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  bottom:32px;
}

.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 30vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 25px;
    padding: 0.5rem;
}

.contentarea {
    display: flex;
    flex-direction: column;
    align-content: center;
    /*width: 760px;*/
}










.wrapper{
    max-width: 30vw;
    margin: 0 auto;
    @media screen and (max-width: 768px){
        max-width: 100vw;
    }
}
.page{
  background: #EBEBF5;
  height: 100vh;
  overflow: hidden;
}

.card-wrapper{
  height: auto;
  background: #1760e5;
  border-radius: 0 0 2em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em 2em 1em;

  .card{
    width: auto;
    height: 130px;
    border-radius: .7125em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    img{
        width: auto;
        height: 130px;
        @media screen and (min-width: 768px) {
            width: auto;
            height: 130px;
        }
    }
    
  }
}

.form{
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    font-size: 18px !important;
    color: #8C8B99;
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-bottom: 1em;
  }
  padding: 1em;
}

  .field{
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: #F2F2F2;
    width: 100%;
    padding: .5em;
    margin-bottom: .5em;

    &.half{
      width: 48%;
    }

    &.submit-btn{
      margin: 0 auto;
      width: 90%;
      height: 3em;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p{
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-left: .5em;

        >span{
          color: #2DA63F;
        }
      }
    }

    label{
      color: #8C8B99;
    }
    input{
      outline: none;
    }
  }
//css-indesejados

  .money-icon{
    background: #2DA63F;
    border-radius: 50%;
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-icon{
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
.v-application p{
  margin-bottom: unset;
}

.mdi-menu{
  display: flex !important;
  align-items: flex-start !important;
}
.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: center;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}
</style>
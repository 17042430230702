<template>
        
        <div class="py-4" style="flex-wrap:wrap;width:100%;">

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.motivo.motivo') }}</label>
                <select v-model="motivoViagem" class="inputs" data-cy="select-motivo">
                            <option v-for="(motivo, index) in motivosViagem" :key="index" :value="motivo"  >
                                {{motivo.descricao}}
                            </option>
                </select>
            </div>
           
            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.motivo.transporte') }}</label>
                <select data-cy="select-transporte" v-model="meioTransporte" class="inputs" @change="isAuto()">
                            <option v-for="(meio, index) in meiosTransporte" :key="index" :value="meio"  >
                                {{meio.descricao}}
                            </option>
                </select>
            </div>
           
            <div class="cardInputs" v-if="transporteehAutomovel">
                <label class="labels">{{ $t('webcheckin.motivo.modelo') }}</label>
                <input type="text"  data-cy="input-modelo" v-model="modelo"  class="inputs" placeholder="Modelo do Automovel"  />
            </div>

            <div class="cardInputs" v-if="transporteehAutomovel">
                <label class="labels">{{ $t('webcheckin.motivo.placa') }}</label>
                <input type="text"  data-cy="input-placa" v-model="placa"  class="inputs" placeholder="Placa"  />
            </div>


            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-motivovoltar">
                      {{ $t('webcheckin.motivo.voltar') }}
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-motivoavancar">
                        {{ $t('webcheckin.motivo.avancar') }}
                </button>
            </div> 

         
        </div>

</template>

<script>

import orto from '../../utils/ortografia.js'

export default { 
    props:["dados"],
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            placa: '',
            modelo: '',
            meioTransporte: null,
            meiosTransporte: [],
            motivoViagem: null,
            motivosViagem: [],
            transporteehAutomovel:false,
            listaDeAutomoveis:["AUTOMOVEL","AUTOMOVEIS","CARRO","CARROS"]
        }
    },
    computed: {
        // nacionalidades: function() {
        //     return this.paises.filter(p => {
        //         return p.nome != undefined;
        //     }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);
        // }
    },
    methods:{
        isAuto(){
            let item = this.listaDeAutomoveis.find((x) => {
                
                if(typeof this.meioTransporte == 'object'){
                    return x == this.meioTransporte.nomesemacento.toUpperCase() ? true : false
                }else{
                    return x == this.meioTransporte.toUpperCase() ? true : false
                }
            })

            if(item){
                this.transporteehAutomovel = true
                return true
            }else{
                this.transporteehAutomovel = false
                return false
            }

        },
        listarMeiosTransporte() {
            this.placa  = this.dados.placa
            this.modelo = this.dados.modelo

            this.$http.post("/geo/transporte/select").then((response) => {
                this.meiosTransporte = orto.ordenarArray(response.data,"descricao")
                console.log("this.dados")
                console.log(this.dados)
                if(this.dados.transporte){
                    if(typeof this.dados.transporte == 'number'){
                        this.meioTransporte = this.meiosTransporte.find(tranporte => tranporte.id == this.dados.transporte)
                    }else{
                        this.meioTransporte = this.meiosTransporte.find(tranporte => {
                                return orto.removerAcentuacao(tranporte.descricao.toUpperCase()) == orto.removerAcentuacao(this.dados.transporte.toUpperCase())
                        })
                    }
                }else{
                    this.meioTransporte = this.meiosTransporte[0]
                }

                this.isAuto()
            });
        },
        listarMotivosViagem() {
            this.$http.post("/geo/motivo/select").then((response) => {
                this.motivosViagem = orto.ordenarArray(response.data,"descricao")
                if(this.dados.motivo){
                    if(typeof this.dados.transporte == 'number'){
                        this.motivoViagem = this.motivosViagem.find(motivo => motivo.id == this.dados.motivo)
                    }else{
                        this.motivoViagem = this.motivosViagem.find(motivo => {
                                return orto.removerAcentuacao(motivo.descricao.toUpperCase()) == orto.removerAcentuacao(this.dados.motivo.toUpperCase())
                        })
                    }
                }else{
                    this.motivoViagem = this.motivosViagem[0]
                }
            });
        },
        validarFormulario(){

            if(!this.motivoViagem){ 
                // this.$swal(this.$t('alert.notification_required_birth_date.title'),this.$t('alert.notification_required_birth_date.content'),"info"); 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Informe o motivo da viagem!'})
                return false
            }

            if(!this.meioTransporte){ 
                // this.$swal(this.$t('alert.notification_required_nationality.title'),this.$t('alert.notification_required_nationality.content'),"info"); 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Informe o transporte utilizado!'})
                return false
            }

            if(this.transporteehAutomovel && 
                (!this.modelo || !this.placa)){ 
                // this.$swal(this.$t('alert.notification_sex_gender_required.title'),this.$t('alert.notification_sex_gender_required.content'),"info"); 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Modelo e Placa são obrigatorios para o transporte Automóvel!'})
                return false
            }

            
                return true
            

            
        },
        salvarDados(){

            
            var dadossalvar         = this.dados
            dadossalvar.idmotivo      = this.motivoViagem.id
            dadossalvar.motivo      = this.motivoViagem.descricao
            dadossalvar.idtransporte  = this.meioTransporte.id
            dadossalvar.transporte  = this.meioTransporte.descricao
            dadossalvar.placa       = this.placa
            dadossalvar.modelo      = this.modelo

            this.$emit('salvarDados',dadossalvar,'endereco')

        },
        async avancar(){

            if( await this.validarFormulario() ){
                this.salvarDados()
            }  
            
        },
        voltar(){
           
            this.$emit('direcionar','nacionalidade')
            // this.$emit('direcionar','contato')
            
        },
    },
    mounted(){
        this.listarMeiosTransporte()
        this.listarMotivosViagem()
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
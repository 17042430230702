<template>
 <div class="wrapper">
      <div class="page">
            <div class="cc-form">
                <div class="center" style="padding-top:20%;">
                    <div>
                        <img src="@/assets/img/prelogin.svg" 
                            width="auto" height="130" alt="Pré Login" crossorigin />
                    </div>
                    <div class="confirmar" style="color:#8C8B99!important;width:80%;max-width:500px;">Para melhor garantir a segurança desta transação, vamos tirar uma self, OK?</div>
                    <button class="continuar" @click="avancar">
                        <span>Continuar</span>
                        <v-icon large class="icone-continuar">mdi-chevron-right</v-icon>
                    </button>
                </div>
            </div>

    </div>
</div>
</template>
<script>

export default {
    props:["dados"],
    data: () => {
        return {
            
        }
    },
    methods: {
        avancar() {
            this.$emit('direcionar', {from: 'avisoself', to: 'self'})
        }
    },
    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    width: 65vw;
    font-weight: normal;
    color: white!important;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 70vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 15px;
}
.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}





.wrapper{
    max-width: 30vw;
    margin: 0 auto;
    @media screen and (max-width: 768px){
        max-width: 100vw;
    }
}
.page{
  background: #EBEBF5;
  height: 100vh;
  overflow: hidden;
}

.card-wrapper{
  height: auto;
  background: #1760e5;
  border-radius: 0 0 2em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em 2em 1em;

  .card{
    width: auto;
    height: 130px;
    border-radius: .7125em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    img{
        width: auto;
        height: 130px;
        @media screen and (min-width: 768px) {
            width: auto;
            height: 130px;
        }
    }
    
  }
}

.form{
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    font-size: 18px !important;
    color: #8C8B99;
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-bottom: 1em;
  }
  padding: 1em;
}

  .field{
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: #F2F2F2;
    width: 100%;
    padding: .5em;
    margin-bottom: .5em;

    &.half{
      width: 48%;
    }

    &.submit-btn{
      margin: 0 auto;
      width: 90%;
      height: 3em;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p{
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-left: .5em;

        >span{
          color: #2DA63F;
        }
      }
    }

    label{
      color: #8C8B99;
    }
    input{
      outline: none;
    }
  }
//css-indesejados

  .money-icon{
    background: #2DA63F;
    border-radius: 50%;
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-icon{
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
.v-application p{
  margin-bottom: unset;
}

.mdi-menu{
  display: flex !important;
  align-items: flex-start !important;
}
.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: center;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}
</style>
<template>
        
        <div class="py-4" style="flex-wrap:wrap;width:100%;">
            <h1 style="text-align:center;padding-top:3vh" >Existem outros hóspedes dessa reserva com dados preenchidos tais como endereço, origem e destino. <br > Você pode copiar essas informações de um dos hóspedes da lista abaixo.</h1>

            <div class="cardInputs">
                <label class="labels">Copiar dados de:</label>
                <select v-model="hospedeSelecionado" class="inputs">
                    <option v-for="(hospede, index) in hospedesPreenchidos" :key="index" :value="index" >
                        {{hospede.nome}} {{hospede.sobrenome}}
                    </option>
                </select>
            </div>

            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar">
                        VOLTAR
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar">
                        Continuar
                </button>
            </div>
         
        </div>

</template>

<script>

export default { 
    props:["dados"],
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            hospedesPreenchidos:[],
            hospedeSelecionado: 0
        }
    },
    computed: {
        // nacionalidades: function() {
        //     return this.paises.filter(p => {
        //         return p.nome != undefined;
        //     }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);
        // }
    },
    methods:{
        voltar(){
           
            this.$emit('direcionar','nacionalidade')
            
        },
        validDate(data) {
            var re = /^([12][0-9])\/(0[1-9]|1[012])\/0[1-9]|[12][0-9]|3[01]{3}$/;
            return re.test(data);
        },
        validarFormulario(){

            return true
            
        },
        salvarDados(){
            //var dadossalvar = this.dados
            // Copiar dados do hóspede selecionado...
            var dadossalvar = this.dados
            var outroHospede = null
            if (this.hospedeSelecionado > 0) {
                
                outroHospede = this.hospedesPreenchidos[this.hospedeSelecionado]
                console.log("outroHospede")
                console.log(outroHospede)

                dadossalvar.cep = outroHospede.endereco.cep
                dadossalvar.endereco = outroHospede.endereco.endereco
                dadossalvar.cidade = outroHospede.endereco.cidade
                dadossalvar.cidadeorigem = outroHospede.endereco.cidade
                dadossalvar.cidadedestino = outroHospede.endereco.cidade
                dadossalvar.idcidade = outroHospede.endereco.cidade
                dadossalvar.bairro = outroHospede.endereco.bairro
                dadossalvar.pais = outroHospede.endereco.pais
                dadossalvar.paisorigem = outroHospede.endereco.pais
                dadossalvar.paisdestino = outroHospede.endereco.pais
                dadossalvar.idpais = outroHospede.endereco.pais
                dadossalvar.uf = outroHospede.endereco.estado?outroHospede.endereco.estado:outroHospede.iduf
                dadossalvar.iduf = outroHospede.endereco.estado?outroHospede.endereco.estado:outroHospede.iduf
                dadossalvar.complemento = outroHospede.endereco.complemento
                dadossalvar.numero = outroHospede.endereco.numero
                dadossalvar.motivo = outroHospede.motivo
                dadossalvar.idmotivo = outroHospede.motivo
                dadossalvar.transporte = outroHospede.transporte
                dadossalvar.idtransporte = outroHospede.transporte
                dadossalvar.placa = outroHospede.placa
                dadossalvar.modelo = outroHospede.modelo
                dadossalvar.horariochegada = outroHospede.horariochegada
                dadossalvar.horariopartida = outroHospede.horariopartida
                dadossalvar.atualizartela = true
                // dadossalvar.outrohospedeidreservahospede = outroHospede.id
            }
            this.$emit('salvarDados',dadossalvar,'motivo')
        },
        async carregarHospedesPreenchidos(){
            //await this.realizarLogin();
            this.copiarDados = false
            this.$http.post("/reserva/preenchida/select", {id:this.dados.id, numeroreserva: this.dados.numeroreserva})
            .then(resp =>{
                this.hospedesPreenchidos = resp.data
                this.hospedesPreenchidos.unshift({
                    nome: 'Não copiar, entrarei com meus dados...'
                })
                this.hospedeSelecionado = 0
            })
            .catch(error =>{ console.log(error) })
        },        
        async avancar(){

            if(this.validarFormulario() ){
                this.salvarDados()
            }  
            
        }
    },
    mounted(){
        this.carregarHospedesPreenchidos()
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
    <v-dialog v-model="exibirModalEditarAparencia" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Aparência
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalEditarAparencia()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <div class="cardInputs">
                    <label class="labels">Fontes Disponíveis</label>
                    <v-select  outlined style="border-radius:15px;"
                        :items="fontes" 
                        v-model="fonte" required
                        @change="mudarFontePrincipal()">
                    </v-select>
                </div>
                <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                    <v-btn @click="salvarAparencia()" color="primary">
                        Salvar
                    </v-btn>
                </div>
            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        textoEmail:"Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
        isImg:null,
        imgSrc:null,
        imgTipo:null, 
        exibirAparencia:null,
        fontes:['Balsamiq Sans','Varela Round','Noto Sans','Cairo','Roboto'],
        fonte: ''
    }),
    props:["exibirModalEditarAparencia"],
    components:{
        // ModalSolicitacoesResponder
  
    },
    methods: {
        mudarFontePrincipal(){
            // Mudar a fonte principal
            var rootEl = document.querySelector(':root');
            if (rootEl) {
                rootEl.style.setProperty('--font-principal', `'${this.fonte}'`);
            }
        },
        salvarAparencia(){
            this.$http.post("configuracao/atualizarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'APARENCIAFONTE',
                "valor" : this.fonte
             }).then(resp =>{
                 if(resp.status == 200){
                     this.setModalEditarAparencia()
                 }else{
                     alert("Não foi possível alterar os dados de exibição do termos")
                 }
             })
             .catch(error =>{
                //  alert(error)
                 console.log(error)
             })
        },
        setModalEditarAparencia(){
            this.$emit('setModalEditarAparencia')
        },
    },
    watch:{
        exibirAparencia: function (val){
            this.exibirAparencia = val
            
        }
    },
    async mounted() {

        var rootEl = document.querySelector(':root');
        if (rootEl) {
            this.fonte = rootEl.style.getProperty('--font-principal');
        }

        //busca o email de aniversariante configurado
        await this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'APARENCIAFONTE'
            }).then(resp =>{
                
                if(resp.status == 200){
                    this.fonte = resp.data[0].valor
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

</style>
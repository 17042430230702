<template>
  <div class="container">   
      <main>
          <div id="fim" class="row">
                <div class="col-lg-3"></div>

                <div class="col-lg-6 text-center" style="padding-top:50px;">
                    <img id="icone1" class="img-responsive" src="@/assets/img/mala_icon.png" alt="">
                    <h3 data-cy="h3-obrigado">{{ $t('webcheckin.fim.Obrigado') }}</h3>
                    <h3 data-cy="h3-boaviagem">{{ $t('webcheckin.fim.otimaviagem') }}</h3>
                </div>

                <div class="col-lg-3"></div>
          </div>
      </main>
  </div> 
</template>

<script>

export default {
    data: () =>{
        return{
            campo:null,
        }
    },
    mounted(){
        //Algo a Fazer
    },
    methods: {
        metodo() {
            alert('teste')
        },
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
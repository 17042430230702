<template>
    <div style="margin-top:20px">
    <div v-for="(pergunta, index) in dataPerguntas" :key="index" class="" style="flex-wrap:wrap;width:100%; overflow:auto;">
       
        <h2 style="text-align:center;margin-bottom:30px;">{{pergunta.pergunta}}</h2>
        <div style="display:flex;flex-wrap:wrap;justify-content:center;" >

            <!-- <div 
              :style="(solicitacao.selecionado ?'background-color:#31B44A;color:white;':'background-color:#c5c5c5;color:black;')+'cursor:pointer;min-height:50px;max-width:40vw;min-width:200px;margin-right:10px;margin-top:10px;border-radius:5px;font-size:16px;font-family:Arial, Helvetica, sans-serif;display:flex;flex-direction:column;justify-content:center;align-items:center;'"
              v-for="solicitacao in solicitacoesTipo" :key="solicitacao.id"
              @click="marcarDesmarcarSolicitacao(solicitacao.id)"
              
              >
              {{solicitacao.descricao}}

            </div> -->

             <div 
              :style="(resposta.selecionado ?'background-color:#31B44A;color:white;':'background-color:#c5c5c5;color:black;')+'cursor:pointer;min-height:50px;max-width:40vw;min-width:200px;margin-right:10px;margin-top:10px;border-radius:5px;font-size:16px;font-family:Arial, Helvetica, sans-serif;display:flex;flex-direction:column;justify-content:center;align-items:center;'"
              v-for="(resposta,index2) in pergunta.respostas" :key="index2"
              @click="marcarDesmarcarSolicitacao(resposta.texto, index)"
              :data-cy="`resposta_${resposta.texto}`"
              
              >
              {{resposta.texto}}

            </div>

            
        </div>

        <div class="cardInputs" style="margin-top:40px;">
            <textarea id="texto-obs" name=""  data-cy="textarea-resposta" v-model="pergunta.observacao" style="background-color:white;border-radius:10px;padding:10px;"  cols="30" rows="5">
            </textarea>
        </div>


        
    </div> 
    <div class="cardInputsLinhaBetween" style="">
        <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-perguntavoltar">
                {{ $t('webcheckin.perguntaextra.voltar') }}
        </button>

        <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-perguntaavancar">
                {{ $t('webcheckin.perguntaextra.avancar') }}
        </button>
    </div> 

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
</div>
</template>

<script>

export default {
    name: 'PerguntaExtra',
    props:["dados","dadosAcompanhantes","hospedePrincipal","hospedePrincipalInicial"],
    data: () =>{
        return{
            dataPerguntas: [],
            solicitacoesTipo:[],
            solicitacoesUsuario:[],
            obs:null,
            snack: false,
            snackColor: '',
            snackText: '',

            pergunta:null,
            objetoPerguntaRetornado:null,
            objetoRespostaRetornado:null,
            respostas:[],
            resposta:'',
        }
    },
    watch:{
        respostas(valor){
            this.respostas = valor
        }
    },
    mounted(){
        this.buscarPerguntas();
    },
    methods: {
        buscarPerguntas(){
            this.$http.post("formulario/select",{chave:'PERGUNTAEXTRA'}).then(async resp =>{
                resp.data.forEach((e) => {
                    const obj = {
                        id: e.id,
                        idformulario: e.idformulario,
                        pergunta: e.texto,
                        respostas: e.respostasaceitas.tipo.split(';')
                    }
                    this.dataPerguntas = [...this.dataPerguntas, obj]
                })

                if(resp.data.length === 0 || !resp.data) {
                    this.dataPerguntas = [{
                        id: null,
                        idformulario: null,
                        pergunt: null,
                        resposta: null,
                    }]
                }
           
                    this.objetoPerguntaRetornado = resp.data[0]
                    this.pergunta = resp.data[0].texto
                    this.respostas = resp.data[0].respostasaceitas.tipo.split(';')
       
                    this.dataPerguntas.forEach( async (e, i) => {
                        e.respostas = await e.respostas.map(p => {
                            p = {texto:p.toUpperCase()}
                            return p;
                        })
                        this.buscarResposta(e, i)
                    })
            })
        },
        buscarResposta(e,index){
            let objeto = {
                id: e.idformulario,
                resposta:{
                    pergunta:{id: e.id},
                    idrespondente:this.dados.idpessoa
                }
            }
            this.$http.post("formulario/resposta/select",objeto).then(async resp =>{
                this.objetoRespostaRetornado = resp.data[0]
                let novoobjetoresposta = []
                for(var i =0; i < e.respostas.length; i++){

                    if(e.respostas[i].texto.toUpperCase() == resp.data[0].respostas[0].texto.toUpperCase()){
                        e.respostas[i].selecionado = true
                        novoobjetoresposta[i] = e.respostas[i]
                    }else{
                        novoobjetoresposta[i] = e.respostas[i]
                    }
                }
                this.dataPerguntas[index].objRespostaRetornado = resp.data[0]
                this.dataPerguntas[index].observacao = resp.data[0].respostas[0].observacao
                this.dataPerguntas[index].respostas = novoobjetoresposta
            }).catch(() =>{
                this.objetoRespostaRetornado = null
            })
        },
        async marcarDesmarcarSolicitacao(texto, i) {
          
            this.dataPerguntas[i].respostas = await this.dataPerguntas[i].respostas.map(p => {
                        p.selecionado = false
                        // this.resposta += p+' ; '
                        return p;
                    })

            this.dataPerguntas[i].respostas.find(x => x.texto === texto).selecionado = !this.dataPerguntas[i].respostas.find(x => x.texto === texto).selecionado
            let objetonovo = this.dataPerguntas[i].respostas.find(x => x.texto === texto)
            let index = this.dataPerguntas[i].respostas.findIndex(x => x.texto === texto)
            this.dataPerguntas[i].respostas.splice(index, 0)
            this.dataPerguntas[i].respostas[index] = objetonovo 
        },

        async salvarRespostas(){
        this.dataPerguntas.forEach(async (e) => {
            await this.salvarSolicitacoes(e);
        })
        },

        async salvarSolicitacoes(perguntaReposta) {
            // var concluidoComSucesso = false
            // console.log(perguntaReposta);
            // var descricaoErro = ""
            // var contadorAuxiliar = 0;
            // alert(perguntaReposta.respostas)
            for(var i =0; i < perguntaReposta.respostas.length; i++){
               
                if(perguntaReposta.respostas[i].selecionado){
                    //  alert('selecionado')
                    var bodyresposta = null;
                    if(perguntaReposta.objRespostaRetornado){
                        //atualizar

                        bodyresposta = {
                            id: perguntaReposta.objRespostaRetornado.respostas[0].id,
                            pergunta : perguntaReposta.id,
                            resposta : { "resposta": perguntaReposta.respostas[i].texto},
                            respondente: this.dados.idpessoa,
                            observacao: perguntaReposta.observacao,
                            ativo: true,
                            idioma: 'pt-BR'
                        }

                    }else{
                        //inseri
                        bodyresposta = {
                            pergunta : perguntaReposta.id,
                            resposta : { "resposta": perguntaReposta.respostas[i].texto},
                            respondente: this.dados.idpessoa,
                            observacao: perguntaReposta.observacao,
                            ativo: true,
                            idioma: 'pt-BR'
                        }
                        
                            // .catch(() =>{
                            //     this.objetoRespostaRetornado = null
                            // })

                    }
                    // console.error(`bodyresposta: ${JSON.stringify(bodyresposta)}`)
                    this.$http.post("formulario/resposta/upinsert",bodyresposta).then(() =>{
                            console.log("ok")
                    }).catch(error =>{
                        console.log(error)
                    })

                }

                
            }


            // alert("concluidoComSucesso "+concluidoComSucesso)
            // console.log("descricaoErro "+descricaoErro)
        },
        async avancar(){
            console.log('avançar')
                await this.salvarRespostas()
                var dadossalvar = this.dados
                dadossalvar.obs = this.dados.obs +" // "+this.obs


                // if(this.hospedePrincipalInicial && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                if(this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                    this.$emit('salvarDados',dadossalvar,'hospedes')   
                }else{
                    this.$emit('salvarDados',dadossalvar,'fim')   
                }
                


        },
        voltar(){

            if(this.dados.exibirPassosHorario){
                this.$emit('direcionar','horario')
            }else{
                if(this.dados.exibirPassosObservacao){
                    this.$emit('direcionar','observacoes')
                }else{
                    if(this.dados.exibirPassosCovid){
                        this.$emit('direcionar','formulariocovid')
                    }else{
                        this.$emit('direcionar','assinatura')
                    }
                }
            }
            
        }


    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
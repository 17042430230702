<template>
    <v-dialog v-model="exibirModalTutorialResposta" width="90%" height="100vh" max-width="40vw" style="">
        <v-card style="max-width: 100%;height: 90vh;">
            <v-toolbar color="#1D4596" class="mb-8">
                <v-flex style="display: flex" class="pa-2">            
                    <span style="width:95%;color:white;font-weight:bold;" >
                        FAQ
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalTutorialResposta',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>

                </v-flex>
            </v-toolbar>


            <v-text style="max-height: 50px;">
                <div style="width:100%;max-height:80%;overflow:auto;">
                    
                        
                        <p style="padding:30px;color:#494949;font-weight: 300; text-align: justify;">
                            {{ resposta }}
                        </p>
                        <div style="width:100%;display:flex;justify-content: flex-end;align-items:flex-end;padding-right: 20px;">
                            
                                <p style="color:#494949;font-weight: 100; font-size: medium; margin-bottom: 4px;">
                                    Essa resposta te ajudou?
                                </p>
                                <v-btn icon style="margin-right: 0" :color="curtidasfaq?'green':'gray'" @click="setCurtirDescurtir(true)">
                                    <v-icon color="gray">
                                        mdi-thumb-up
                                    </v-icon>
                                </v-btn>
                                <v-btn icon style="margin-right: 0" :color="curtidasfaq===false?'red':'gray'" @click="setCurtirDescurtir(false)">
                                    <v-icon color="gray">
                                        mdi-thumb-down
                                    </v-icon>
                                </v-btn>
                            
                        </div>
                </div>
            </v-text>   


        </v-card>

    </v-dialog>
</template>          

<script>
import { BUILD_APIES_URL, BUILD_ID_CLIENTE } from '../../../constantes';


export default {
    name: "ModalTutorialResposta",
    data: () => ({
        faq: []
    }),
    props:["exibirModalTutorialResposta", "resposta", "curtidasfaq", "idfaq"],
    components: {
    },
    methods: {
        setCurtirDescurtir(fl_curtirDescurtir) {
        
            this.curtidasfaq = fl_curtirDescurtir

            alert('Obrigado pela avaliação!')

            const token = localStorage.getItem('token')
            let baseURL = BUILD_APIES_URL

            this.$http.post(baseURL+"/faq/inserirAtualizarCurtida", 
            {id_faq: this.idfaq,idusuario: localStorage.getItem('userid'),fl_curtida: this.curtidasfaq,idcliente: BUILD_ID_CLIENTE},
            {headers:{'x-access-token':token}})
                .then((response) => {
                    this.faq = response.data
                })
                .catch((error) => {
                    alert(error)
                    console.log(error);
                });
        },
        
    },
    mounted() {
        
    },
};
</script>
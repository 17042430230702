<template>
  <div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <input :id="'image-upload-'+indice" type="file" style="display:none;" 
      accept="image/*" @change="onFileChange" />
    <v-text-field
      data-cy="texto"
      :label="imagem.id>0?'Texto':'Nova Imagem'"
      v-model="imagem.texto"
      @keyup="textChanged"
    >
    </v-text-field>
    <label :for="'image-upload-'+indice">
      <div style="text-align:center;cursor:pointer;">
        <v-icon v-if="!imagem.imgSrc" x-large style="align-self:center;color: rgb(33, 150, 243);">mdi-plus</v-icon>
      </div>
      <img style="text-align:center;cursor:pointer;"
        v-if="imagem.imgSrc"
        :src="'data:' + imagem.tipo + ';base64,' + imagem.imgSrc"
        class="imgUpload"
        height="65"
        width="150"
      />
    </label>
    <div style="text-align:center;" @click="aboutToDelete=true">
      <template v-if="imagem.id!=0">
        <v-icon small :class="showDelete?'es-delete-red':'es-delete-grey'" v-if="!aboutToDelete">mdi-delete</v-icon>
        <div @click="deleteImage" style="cursor:pointer;">
          <span  v-if="aboutToDelete" style="color:orange;cursor:pointer;font-size:0.7em;">
            Clique de novo para excluir</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showDelete: false,
    aboutToDelete: false
  }),
  props: ['imagem','indice'],
  components: {  },
  methods: {
    onFileChange(e) {
      this.$emit('change',e,this.indice);
    },
    textChanged() {
      this.$emit('text-changed',this.indice);
    },
    mouseEnter() {
      this.showDelete=true;
    },
    mouseLeave() {
      this.showDelete=false;
      this.aboutToDelete=false;
    },
    deleteImage() {
      this.$emit('delete',this.indice);
      this.aboutToDelete=false;
    }
  },
  mounted() {

  },
};
</script>

<style scoped>
.es-delete-red {
  align-self:center;
  color:rgb(241, 80, 80)!important;
}
.es-delete-grey {
  align-self:center;
  color:grey!important;
}
</style>
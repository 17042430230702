<template>
  <v-dialog
    v-model="exibirModalGerais"
    width="1000"
    max-width="80vw"
  >
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Configurações Gerais
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações Gerais"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalGerais()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <v-flex>
          <div
          >
          <v-row>
            <v-col cols="12">
              <!-- Configurações Gerais de Importações -->
              <div style="font-size: 16px;display:flex;flex-direction:row;justify-content:flex-start;align-items:center;" >
                <!-- Ativar Importações -->
                <div style="width:50%;display:flex;flex-direction:row;">
                  <v-switch v-model="ativarImportacoes" data-cy="ativarImportacoes" style="border-radius:5px;padding:5px;"></v-switch>
                  <span style="align-self:center;">{{ativarImportacoes?'Desativar':'Ativar'}} importações ?</span>
                </div>
                <!-- Tipo de Integração -->
                <div style="width:50%;">
                  <label>Tipo de Integração</label>
                  <v-text-field id="tipoIntegracao"
                    data-cy="input-tipoIntegracao" 
                    v-model="tipoIntegracao" label="Tipo de Integração" 
                  />
                </div>
              </div>              
            </v-col>
          </v-row>
          </div>
        </v-flex>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn
            @click="salvar()"
            data-cy="salvar"
            color="primary"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
export default {
  data: () => ({
    urlapi: '',
    ativarImportacoes: false,
    tipoIntegracao: 'TOTVS',
  }),
  props: ["exibirModalGerais"],
  components: {
    BotaoTutorial
},
  methods: {
    salvar() {
      this.tipoIntegracao = this.tipoIntegracao.toUpperCase().trim();
      console.error(this.ativarImportacoes);
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "TIPOINTEGRACAO",
          valor: this.tipoIntegracao,
        })
        .then((resp) => {
          if (resp.status == 200) {
            localStorage.setItem('TIPOINTEGRACAO',this.tipoIntegracao)
            this.$http
              .post("configuracao/atualizarParametro", {
                idhotel: localStorage.getItem("hotel"),
                chave: "ATIVARIMPORTACOES",
                valor: this.ativarImportacoes,
              })
              .then((resp) => {
                // alert(JSON.stringify(resp))
                if (resp.status == 200) {
                  localStorage.setItem('ativarimportacoes',this.ativarImportacoes)
                  this.setModalGerais();
                } else {
                  console.error("Erro ao atualizadar configuração.");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.error("Erro ao atualizadar configuração.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setModalGerais() {
      this.$emit("setModalGerais");
    },

  },
  mounted() {
    this.ativarImportacoes = localStorage.getItem('ativarimportacoes') == 'true';
    this.tipoIntegracao = localStorage.getItem('TIPOINTEGRACAO');
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>
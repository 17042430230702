<template>
  <v-container fill-height fluid grid-list-xl>
    <v-dialog v-model="showDialog" width="70%" style="min-height:90vh!important;" persistent>
      <v-card class="pa-2" height="100%">
        <!-- Título -->
        <v-toolbar color="light-blue" class="ma-2"
          style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
          <div style="min-width:100%!important;display:flex;justify-content:space-between;">
            <span class="toolbar-title"> 
              Histórico do Pagamento 
            </span> 
            </div>
        </v-toolbar>
        <br>
        <!-- Dados -->
        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="footerProps"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`footer.page-text`]="items"> 
            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
          </template>
          <template v-slot:[`item.datacriacao`]="{ item }">
            {{item.datacriacao | formatarDataYYHora}}
          </template>
          <template v-slot:[`item.valorpago`]="{ item }">
            <div class="item-value">{{item.valorpago | formatarMoeda}}</div>
          </template>
        </v-data-table>
        <v-card-actions>
            <v-spacer />
            <v-btn
              align-center
              justify-center
              color="primary"
              @click="$emit('closeitems')"
              >Fechar
            </v-btn>            
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  props: ['show','items'],
  data: () => ({
    footerProps: {
      'items-per-page-options': [6,12,24,{ text: "Todos", value: -1 }],
      'items-per-page-text': 'Linhas por página',
    },
    search: "",
    headers: [
      { text: "Nome", value: "nomehospede" },
      { text: "Sobrenome", value: "sobrenomehospede" },
      { text: "Reserva", value: "numeroreserva" },
      { text: "Data", value: "datacriacao" },
      { text: "Valor Pago", value: "valorpago", align: 'end' },
      { text: "Status", value: "nome" }
    ],
    showDialog: false
  }),
  computed: {
  },
  watch: {
    show(val) {
      this.showDialog = val
    }
  },
  created() {
    
  },
  methods: {
  },
};
</script>
<style scoped>
.item-value{
  font-size: 0.8rem!important;
}
</style>
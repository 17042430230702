<template>
    <div>
        <div class="captura_container" v-if="passo == 0">
            <div style="padding-top:10px;width:100%;padding-right:5%;padding-left:5%;display:flex;justify-content:space-between;position:fixed;top:0px;">
                  <div v-if="tagSincronizada" style="color:white;font-size:18px;background-color:#2c9700;padding:10px;">TAG SINC.: {{tagSincronizada}}</div>
                  <div v-else style="color:white;font-size:18px;background-color:#009797;padding:10px;">QR CODE: {{apelido}}</div>
                  <v-btn large color="error" @click="logout()">Sair do Sistema</v-btn>
            </div>
            <h1 v-if="imagens.length==0" style="text-align:center;color:white;">Aguardando solicitação de Captura {{textoCaptura}}</h1>
            <CapturaDocumentosGaleria v-if="imagens.length>0" :imagens="imagens"></CapturaDocumentosGaleria>
            <!-- <h2 style="color:red!important;text-align:center;">Conexão Válida  por 10 min</h2> -->
            <!-- <h4 style="text-align:center;">Mantenha esta tela aberta <strong style="font-size:20px;">e em outra sessão faça login com o mesmo email</strong>, acesse uma reserva e solicite a captura de documentos para que possa realizar a captura do documento e extrair os dados</h4> -->
            <!-- <button @click="testeSocket()" type="button" >teste</button> -->
        </div>
        <CapturaDocumentosTipo v-if="passo == 1"                @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()"  @setarTipoDocSelecionado="(valor) => setTipoDocSelecionado(valor)" />
        <CapturaDocumentosFotoFrente v-if="passo == 2"          @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :tipoDocSelecionado="tipoDocSelecionado" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" @jaexiste="(eventdata) => jaExiste(eventdata)" />
        <CapturaDocumentosFotoVerso v-if="passo == 3"           @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :tipoDocSelecionado="tipoDocSelecionado" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" @jaexiste="(eventdata) => jaExiste(eventdata)" />
        <CapturaDocumentosConfirmacaoDados v-if="passo == 4"    @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" :dados="dados" />
        <CapturaDocumentosFormCovid v-if="passo == 5"           @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" :dados="dados"/>
        <CapturaDocumentosAssinatura v-if="passo == 6"          @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" />
        <CapturaDocumentosFotoSelfie v-if="passo == 7"          @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :tipoDocSelecionado="tipoDocSelecionado" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" :nome="nome" />
        <CapturaDocumentosAdicional v-if="passo == 8"           @setarPasso="(valor) => setPasso(valor)"    @avancar="() => avancar()" :tipoDocSelecionado="tipoDocSelecionado" :idHospede="idHospede" :idReserva="idReserva" :idReservaHospede="idReservaHospede" :nome="nome" />

        <!-- <v-btn @click="avancar()">teste</v-btn>  -->



        <template>
        <v-row justify="center">
          <v-dialog
            v-model="confirmarSubstituicao"
            persistent
            max-width="600"
          >
            <v-card>
              <v-card-title class="text-h5">
                Já existe uma pessoa com o mesmo CPF:
              </v-card-title>
              <v-card-text>
                <strong>{{this.dadosOutraPessoa.nome+' '+this.dadosOutraPessoa.sobrenome}}</strong> <br>
                <strong>Deseja utilizar os seus dados?</strong>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="naoSubstituir()"
                  data-cy="btn-dadospnaosub"
                >
                  Não
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="substituirPessoa()" 
                  data-cy="btn-dadospsub"
                >
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
        
    </div>
    

    
</template>

<script>

import CapturaDocumentosTipo from './CapturaDocumentosTipo'
import CapturaDocumentosFotoFrente from './CapturaDocumentosFotoFrente'
import CapturaDocumentosFotoVerso from './CapturaDocumentosFotoVerso'
import CapturaDocumentosConfirmacaoDados from './CapturaDocumentosConfirmacaoDados'
import CapturaDocumentosFormCovid from './CapturaDocumentosFormCovid'
import CapturaDocumentosAssinatura from './CapturaDocumentosAssinatura'
import CapturaDocumentosFotoSelfie from './CapturaDocumentosFotoSelfie'
import CapturaDocumentosGaleria from './CapturaDocumentosGaleria'
import CapturaDocumentosAdicional from './CapturaDocumentosAdicional'
export default {
  name: 'CapturaDocumentos',
  data: () => ({
    textoCaptura:'',
    dados:null,
    image: '',
    passo:0,
    idHospede:0,
    idReserva:0,
    idReservaHospede:0,
    tipoDocSelecionado:'RG',
    nome:null,
    imagens: [],
    formSanitario: null,
    modo: "0",
    confirmarSubstituicao: false,
    substituir: false,
    dadosOutraPessoa: false,
    idhospedepms:null,
    tagSincronizada:null,
    apelido:null,
  }),
  components:{
       CapturaDocumentosTipo: CapturaDocumentosTipo,
       CapturaDocumentosFotoFrente: CapturaDocumentosFotoFrente,
       CapturaDocumentosFotoVerso: CapturaDocumentosFotoVerso,
       CapturaDocumentosConfirmacaoDados: CapturaDocumentosConfirmacaoDados,
       CapturaDocumentosFormCovid:CapturaDocumentosFormCovid,
       CapturaDocumentosAssinatura: CapturaDocumentosAssinatura,
       CapturaDocumentosFotoSelfie: CapturaDocumentosFotoSelfie,
       CapturaDocumentosGaleria: CapturaDocumentosGaleria,
       CapturaDocumentosAdicional: CapturaDocumentosAdicional
  },
  methods: {
    logout(){
         this.$store.dispatch("logout").then(() => {
              window.location.href = "/"
        });
    },
    naoSubstituir() {
      this.substituir = false
      this.confirmarSubstituicao = false

      let params = {
        reserva: {
          id: this.idReserva,
          hotel: localStorage.getItem("hotel")
        },
        hospede: {
          id: this.idHospede,
          idhospedepms: this.idhospedepms,
                nome: this.dadosOutraPessoa.dadosLeituraOCR.nome,
                sobrenome: this.dadosOutraPessoa.dadosLeituraOCR.sobrenome,
                nascimento: this.dadosOutraPessoa.dadosLeituraOCR.data_nascimento,
                nacionalidade : this.dadosOutraPessoa.dadosLeituraOCR.nacionalidade
        },
      };

      this.$http.post("/reserva/upinsert", params).then((response) => {          
          if (response.status == 200) {

            if(this.tipoDocSelecionado == "RG" || this.tipoDocSelecionado == "RNE" || this.tipoDocSelecionado == "OUTROS"){
                this.setPasso(3)
            }else{
                // se não for RG ou RNE não tem necessidade de capturar o verso do documento
                this.avancar()
                this.setPasso(0)
            }

          }
        }).catch((error) => {
          console.log(error);
        });

      
    },
    substituirPessoa() {
      this.substituir = true
      this.confirmarSubstituicao = false
      // Salvar os dados substituindo a pessoa
      let params = {
        reserva: {
          id: this.idReserva,
          hotel: localStorage.getItem("hotel")
        },
        hospede: {
          id: this.idHospede,
          idhospedepms: this.idhospedepms,
          substituir: this.substituir,
          documentos: [
            {
              tipo: this.dadosOutraPessoa.tipo,
              documento: this.dadosOutraPessoa.documento,
            }
          ],
        },
      };

      console.log("params >>>>>> ",params)

       

      this.$http.post("/reserva/upinsert", params).then((response) => {          
          if (response.status == 200) {
            // this.$emit('update')
            // this.$emit('avancar')

            if(this.tipoDocSelecionado == "RG" || this.tipoDocSelecionado == "RNE" || this.tipoDocSelecionado == "OUTROS"){
                this.setPasso(3)
            }else{
                // se não for RG ou RNE não tem necessidade de capturar o verso do documento
                this.avancar()
                this.setPasso(0)
            }

          }
        }).catch((error) => {
          console.log(error);
        });
    },

    jaExiste(data) {

      // Notificar o operador que o documento já existe
      console.error('Já existe, data:'+JSON.stringify(data))

      this.confirmarSubstituicao = true
      this.dadosOutraPessoa = data


      // data.tag = localStorage.getItem('tag')

      // this.$socket.emit('captura_to_dash_notificar_ja_existe', {
      //       usuario: localStorage.getItem('useremail'), 
      //       data
      // })

      // this.sockets.subscribe("confirmarsubstituir", (dados) => {

      //     this.confirmarSubstituicao = true
      //     this.dadosOutraPessoa = dados.data


          
      // });


    },
    carregarParametros() {
      this.$http.post("configuracao/buscarParametro", {
        "idhotel" : localStorage.getItem("hotel"), 
        "chave" : 'WCHECKINEXIBIRCOVID'
      }).then(async resp =>{
          this.formSanitario = resp.data[0].valor == 'true' ? true : false
      }).catch(error =>{ console.log(error) })
    },
    carregarImagens() {
      this.$http
        .post("perfil/hotel/imagem/select", {
          idhotel: localStorage.getItem("hotel")
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.length > 0) {
              this.imagens = resp.data;
              for (var i = 0; i < this.imagens.length; i++) {
                let imgObj = this.imagens[i]
                if(imgObj.dados.data) {
                  this.imagens[i].imgSrc = Buffer.from(imgObj.dados.data).toString();
                  delete imgObj.dados;
                }
              }
            }
          } else {
            console.log("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    testeSocket(){
        this.$socket.emit('enviar_notificacao', {msg: 'teste' })  
    },
    setPasso(valor){
      // Se for o passo do formulário sanitário depende da configuração...
      if (valor == 5) {
        this.passo = this.formSanitario ? valor : valor + 1;
      } else {
        this.passo = valor;
      }
    },
    setTipoDocSelecionado(valor){
        this.tipoDocSelecionado = valor
    },
    avancar(){

        this.$socket.emit('captura_to_dash_avancar', {
                          usuario: localStorage.getItem('useremail'), 
                          msg: ''
                    })  
    }
  },
  created() {
      this.carregarImagens();
      this.carregarParametros();
  },
  beforeDestroy(){
      this.logout()
  },
  mounted() {
    this.apelido = localStorage.getItem('apelido').toUpperCase()
    this.textoCaptura = localStorage.getItem('textoCaptura')
    this.tagSincronizada = localStorage.getItem('tag')
    this.modo = this.$route.params.modo
    let tipo = ''
    if (this.modo == "0") {
      tipo = 'CAPTURA_AMBOS'
    } else {
      if (this.modo == "1") {
        tipo = 'CAPTURA_DOCUMENTOS'
      } else {
        if (this.modo == "2") {
          tipo = 'CAPTURA_ASSINATURA'
        }
      }
    }
    //Conectar ao WebSocket
    this.$socket.disconnect();
    this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
    this.$socket.io.opts.query.tipo = tipo
    if(localStorage.getItem('tag') > 0){
      this.$socket.io.opts.query.tag = localStorage.getItem('tag')
    }
    //this.$socket.io.opts.query.tipo_acesso = 'CAPTURA_DOCUMENTOS'
    this.$socket.connect();
    this.$store.dispatch("setHiddenDrawer", true)

    //METODOS SOCKETS

    this.sockets.subscribe("capturar_doc", async (reserva) => {
    
        let username = "webcheckin";
          let password = "FSD6&*)))4%8gf54dfh8sdfsdfs342854%$&99##3";
        // this.idReservaHospede = reserva.idReservaHospede
       
        await this.$store.dispatch("login", { username, password })
        .then(async () => { 
            this.$store.dispatch("set_tipoacesso", 'capturadocumento')
            try {
               
              
              await this.$http.post("/reserva/select", {idreserva:reserva.idReserva,idhospede:reserva.idHospede})
              .then(async resp =>{

               
                      this.dados = null
                      this.dados = resp.data[0]  
                   



                      this.idHospede = reserva.idHospede
                      this.idReserva = reserva.idReserva
                      this.idReservaHospede =  this.dados.id
                      this.idhospedepms = this.dados.idhospedepms
                      this.passo = 1


              }).catch(error =>{
                  console.log(error)
              })
            } catch (error) {
                console.log('teste')
            }
        })
        .catch((err) => {
            alert(JSON.stringify(err))
        });

      
       
    });

    this.sockets.subscribe("capturar_assinatura", async (reserva) => {
        this.idHospede = reserva.idHospede
        this.idReserva = reserva.idReserva
        this.idReservaHospede = reserva.idReservaHospede

        let username = "webcheckin";
          let password = "FSD6&*)))4%8gf54dfh8sdfsdfs342854%$&99##3";
        await this.$store.dispatch("login", { username, password })
        .then(async () => { 
          this.$store.dispatch("set_tipoacesso", 'capturadocumento')
            await this.$http.post("/reserva/select", {id:this.idReservaHospede})
            .then(async resp =>{
                    this.dados = null
                    this.dados = resp.data[0]  
                    this.passo = 4
            }).catch(error =>{
                console.log(error)
            })
        })
        .catch((err) => {
            alert(JSON.stringify(err))
        });


        
        
    });

    this.sockets.subscribe("capturar_selfie", (reserva) => {
        this.idHospede = reserva.idHospede
        this.idReserva = reserva.idReserva
        this.idReservaHospede = reserva.idReservaHospede
        this.nome = reserva.nome
        this.passo = 7
    });

        
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        /* color:#808080; */
        margin-bottom:10px;
        font-size:30px!important;
        font-weight:bold;
        text-align: center;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        background-color:black;
        width:100vw;
        height:100vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        /* padding-left:10%;
        padding-right:10%; */
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        opacity: 0.8;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>
<template>
  <v-container
    fluid
    grid-list-xl
    fill-height>
    <v-layout
      justify-center>
      <v-flex xs12>
        <material-card
          color="general">
          <v-toolbar color="light-blue" class="ma-2" style="color: white;display:flex;flex-direction:column;">
            <div style="min-width:70%!important;">
              <span class="toolbar-title"> Notificações</span> 
            </div>
            <div style="min-width:30%!important;display:flex;">
                    <v-row>
                      <v-col cols="2" md="6"></v-col>
                      <v-col cols="2" md="6" style="width:5%;padding:10px;display:flex;justify-content: flex-end;">
                        <BotaoTutorial style="justify-content: center;" urlVideo=""/>
                      </v-col>
                    </v-row>     
                </div>
                <v-btn v-if="false" small elevation="" color="" @click="testenotificar()">teste</v-btn>
            
          </v-toolbar>

          <v-card>
            
            <v-data-table
            :headers="headers"
            :items="notificacoes"
            :search="search"
            class="elevation-1 ma-2"
            no-data-text="Nenhuma Notificação Encontrada."
          >

            <template v-slot:[`item.data_criacao`]="{ item }">
              {{item.data_criacao | formatarDataHora}}
            </template>

            <template v-slot:[`item.data_visualizado`]="{ item }">
              {{item.data_visualizado | formatarDataHora}}
            </template>


            <v-container grid-list-lg>
              <v-layout
                justify-center
                row
                wrap>
                <v-flex
                  xs12
                  sm4>
                  <v-btn
                    block
                    color="general"
                    @click="snack('top', 'left')"
                  >
                    Top Left
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-btn
                    block
                    color="general"
                    @click="snack('top')"
                  >
                    Top Center
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout
                justify-center
                row
                wrap>
                <v-flex
                  xs12
                  sm4>
                  <v-btn
                    block
                    color="general"
                    @click="snack('top', 'right')"
                  >
                    Top Right
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-btn
                    block
                    color="general"
                    @click="snack('bottom', 'left')"
                  >
                    Bottom Left
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout
                justify-center
                row
                wrap>
                <v-flex
                  xs12
                  sm4>
                  <v-btn
                    block
                    color="general"
                    @click="snack('bottom')"
                  >
                    Bottom Center
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  sm4>
                  <v-btn
                    block
                    color="general"
                    @click="snack('bottom', 'right')"
                  >
                    Bottom Right
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                  <span>{{ snackText }}</span>
                  <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
          </v-data-table>
          </v-card>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  components: {
    BotaoTutorial
  },
  data: () => ({
    notificacoes:[],
    headers: [
      { text: "ID", value: "id" },
      { text: "Msg", value: "msg" },
      { text: "Data Criacao", value: "data_criacao" },
      { text: "Data Visualização", value: "data_visualizado" },
    ],
    color: null,
    colors: [
      'purple',
      'info',
      'success',
      'warning',
      'error'
    ],
    top: true,
    bottom: false,
    left: false,
    right: false,
    snack: false,
    snackColor: 'success',
    snackText: ''
  }),
  mounted(){
    this.$http.post("/notificacao/select", {idusuario:localStorage.getItem('userid')})
      .then((response) => {
          this.notificacoes = response.data
          this.$http.post("/notificacao/marcarcomovisualizado", 
          { idusuario:localStorage.getItem('userid'),
            emailusuario:localStorage.getItem('useremail') })
          .then(() => {
              this.$qtdeNotificacoes = 0  
          })
          .catch((error) => {
              console.log(error)
          });
          })
      .catch((error) => console.log(error));
  },
  methods: {
    testenotificar(){
      let notificacao = {idusuario:localStorage.getItem('userid'),
                email:localStorage.getItem('useremail'),
                mensagem:'teste'}

      this.$http.post("/notificacao/inserirnotificacao", 
          notificacao)
          .then(() => {
            console.log("NOTIFICACAO INSERIDA COM SUCESSO")
              // this.$qtdeNotificacoes = 13  
          })
          .catch((error) => {
              console.log(error)
          });
    },
    snack (...args) {
      this.top = false
      this.bottom = false
      this.left = false
      this.right = false

      for (const loc of args) {
        this[loc] = true
      }

      this.color = this.colors[Math.floor(Math.random() * this.colors.length)]

      this.snack = true
    }
  }
}
</script>

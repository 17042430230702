<template>
        
        <div class="" style="flex-wrap:wrap;width:100%;">
            
            <div class="cardInputs">
                <label class="labels" style="font-size:20px!important;">Por qual canal você fez sua reseva?</label>
                <select v-model="canal" class="inputs" >
                            <option style="font-size: 1.5em;font-size-adjust: 0.3;" v-for="(canal, index) in canais" :key="index" :value="canal" class="text-center" >
                                {{canal.empresareservante}}
                            </option>
                </select>
            </div>


            <div class="cardInputs" style="display:flex;justify-content:center;align-items:center;">
                <button v-on:click="avancar()" class="botaoConfirmar">
                        Procurar
                </button>
            </div>

         
        </div>

</template>

<script>

// import moment from 'moment'
export default { 
    props:["dados"],
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            canais:[],
            canal:null

        }
    },
    methods:{
        listarCanais(){
        
            this.$http.post("reserva/empresareservante/select",{
                datacheckinprevisto:this.dados.datacheckinprevisto,
                datacheckoutprevisto:this.dados.datacheckoutprevisto,
                hotel:1,
                // hotel:localStorage.getItem('hotel'),
                })
            .then(resp =>{

                    this.canais = resp.data.filter(p => {
                        return p.empresareservante != undefined;
                    }).sort((a, b) => (a.empresareservante > b.empresareservante) ? 1 : -1);

                    this.canal = this.canais[0]
                    

            })
        },
        validDate(data) {
            var re = /^([12][0-9])\/(0[1-9]|1[012])\/0[1-9]|[12][0-9]|3[01]{3}$/;
            return re.test(data);
        },
        async avancar(){

            var dadossalvar     = this.dados
            dadossalvar.canal   = this.canal.idempresareservante
           
            this.$emit('atualizarDados',dadossalvar,'ReservasPrincipal')
            
        }
    },
    mounted(){
        this.listarCanais()
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
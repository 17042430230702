<template>
    <div class="wout-login">
        <div class="inicio">
            <!-- <button class="menu-voltar-btn" @click="voltar()">
                <v-icon color="white">mdi-arrow-left</v-icon>
            </button> -->
            <div class="contentarea">
                <div class="confirmar">
                    <span>Por gentileza, retire óculos ou máscara e centralize seu rosto para a foto!</span>
                </div>
                <div class="camera" >
                    <button v-show="!previewPhoto && isDev" class="continuar" id="startbutton" style="width:150px;" @click="pularreconhecimento">
                        Pular
                    </button>
                    <video id="video" playsinline>Video stream not available.</video>
                    <button v-show="!previewPhoto" class="continuar" id="startbutton" style="width:150px;" @click="takepicture">
                        Tirar Self
                    </button>
                    <div v-show="previewPhoto" class="output"  style="">
                        <img id="photo" alt="The screen capture will appear in this box."> 
                       <div v-if="!realizandoUpload" style="display:flex;flex-direction:row;align-items:space-between;padding-left:10%;padding-right:10%;padding-top:40px;margin-top:20px;width:100%;">
                            <button class="continuar" id="startbutton" style="width:150px;background-color:red!important;" @click="tirarOutra">
                                Tirar Outra
                            </button>
                            <button class="continuar" id="startbutton" style="width:150px;" @click="avancar">
                                Continuar
                            </button>
                        </div>
                       <div v-else style="display:flex;flex-direction:row;justify-content:center;margin-top:20px;color:white;width:100%;text-align:center;font-weight:300;">
                            Aguarde...<br>Realizando Reconhecimento Facial...
                        </div>
                    </div>
                </div>
                <canvas id="canvas">
                </canvas>
            </div>
        </div>
    </div>
</template>
<script>
import { BUILD_FD_API_URL } from '@/constantes.js'
export default {
    props:["dados"],
    data: () => {
        return {
            realizandoUpload:false,
            streaming: false,
            width: 320,
            height: 240,
            imagePoolSize: 6,
            imagePool: [],
            previewPhoto: false,
            lastFace: {
                imagem: null
            },
            isDev: process.env.NODE_ENV == 'development'
        }
    },
    methods: {
        tirarOutra() {
            this.previewPhoto = false
        },
        voltar() {
            this.$emit('direcionar', {from: 'self', to: 'pagamento'})
        },
        avancar() {           
            // Delay proposital para carregar a imagem...
            //setTimeout(()=>{
                this.compararFaces()
            //},500);
        },
        showViewLiveResultButton() {
            if (window.self !== window.top) {
                // Ensure that if our document is in a frame, we get the user
                // to first open it in its own tab or window. Otherwise, it
                // won’t be able to request permission for camera access.
                document.querySelector(".contentarea").remove();
                const button = document.createElement("button");
                button.textContent = "View live result of the example code above";
                document.body.append(button);
                button.addEventListener('click', () => window.open(location.href));
                return true;
            }
            return false;
        },
        clearphoto() {
            var canvas = document.getElementById('canvas');
            var photo = document.getElementById('photo');
            var context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);
            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
        },
        compararFaces() {
            this.realizandoUpload = true
            const baseURL = BUILD_FD_API_URL
            const params = {
                "idreservahospede" : this.dados.dadoscartao.idreservahospedeacomparar,
                "dados" : this.lastFace.imagem,
                "tipo": "image/png",
                "nome": "imgcheckout_" + this.dados.dadoscartao.idreservahospedeacomparar
            }
       
            this.$http.post(`${baseURL}/webcheckout/compararfaces`, params, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
            .then(async resp => {
               
                if (resp.status == 200) {

                    let objResponse = resp.data
                    if (objResponse && objResponse.FaceMatches && objResponse.FaceMatches.length > 0) {
                        this.realizandoUpload = false
                        // Para testar o reconhecimneto facial...
                        // TODO: Para código em produção, descomentar a linha abaixo
                        this.$emit('registrarPagamento', this.dados.dadoscartao)
                    } else {
                        console.error(JSON.stringify(objResponse))
                        this.realizandoUpload = false
                        this.$emit('direcionar', {from: 'self', to: 'falhareconhecimento'})
                    }
                }else{
                    // alert('Não foi possível realizar reconhecimento facial!a2')
                    alert(resp.data)
                    this.realizandoUpload = false
                }
            }).catch(error =>{ alert(error);console.log(error) })            
        },
        holdLastFace(e) {
            this.lastFace.imagem = e.src
        },
        // Captura a foto da câmera
        takepicture() {
            let photo = document.getElementById('photo');
            let video = document.getElementById('video');
            let canvas = document.getElementById('canvas');
            var context = canvas.getContext('2d');
            if (this.width && this.height) {
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(video, 0, 0, this.width, this.height);
                var data = canvas.toDataURL('image/png');
                photo.onload = () => {
                    this.holdLastFace(photo)
                }
                photo.setAttribute('src', data);
            } else {
                this.clearphoto();
            }
            this.previewPhoto = true
        },
        pularreconhecimento() {
            this.$emit('registrarPagamento', this.dados.dadoscartao);
        },
        startup() {
            if (this.showViewLiveResultButton()) { return; }
            let video = document.getElementById('video');
            let photo = document.getElementById('photo');
            let canvas = document.getElementById('canvas');
            
            navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then(function(stream) {
                video.srcObject = stream;
                video.play();
            })
            .catch(function(err) {
                console.log("An error occurred: " + err);
            });
            // O 'this' dentro da função abaixo não se refere à instância Vue
            // Assim, é preciso guardar sua referência...
            const este = this
            video.addEventListener('canplay', function() {
                if (!este.streaming) {
                    este.height = video.videoHeight / (video.videoWidth/este.width);
                    // Bug do Firefox
                    if (isNaN(este.height)) {
                        este.height = este.width / (4/3);
                    }
                    video.setAttribute('width', este.width);
                    video.setAttribute('height', este.height);
                    canvas.setAttribute('width', este.width);
                    canvas.setAttribute('height', este.height);
                    photo.setAttribute('width', este.width);
                    photo.setAttribute('height', este.height);
                    este.streaming = true;
                }
            }, false);
            
            this.clearphoto();
        }        
    },
    mounted() {
        this.startup()
    }
}
</script>

<style scoped>
.inicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    padding-top:30px;
}
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    font-weight: normal;
    color: white!important;
    margin-bottom: 1.5rem;
    width: 90vw;
    align-self: center;
}

.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

#video {
  /* border: 1px solid white;
  box-shadow: 0px 0px 30px 20px blue;
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: relative; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:250px;
  /* height:450px; */
  border-radius: 50%;
  position: relative;
}

#photo {
  /* border: 1px solid black;
  box-shadow: 0px 0px 30px 20px blue;
  width: 90vw;
  height: auto;
  border-radius: 50%; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:300px;
  height:300px;
  border-radius: 50%;
  margin-top:-50px;

}

#canvas {
  display:none;
}

.camera {
    position: relative;
    width: 100%;
    height:60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.output {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 90vw;
    display:inline-block;
    vertical-align: top; */
    position: absolute;
    top:10px;
    width: 70vh;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#startbutton {
  display:block;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  bottom:32px;
}

.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 30vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 25px;
    padding: 0.5rem;
}

.contentarea {
    display: flex;
    flex-direction: column;
    align-content: center;
    /*width: 760px;*/
}
</style>
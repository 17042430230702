<template>
        
        <div class="py-4" style="flex-wrap:wrap;width:100%;">
           <h1 style="text-align:center;" >😊</h1>
           <div class="cardInputs">
                <label class="labels" style="text-align:center;line-height: 1.5;">{{ $t('webcheckin.fotoverso.Envieumafoto') }}</label>
                <!-- <label class="labels" style="text-align:center;color:blue;font-weight:bold;font-size:18px;">( O lado que contem seu nome )</label> -->
            </div>

            <input id="file-upload" type="file" style="display: none" data-cy="btn-uploadverso" accept="image/*" @change="onFileChange"/>
            <div style="display:flex;justify-content:center;">
                <label for="file-upload" style="background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                    <span style="margin-right:10px;font-size:18px;font-weight:bold;color:white;">{{ $t('webcheckin.fotofrente.cliqueaqui') }}</span>
                    <i style="color:white!important;" class="fa fa-search"></i>
                </label> 
            </div>

            <div class="cardInputs" style="display:flex;justify-content:center;align-items:center;">

                        <img v-if="imgSrc"
                        :src="'data:image/jpeg;base64,' + imgSrc"
                        class="imgUpload"
                        />

            </div>

            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-versovoltar">
                      {{ $t('webcheckin.fotofrente.voltar') }}
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-versoavancar">
                        {{ $t('webcheckin.fotofrente.avancar') }}
                </button>
            </div> 
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                    <span>{{ snackText }}</span>
                    <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
            <v-dialog v-model="loading" width="50%"  style="min-height:90vh;">
                <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                    
                    <h1>Aguarde!</h1>
                    <div>Salvando seus dados...</div>
                    
                </v-card>
            </v-dialog>


            <!-- <transition name="modal" v-if="modalCarregando" @close="modalCarregando = false;">
                <div class="modal-mask" >
                <div class="modal-wrapper">
                    <div class="modal-container">

                    <div class="modal-header">
                        <slot name="header headerTitulo" >
                            <div style="width:100%;display:flex;justify-content:center;font-size:20px;">😎</div>
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                
                            <div class="form-group" style="text-align:center;">
                                <b>AGUARDE ENQUANTO CARREGAMOS SUA FOTO!</b>
                            </div>
                            
                        </slot>
                    </div>

                    <div class="modal-footer">

                    </div>
                    </div>
                </div>
                </div>
            </transition> -->


        </div>
        

</template>

<script>
import {serializarImagem} from '../../utils/serializar'
export default {
    props:["dados"],
    data: () =>{
        return{
            isImg: null,
            imgSrc: null,
            imgTipo: null,
            // img_documento_frente:null,
            // url_img_documento:null,
            // fl_doc_retornado:0,
            // fl_carregando:0,
            // id_web_checkin:null,
            // nome:null,
            // tokenWebCheckin:null,
            // step_web_checkin:null,
            // modalCarregando: false,
            // tokenJWT:null
            snack: false,
            snackColor: '',
            snackText: '',
            loading:false,
        }
    },
    mounted(){
        
        this.carregarDados()
       
    },
    methods: {
        async onFileChange(e) {
            this.loading =  true
            serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaFotoVerso(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })

            // try{
            //     this.loading =  true
            //     await this.setImage(e);
            // }catch (error) {
            //     this.loading = false
            //     this.snack = true
            //     this.snackColor = "error" 
            //     this.snackText = error.message
            // }
        },
        // async setImage(e) {

        //     const file = e.target.files[0];

        //     if (!file.type.includes("image/")) {
        //         alert("Please select an image file");
        //         return;
        //     }

        //     if (typeof FileReader === "function") {
        //         const reader = new FileReader();

        //         reader.onload = (event) => {
        //         this.isImg = true;
        //         this.imgSrc = event.target.result;
        //         let index = event.target.result.indexOf(",") + 1;
        //         this.imgSrc = event.target.result.substring(
        //             index,
        //             event.target.result.length
        //         );
        //         if (this.imgSrc) {
        //             this.salvaFotoVerso(file.type);
        //         }
        //         };
        //         reader.readAsDataURL(file);
        //     } else {
        //         this.loading = false
        //         alert("Sorry, FileReader API not supported");
        //     }

        // },
        async salvaFotoVerso(tipoImg) {

            if (!this.imgSrc.length > 0) {
                console.log("invalido");
                return;
            }

            let dados = {
                "tipo" : tipoImg,
                "nome" : "DocumentoVerso",
                "dados" : this.imgSrc,
                "tabela": "hospede",
                "fk": "docverso",
                "chave": "id",
                "valor": this.dados.idhospede
            };
            await this.$http.post("/imagem/upinsert", dados, { timeout: 60000 })
                .then((response) => {
                    if (response.status == 200) {
                        console.log("Foto do Usuario Salvo com Sucesso!");
                    } else {
                        console.log("Não foi possivel salvar a Foto do Usuario!");
                    }
                    this.loading = false
                })
                .catch((error) => {
                    console.log(error.response);
                    this.loading = false
                });

        },
        validarFormulario(){

            if(!this.imgSrc){ 
                // this.$swal(this.$t('alert.notification_document_required.title'),this.$t('alert.notification_document_required.content'),"info"); 
                alert("Imagem do Verso documento e Obrigatorio")
                return false
            }

            return true
            
        },
        
        salvarDados(){
            
            

        },
        async avancar(){

                if( await this.validarFormulario()){

                    this.modalCarregando = true
                    this.$emit('direcionar','dadopessoal')
                    
                } 
            
        },
        voltar(){
           
            this.$emit('direcionar','fotofrente')
            
        },

        carregarDados(){
            this.$http.post("/imagem/select", {id:this.dados.docverso}, { timeout: 60000 })
                .then((response) => {

                    if (response.status == 200) {
  
                        // this.imgSrc = String.fromCharCode.apply(
                        //     null,
                        //     response.data[0].dados.data
                        // );
                        if(response.data.length > 0){
                            this.imgSrc = Buffer.from(response.data[0].dados).toString()
                            this.imgTipo = response.data[0].tipo;
                        }

                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
    <div v-if="1==2" @click="setModalTutorial()" style="padding:0px!important;display:flex;align-items: center;justify-content: center; width:25px;height: 25px;border-radius: 20px;" >

        <img src="../../assets/img/interrogacao.png" width="26" height="26" :alt="legenda"/>
        <!-- <v-icon :title="legenda" style="color:red;font-size: 20px;margin:0px!important;padding:0px!important;">mdi-help-circle-outline</v-icon>  -->
        
        <ModalTutorial :exibirModalTutorial="exibirModalTutorial" @setModalTutorial="setModalTutorial()" 
            :url="urlVideo" :categoria="categoria"/>

    </div>
    
</template>

<script>
import ModalTutorial from '../DashViews/Modals/ModalTutorial.vue';

export default {
    name: "BotaoTutorial",
    props: ["urlVideo", "categoria", "legenda"],
    components: {
    ModalTutorial,
},
    data: () => ({
        exibirModalTutorial: false,
    }),
    methods: {
        setModalTutorial(){
            this.exibirModalTutorial = !this.exibirModalTutorial
        },
    },
    mounted() {
    },
}
</script>

<template>
  <v-card>
    <v-toolbar color="blue">
      <span style="width:95%">Procedência e Destino {{idreserva}} - {{idhospede}} - {{possuiEmpresaReservante}} - {{id}}</span>
      <span style="width:5%"> 
          <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
          </v-btn>
      </span>
    </v-toolbar>
    <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
    <v-form v-model="valid" lazy-validation ref="form">
      <v-card-text style="display: flex;">
        <!-- Procedência -->
        <v-col class="mx-1">
          <v-row>
            <v-flex>
              <div style="padding-bottom:1vh;">
                <span><strong>Última Procedência</strong></span>
              </div>
            </v-flex>
          </v-row>
          <v-row class="my-2">
            <v-select
              :items="paisOrgList"
              item-value="id"
              item-text="nome"
              class="ml-2"
              label="País Origem"
              append-icon="mdi-map"
              style="width: 200px; padding-top: 5px"
              v-model="paisOrigem"
              :rules="selectsRules"
              @change="[listarUfsOrigem(),validate()]"
              required
            >
            </v-select>
            <v-select
              :items="ufOrgList"
              item-value="sigla"
              item-text="sigla"
              class="ml-2"
              label="UF Origem"
              append-icon="mdi-map-marker-multiple"
              style="width: 200px; padding-top: 5px"
              v-model="ufOrigem"
              :rules="selectsRules"
              @change="[listarCidadesOrigem(),validate()]"
              required
            >
            </v-select>

            <v-select
              :items="cidadeOrgList"
              item-value="id"
              item-text="nome"
              class="ml-2"
              label="Cidade Origem"
              append-icon="mdi-map-marker"
              style="width: 200px; padding-top: 5px"
              v-model="cidadeOrigem"
              :rules="selectsRules"
              @change="validate()"
              required
            >
            </v-select>

            <v-text-field
              class="ml-2"
              type="time"
              label="Horario IN"
              style="width: 100px; padding-top: 5px"
              value="14:00"
              v-model="horaCheckin"
              @blur="[validate()]"
              @keyup="validate()"
              required
            />
            <v-select
              class="ml-2"
              :items="meiosTransporte"
              item-value="id"
              item-text="descricao"
              label="Meio de Transporte"
              v-model="meioTransporte"
              :rules="selectsRules"
              @change="[checkTransporte(),validate()]"
              required
            ></v-select>
            <div id="textPlaca" style="display:none!important;">
              <v-text-field 
                class="ml-2"
                label="Placa"
                style="width: 100px;"
                v-model="placa"
              />
            </div>
            <div id="textModelo" style="display:none!important;">
              <v-text-field 
                class="ml-2"
                label="Modelo"
                style="width: 150px;"
                v-model="modelo"
              />
            </div>
          </v-row>
        </v-col>
        <!-- Destino -->
        <v-col class="mx-1">
          <v-row>
            <v-flex>
              <div style="padding-bottom:1vh;">
                <span><strong>Próximo Destino</strong></span>
              </div>
            </v-flex>
          </v-row>
          <v-row>
            <v-select
              :items="paisDestList"
              item-value="id"
              item-text="nome"
              class="ml-2"
              label="País Destino"
              append-icon="mdi-map"
              style="width: 200px; padding-top: 5px"
              v-model="paisDestino"
              :rules="selectsRules"
              @change="[listarUfsDest(),validate()]"
              required
            >
            </v-select>

            <v-select
              :items="ufDestList"
              item-value="sigla"
              item-text="sigla"
              class="ml-2"
              label="UF Destino"
              append-icon="mdi-map-marker-multiple"
              style="width: 200px; padding-top: 5px"
              v-model="ufDestino"
              :rules="selectsRules"
              @change="[validate(), listarCidadesDestino()]"
              required
            >
            </v-select>
            <v-select
              :items="cidadeDestList"
              item-value="id"
              item-text="nome"
              class="ml-2"
              label="Cidade Destino"
              append-icon="mdi-map-marker"
              style="width: 200px; padding-top: 5px"
              v-model="cidadeDestino"
              :rules="selectsRules"
              @change="validate()"
              required
            >
            </v-select>

            <v-text-field
              class="ml-2"
              type="time"
              label="Horario OUT"
              style="width: 100px; padding-top: 5px"
              value="12:00"
              v-model="horaCheckout"
              @blur="[validate()]"
              @keyup="validate()"
              required
            />
          </v-row>
          <v-row>
            <v-select
              class="ml-2"
              :items="motivosViagem"
              item-text="descricao"
              item-value="id"
              label="Motivo da Viagem"
              v-model="motivoViagem"
              :rules="selectsRules"
              @change="validate()"
              required
            ></v-select>
          </v-row>
        </v-col>
      </v-card-text>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
            <span>{{ snackText }}</span>
            <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
          </div>
      </v-snackbar>
      <div style="text-align: center">
        <v-spacer></v-spacer>
         <v-btn style="color:white;" v-if="btnSalvarHabilidado&&possuiEmpresaReservante"
              color="light-green"
               class="mb-4 mt-4"
              medium
              @click="salvarPasso()"
              :disabled="!valid"
              >Salvar</v-btn
            >
            <v-btn style="color:white;font-weight:bold" v-else
              color="light-green"
               class="mb-4 mt-4"
              medium
              :disabled="true"
              >{{possuiEmpresaReservante?'FICHA ESTÁ FINALIZADA':'FICHA BLOQUEADA'}}</v-btn
            >
        <v-spacer></v-spacer>
      </div>
    </v-form>
  </v-card>
</template>
<script>
export default {
  props: ["id","idreserva", "idhospede", "possuiEmpresaReservante"],
  name: "OrigemDestinoFdnew",
  data: () => ({
    reservaOrigemDestino: {}
  }),
  methods: {
    loadData() {
      this.$http.post("/reserva/origemdestino/select", {
          hotel: localStorage.getItem("hotel"),
          id: this.id
        })
        .then(async (response) => {
          if (response.data.length > 0) {
            this.reservaOrigemDestino = response.data[0]
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.loadData();
  }
};

</script>

<template>
  <div>
      <material-card class="card-tabs" color="green">
        <v-flex slot="header">
          <v-tabs v-model="tabs" color="transparent" slider-color="white">
            <v-tab class="mr-3">
              <v-icon class="mr-2">mdi-door</v-icon>
              UHS
            </v-tab>
          </v-tabs>
        </v-flex>

        <v-tabs-items style="heigth: 600px">
          <div>
            <v-data-table
              :headers="headersUHs"
              items="uhs"
              item-key="idmovimentouh"
              :items-per-page="15"
              locale="pt-BR"
              mobile-breakpoint="350"
              loading="20"
              loading-text="Carregando.."
            >
            </v-data-table>
          </div>

          <v-divider />
        </v-tabs-items>
      </material-card>
  </div>
</template>

<script>
export default {
  name: "uhs",
  data: () => ({}),
};
</script>
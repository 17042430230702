<template>
  <v-container class="v-container">
    <v-layout justify-left wrap>
      <!-- Tutoriais cadastrados -->
      <v-flex xs12 md12>
        <v-card style="padding: 20px;">
          <v-card-title>
            <div style="min-width:100%!important;display:flex;justify-content:space-between;">
              <span class="toolbar-title" style="width:80%;">Tutoriais Cadastrados</span>
              <span style="width:5%;"> 
                <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Tutoriais"/>
              </span>
            </div>
            
          </v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            style="padding: 20px;"
            single-line
            hide-details
          />
          <v-data-table
            :headers="headers"
            :items="tutoriais"
            :rows-per-page="5"
            :search="search"
            item-key="id"
          >
            <template v-slot:item="{item}">
              <tr class="table-row" @click="abrirTutorial(item)">
                <td>
                  <a>
                    {{item.sistema}}
                  </a>
                </td>
                <td>
                  {{item.titulo}}
                </td>
                <td>
                  {{item.descricao}}
                </td>
                <td>
                  {{item.video}}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
              </div>
          </v-snackbar>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'

import { tokenapies } from '@/constantes.js'

import {BUILD_APIES_URL} from '@/constantes.js'
import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
    data: () => ({
        snack: false,
        snackColor: "",
        snackText: "",
        search: "",
        tutoriais: [],
        headers: [
            { text: "Sistema", value: "sistema" },
            { text: "Título", value: "titulo" },
            { text: "Descrição", value: "descricao" },
            { text: "Id do Video", value: "video" },
        ],
    }),
    computed: {},
    watch: {},
    methods: {
        abrirTutorial(item) {
            this.$router.push("/dashboard/tutorial/" + item.id);
        },
        carregarTutoriais() {
            let baseURL = BUILD_APIES_URL;
            axios.post(baseURL + "/tutorial/select", { idioma: "pt-BR", sistema: 1, "uid": localStorage.getItem("userid") }, { headers: { "x-access-token": tokenapies } })
                .then((response) => {
                if (response.status == 200) {
                    this.tutoriais = response.data;
                }
            })
                .catch((error) => {
                console.log(error);
            });
            // this.tutoriais = [
            //   {
            //     "id": 1,
            //     "sistema": {
            //       "id": 1,
            //       "nome": "Ficha Digital",
            //       "sigla": "FDI",
            //       "descricao": "Sistema Ficha Digital",
            //       "ativo": true
            //     },
            //     "link": "https://www.youtube.com/watch?v=OeEzmPs7i_U",
            //     "chave": "FDTRAINING",
            //     "descricao": "Introdução ao Ficha Digital",
            //     "ativo": true,
            //     "perguntas": [
            //       {
            //         "id": 15,
            //         "texto": "Como chama o sistema de checkin digital?",
            //         "respostasaceitas": {
            //           "a": "FDGuest",
            //           "b": "Ficha",
            //           "c": "Cardapio"
            //         },
            //         "respostascorretas": {
            //           "b": "Ficha"
            //         },
            //         "ativo": true
            //       },
            //       {
            //         "id": 16,
            //         "texto": "Qual a empresa que produz o Ficha Digital?",
            //         "respostasaceitas": {
            //           "a": "Esmeralda",
            //           "b": "Economy",
            //           "c": "Xpto"
            //         },
            //         "respostascorretas": {
            //           "b": "Economy"
            //         },
            //         "ativo": true
            //       },
            //       {
            //         "id": 17,
            //         "texto": "Com quais PMS o FD se integra?",
            //         "respostasaceitas": {
            //           "a": "Totvs",
            //           "b": "Hits",
            //           "c": "Ambos"
            //         },
            //         "respostascorretas": {
            //           "c": "Ambos"
            //         },
            //         "ativo": true
            //       }
            //     ]
            //   },
            //   {
            //     "id": 2,
            //     "sistema": {
            //       "id": 1,
            //       "nome": "Ficha Digital",
            //       "sigla": "FDI",
            //       "descricao": "Sistema Ficha Digital",
            //       "ativo": true
            //     },
            //     "link": "https://www.youtube.com/watch?v=OeEzmPs7i_U",
            //     "chave": "FDPREENCHER",
            //     "descricao": "Preenchendo os dados no Ficha Digital",
            //     "ativo": true,
            //     "perguntas": [
            //       {
            //         "id": 10,
            //         "texto": "Como chama o sistema de checkin digital?",
            //         "respostasaceitas": {
            //           "a": "FDGuest",
            //           "b": "Ficha",
            //           "c": "Cardapio"
            //         },
            //         "respostascorretas": {
            //           "b": "Ficha"
            //         },
            //         "ativo": true
            //       },
            //       {
            //         "id": 11,
            //         "texto": "Qual a empresa que produz o Ficha Digital?",
            //         "respostasaceitas": {
            //           "a": "Esmeralda",
            //           "b": "Economy",
            //           "c": "Xpto"
            //         },
            //         "respostascorretas": {
            //           "b": "Economy"
            //         },
            //         "ativo": true
            //       },
            //       {
            //         "id": 12,
            //         "texto": "Com quais PMS o FD se integra?",
            //         "respostasaceitas": {
            //           "a": "Totvs",
            //           "b": "Hits",
            //           "c": "Ambos"
            //         },
            //         "respostascorretas": {
            //           "c": "Ambos"
            //         },
            //         "ativo": true
            //       },
            //       {
            //         "id": 13,
            //         "texto": "Quem é Economy?",
            //         "respostasaceitas": {
            //           "tipo": "SIM_NAO"
            //         },
            //         "respostascorretas": null,
            //         "ativo": true
            //       },
            //       {
            //         "id": 14,
            //         "texto": "Como chama o sistema de checkin digital?",
            //         "respostasaceitas": {
            //           "a": "Esmeralda",
            //           "b": "Economy",
            //           "c": "Ambas"
            //         },
            //         "respostascorretas": {
            //           "c": "Ambas"
            //         },
            //         "ativo": true
            //       }
            //     ]
            //   }
            // ]
        },
    },
    mounted() {
        this.carregarTutoriais();
    },
    components: { BotaoTutorial }
};
</script>

<style scoped>

.table-row {
  cursor: pointer;
}
.selected {
  background-color: rgb(154, 209, 253)!important;;
}
.table-row:hover {
  background-color: rgb(33, 150, 243)!important;
  color: white;
  font-weight: bold;
}
</style>
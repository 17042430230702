<template>
  <v-dialog v-model="exibirModalEditarTermoLGPD" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Editar o Termo LGPD
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalEditarTermoLGPD()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <!-- <v-card> -->
        <div style="font-size: 16px; display: flex; flex-direction: column">
          <label style="margin-bottom: 10px"
            >Faça o Upload do Termo LGPD</label
          >
          <input type="file" id="inputFile2" data-cy="inputfile2" accept="application/pdf" @change="onFileChange" />
        </div>

        <div v-if="nomeTermo" style="margin-top:10px;font-weight:bold;background-color:#c9e7ff;color:#14254299;height:30px;display:flex;align-items:center;padding-left:10px;">
          Arquivo Adicionado: {{nomeTermo}}
        </div>

        <div
          style="margin-top:20px;font-size: 16px;display:flex;justify-content:flex-start;align-items:center;"
        >
          <v-switch v-model="enviarTermoLGPD" data-cy="enviarTermo"> </v-switch>
          <label>Enviar Termo LGPD ao hóspede quando finalizar a ficha?</label
          >
        </div>

        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn
            @click="salvarEnviarTermoLGPD()"
            data-cy="salvarEnviarTermoLGPD"
            color="primary"
          >
            Salvar
          </v-btn>
        </div>
        <!-- </v-card> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
var httpaux;
const salvarTermoAux = async (tipoImg,imgSrc) => {

      if (!imgSrc.length > 0) {
        console.log("invalido");
        return;
      }

      let dados = {
        tipo: tipoImg,
        nome: "termolgpd",
        dados: imgSrc,
        tabela: "hotel",
        fk: "termolgpd",
        chave: "id",
        valor: localStorage.getItem("hotel"),
      };

      await httpaux.post("/imagem/upinsert", dados, { timeout: 60000 })
        .then((response) => {
          if (response.status == 200) {
            console.log("Termo LGPD Salvo com Sucesso!");
          } else {
            console.log("Não foi possivel salvar o Termo LGPD!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });

    }
export default {
  
  data: () => ({
    textoEmail:
      "Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
    isImg: null,
    imgSrc: null,
    imgTipo: null,
    nomeTermo: null,
    enviarTermoLGPD: null
  }),
  props: ["exibirModalEditarTermoLGPD"],
  components: {
    // ModalSolicitacoesResponder
  },
  methods: {
    onFileChange() {

      //Read File
        var selectedFile = document.getElementById("inputFile2").files;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = async function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                this.imgSrc = fileLoadedEvent.target.result;
 
                await salvarTermoAux("application/pdf",this.imgSrc)
                // Print data in console
                console.log(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    },
    salvarEnviarTermoLGPD() {

      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "ENVIARTERMOLGPD",
          valor: this.enviarTermoLGPD,
        })
        .then((resp) => {
          // alert(JSON.stringify(resp))
          if (resp.status == 200) {
            this.setModalEditarTermoLGPD();
          } else {
            alert("Não foi alterar os dados de exibição do termos");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setModalEditarTermoLGPD() {
      this.$emit("setModalEditarTermoLGPD");
    },
    carregarEnviarTermoLGPD() {
      this.$http
      .post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "ENVIARTERMOLGPD",
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.enviarTermoLGPD = resp.data && resp.data.length > 0 &&
            (resp.data[0].valor == true || resp.data[0].valor == 'true');
        } else {
          alert("Não foi possivel buscar os dados");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted() {
    httpaux = this.$http
    //busca o email de aniversariante configurado
    
    this.carregarEnviarTermoLGPD()

      this.$http.post("perfil/hotel/select",{id:localStorage.getItem("hotel")})
      .then(resp => {
        console.log("resp.data 12")
        console.log(resp.data[0].termolgpd) 
          if(resp.data[0].termolgpd){
              this.$http.post("/imagem/select", {id:resp.data[0].termolgpd}, { timeout: 60000 })
              .then((response) => {
                this.nomeTermo = response.data[0].nome
              })
              .catch((error) => {
                  console.log(error);
              });
          }
      })
      .catch((error)=>{
        console.log(error)
      })
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>
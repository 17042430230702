<template>
        
        <div class="py-4" style="flex-wrap:wrap;width:100%;">
            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.nacionalidade.datanascimento') }}</label>
                <input type="text" data-cy="input-nascimento" v-mask="['##/##/####']" v-model="datanascimento"  class="inputs" placeholder="dd/mm/yyyy"  />
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.nacionalidade.nacionalidade') }}</label>
                <select v-model="nacionalidade" data-cy="select-nacionalidade"  class="inputs">
                            <option v-for="(pais, index) in nacionalidades" :key="index" :value="pais"  >
                                {{pais.nome}}
                            </option>
                </select>
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.nacionalidade.sexo') }}</label>
                <select v-model="sexo" class="inputs" data-cy="select-genero" >
                        <option value="null" >SELECIONE UMA OPÇÃO</option>
                        <option value="M">MASCULINO</option>
                        <option value="F">FEMININO</option>
                </select>
            </div>

            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-enderecovoltar">
                      {{ $t('webcheckin.nacionalidade.voltar') }}
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-enderecoavancar">
                        {{ $t('webcheckin.nacionalidade.avancar') }}
                </button>
            </div> 
         
        </div>

</template>

<script>

import moment from 'moment'
import dayjs from 'dayjs'   
export default { 
    props:["dados"],
    data: () =>{
        return {
            // os dados que salvarei na base do ficha
            paises:[],
            nacionalidades:[],
            pais:null,
            datanascimento:null,
            nacionalidade: null,
            sexo:null,
            copiarDados: false
        }
    },
    computed: {
    },
    methods:{
        listarPaises(){
            this.$http.post("geo/pais/select", {
                hotel: localStorage.getItem("hotel"),
                orderby: 'noaccent(nome)'})
            .then(resp =>{
       
                    this.nacionalidades = resp.data.filter(p => {
                        return p.nome != undefined;
                    }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);

                    this.nacionalidades = this.nacionalidades.map(p => {
                        p.nome = p.nome.toUpperCase();
                        return p;
                    })

                    if(this.dados.nacionalidade){
                        this.nacionalidade = this.nacionalidades.find(nacionalidade => nacionalidade.id == this.dados.idnacionalidade)
                    }

            })
        },
        validDate(data) {
            var re = /^([12][0-9])\/(0[1-9]|1[012])\/0[1-9]|[12][0-9]|3[01]{3}$/;
            return re.test(data);
        },
        validarFormulario(){

            if(!this.datanascimento){ 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Nascimento é Obrigatorio!'})
                return false
            }

            if(!this.nacionalidade){ 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Nacionalidade Invalida!'})
                return false
            }

            if(!this.sexo){ 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Sexo/Gênero Invalido!'})
                return false
            }

            if(!this.validDate(this.datanascimento)){ 
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Nascimento Invalida!'})
                return false
            }
            
            if(this.datanascimento && parseInt(dayjs(this.datanascimento).format('YYYY')) < parseInt(dayjs().subtract(110,'year').format('YYYY'))){
                this.$emit('emitirAlert', {snack:true,snackColor:'error',snackText:'Data de Nascimento Invalida!'})
                return false
            }

                return true
            

            
        },
        salvarDados(){

            let diaF = this.datanascimento.substring(0,2)
            let mesF = this.datanascimento.substring(3,5)
            let anoF = this.datanascimento.substring(6,10)
            let datanascimento = anoF+'-'+mesF+'-'+diaF
            
            var dadossalvar = this.dados
            dadossalvar.datanascimento   = datanascimento
            dadossalvar.nacionalidade   = this.nacionalidade.id
            dadossalvar.idnacionalidade   = this.nacionalidade.id
            dadossalvar.sexo            = this.sexo
           
            let idade = moment().diff(datanascimento, 'years')
            
            if(idade <= this.dados.idadelimitecrianca){
                dadossalvar.crianca = true
                this.$emit('salvarDados',dadossalvar,'dadopessoal')
            }else{
                dadossalvar.crianca = false
                if (this.copiarDados) {
                    this.$emit('salvarDados',dadossalvar,'copiardados')
                } else {
                    this.$emit('salvarDados',dadossalvar,'motivo')
                }
            }
            

        },
        async carregarDadosAdicionais(){
            //await this.realizarLogin();
            this.copiarDados = false
            this.$http.post("/reserva/preenchida/select", {id:this.dados.id, numeroreserva: this.dados.numeroreserva})
            .then(resp =>{

                if (resp.data.length > 0) {
                    // Próximo passo, decidir se copia ou não dados do hóspede
                    this.copiarDados = true
                }

                if(this.validarFormulario() ){
                    this.salvarDados()
                }

            })
            .catch(error =>{ console.log(error) })
        },
        async avancar(){
            // if (!this.dados.principal) {
                this.carregarDadosAdicionais();
            // } else {
            //     this.copiarDados = false
            //     if(this.validarFormulario() ){
            //         this.salvarDados()
            //     }                
            // }        
        },
        voltar() {
            this.$emit('direcionar', 'docverso')
        }
    },
    mounted(){
        this.listarPaises()
        this.nacionalidade = this.nacionalidades[0];
        this.sexo = this.dados.sexo ? this.dados.sexo : null

        if(this.dados.nascimento != "" && this.dados.nascimento != undefined){ 
            let anoF = this.dados.nascimento.substring(0,4)
            let mesF = this.dados.nascimento.substring(5,7)
            let diaF = this.dados.nascimento.substring(8,10)
            this.datanascimento = diaF+'/'+mesF+'/'+anoF
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
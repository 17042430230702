<template>
    <div class="wout-principal">
        <div class="center" style="">
            <div>
                <img src="@/assets/img/pgtonegado.svg" 
                    width="auto" height="130" alt="Ops! Falha no reconhecimento facial!" crossorigin />
            </div>
            <div class="negado">
                <div >Ops!</div>
                <div>Houve falha na tentativa de reconhecimento facial!</div>
            </div>
            <br>
            <v-btn color="info" class="adicionar-btn" @click="voltar" >
                Tentar novamente
            </v-btn>
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    },
    methods: {
        voltar() {
            this.$emit('direcionar',{from: 'falhareconhecimento', to: 'self'})
        }
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    height:90vh;    
    /* background-color: red; */
}
.negado {
    /* font-weight: normal;
    color: rgb(65, 65, 65)!important;
    padding: 10px;
    margin-left: 40px;
    margin-right: 40px;
    background-color: red; */
    display: flex;
    flex-direction: column;
    padding:30px;
    color: rgb(65, 65, 65)!important;
    /* background-color: red; */
    max-width: 80%;
    
}
.adicionar-btn {
    font-weight: normal;
    font-size: 0.95rem!important;
    /* color: white!important; */
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
}
</style>
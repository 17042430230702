<template>

    <div>
        <v-dialog v-model="exibirModalTutorial" width="90%" height="100vh" max-width="40vw" style="background-color: red;">
            <v-card style="max-width: 100%;height: 90vh;">
                <v-toolbar color="#1D4596" class="mb-8">
                    <v-flex style="display: flex" class="pa-2">            
                        <!-- <span style="width:95%;color:white;font-weight:bold;" >
                            {{titulo}}
                        </span> -->
                        <span style="width:95%;color:white;font-weight:bold;" >
                            Central de Ajuda
                        </span>
                        <span style="width:5%"> 
                            <v-btn x-small color="red" @click="$emit('setModalTutorial',false)" title="Fechar">
                                <v-icon x-small style="color:white;">mdi-close</v-icon>
                            </v-btn>
                        </span>

                    </v-flex>
                </v-toolbar>

                <div style="width:100%;padding-left:5%;padding-right: 5%;display: flex;flex-direction: row;justify-content: space-between;">
                    <v-btn color="info" style="width:45%;" @click="setExibicao('video')">
                        <v-icon style="margin-right:10px;">mdi-video</v-icon>Videos
                    </v-btn>
                    <v-btn color="info" style="width:45%;" @click="setExibicao('faq')">
                       <v-icon style="margin-right:10px;">mdi-frequently-asked-questions</v-icon> Perguntas e Respostas
                    </v-btn>
                    <div></div>

                </div>
                <v-card-text class="text-center">
                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="12" v-if="exibirVideo">
                            <iframe width="560"  height="315" :src="url"  frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe> 
                            

                            <!-- <div style="width:100%;display:flex;justify-content: flex-end;align-items:flex-end;padding-right: 20px;margin-top:20px;">
                                    
                                    <p style="color:#494949;font-weight: 100; font-size: medium; margin-bottom: 4px;">
                                        Essa resposta te ajudou?
                                    </p>
                                    <v-btn icon style="margin-right: 0" color="gray" @click="onclick()">
                                        <v-icon color="gray">
                                            mdi-thumb-up
                                        </v-icon>
                                    </v-btn>
                                    <v-btn icon style="margin-right: 0" color="gray" @click="onclick()">
                                        <v-icon color="gray">
                                            mdi-thumb-down
                                        </v-icon>
                                    </v-btn>
                                
                            </div> -->
                        </v-col>
                        <v-col cols="12" md="12" v-else>
                           
                                <v-text-field
                                    v-model="search" append-icon="search" label="Buscar" single-line hide-details
                                />
                                <v-data-table data-table-dense-header-height='100px' :headers="headersTutoriais" 
                                :search="search" :items="listaDeFaqs" :items-per-page="5" 
                                style="width:100%;margin-top:5px;" >
                                    <template  v-slot:[`item.pergunta`]="{ item }">

                                            <a @click="setModalTutorialResposta(item)" style="color:#494949;font-weight: 300;text-decoration: underline;">
                                                {{item.pergunta}}
                                            </a>

                                    </template>
                                </v-data-table>
                            
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <ModalTutorialResposta v-if="exibirModalTutorialResposta" :exibirModalTutorialResposta="exibirModalTutorialResposta" @setModalTutorialResposta="setModalTutorialResposta()"
                                        :resposta="faqSelecionado.resposta" :curtidasfaq="faqSelecionado.curtidasfaq" :idfaq="faqSelecionado.id" />

    </div>
    
</template>          

<script>
//import axios from 'axios'

import ModalTutorialResposta from './ModalTutorialResposta.vue';
import { tokenapies, BUILD_ID_CLIENTE,BUILD_APIES_URL } from '@/constantes.js'


export default {
    name: "ModalTutorial",
    data: () => ({
        headersTutoriais:[
            { text: "Pergunta", value: "pergunta" },
        ],
        // listaDeTutoriais:[
        //     {id:1,titulo:'Tutorial 1'},
        //     {id:2,titulo:'Tutorial 2'},
        //     {id:3,titulo:'Tutorial 3'},
        //     {id:4,titulo:'Tutorial 4'},
        // ],
        search: "",
        pergunta: "",
        resposta: "",
        listaDeFaqs:[],
        exibirModalTutorialResposta: false,
        faqSelecionado:{},
        exibirVideo:true
    }),
    props:["exibirModalTutorial","url","categoria"],
    components: {
    ModalTutorialResposta
},
    methods: {
        setExibicao(itemExibir){
            if(itemExibir == 'video'){
                this.exibirVideo = true
            }else{
                this.exibirVideo = false
            }
        },
        buscarFaqs(){
            let baseURL = BUILD_APIES_URL
            
            this.$http.post(baseURL+"/faq/pergunta/endpoint/select", 
            {pergunta: this.search,categorias:this.categoria,idcliente:BUILD_ID_CLIENTE,
                idusuario:localStorage.getItem('userid'),idsistema:1},
            {headers:{'x-access-token':tokenapies}})
                .then((response) => {
                        this.listaDeFaqs = response.data
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        setModalTutorialResposta(faq){
            this.faqSelecionado = faq
            this.exibirModalTutorialResposta = !this.exibirModalTutorialResposta
        },
    },

    mounted() {
        // this.buscarFaqs()
    },
};
</script>
<style scoped>
    .table-row {
        cursor: pointer;
        font-size: 12px;
        text-align: left;
    }
    .table-row a{
        font-weight: bold;
        font-size: 13px;
    }
    .selected {
        background-color: rgb(154, 209, 253)!important;;
    }
    .table-row:hover {
        background-color: rgb(33, 150, 243)!important;
        color: white;
        font-weight: bold;
    }
    .table-row:hover a{
        color: white;
        font-weight: bold;
    }
</style>
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-card class="pa-2">
          <!-- Título -->
          <v-toolbar color="light-blue" class="ma-2"
            style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
            <div style="min-width:100%!important;display:flex;justify-content:space-between;">
              <span class="toolbar-title"> 
                Extrato Utilização Whatsapp
              </span> 

            </div>
          </v-toolbar>
          <br>
          <!-- Filtro -->
          <v-row style="max-width: 100%;display:flex;align-items:center;justify-content:center;padding-left:10%;padding-right:10%;">
            <v-col cols="12" md="3" >
                    <v-select class="" label="Status" v-model="filtroStatusPagto" :items="listadestatus"
                        item-value="id" item-text="nome" return-object style="width:100%;" >
                    </v-select></v-col>
            <v-col cols="12" md="3" >
                    <v-text-field class="mr-2" type="date" label="Check-IN Inicial" v-model="filtroDataInicial" style="width:100%;"  >
                    </v-text-field>
            </v-col>
            <v-col cols="12" md="3" >
                    <v-text-field class="mr-2" type="date" label="Check-IN Final" v-model="filtroDataFinal" style="width:100%;" >
                    </v-text-field>
            </v-col>
            <v-col cols="12" md="3" >
                    <v-btn @click="loadConsumo()" small color="primary" class="mx-2" :disabled="textoBtnPequisar != 'Pesquisar'" style="color:white;min-height:40px;font-weight:bold;width:100%;">
                        <v-icon color="white" v-if="textoBtnPequisar == 'Pesquisar'">mdi-magnify</v-icon>
                        {{textoBtnPequisar}}
                    </v-btn>
            </v-col>


      
          </v-row>
          <v-spacer />

          
          
          <v-data-table v-if="this.filtroStatusPagto.id == 1" :headers="headersSintetico" :items="itensSintetico"   class="elevation-1" >
                    <template v-slot:[`item.data_pagamento`]="{ item }">
                        {{item.data_pagamento | formatarData}}
                    </template>
          </v-data-table>

          <v-data-table v-if="this.filtroStatusPagto.id == 2" :headers="headersAnalitico" :items="itensAnalitico"   class="elevation-1" >
                    <template v-slot:[`item.data_envio`]="{ item }">
                        {{item.data_envio | formatarDataYYHora}}
                    </template>

                    <template v-slot:[`item.valor_mensagem`]="{ item }">
                        R$ {{item.valor_mensagem | formatarMoeda}}
                    </template>
          </v-data-table>
          
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {

  data: () => ({
    listadestatus:[
      {id:1,nome:'Sintetico'},
      {id:2,nome:'Analitico'},
    ],
    filtroStatusPagto:{id:1,nome:'Sintetico'},
    filtroDataInicial: null,
    filtroDataFinal: null,
    footerProps: {
      'items-per-page-options': [15,20,25,{ text: "Todas", value: -1 }],
      'items-per-page-text': 'Linhas por página',
    },
    dialog: false,
    search: "",
    headersSintetico: [
      { text: "Competencia", value: "competencia" },
      { text: "Quantidade Envios", value: "qtde_envios" },
      { text: "Valor Somado", value: "valor" },
      { text: "Data Pagamento", value: "data_pagamento" },
    ],
    itensSintetico:[],
    headersAnalitico: [
      { text: "Competencia", value: "competencia" },
      { text: "Data Envio", value: "data_envio" },
      { text: "Destino", value: "telefone_destino" },
      { text: "Modelo Mensagem", value: "template" },
      { text: "Valor Cobrado", value: "valor_mensagem" },
    ],
    itensAnalitico:[],
    textoBtnPequisar: 'Pesquisar',
  
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
        this.filtroDataInicial =  dayjs().startOf("month").format('YYYY-MM-DD')
        this.filtroDataFinal =    dayjs().endOf('month').format('YYYY-MM-DD')
        this.loadConsumo()
  },
  methods: {
    loadConsumo(){
        if(this.filtroStatusPagto.id == 1){
            this.gerarRelatorioSintetico()
        }
        if(this.filtroStatusPagto.id == 2){
            this.gerarRelatorioAnalitico()
        }
    },
    gerarRelatorioSintetico(){
        let params = {
            datainicial:this.filtroDataInicial,
            datafinal:this.filtroDataFinal
        }
        console.log('params sintetico ',JSON.stringify(params,null,2))
        this.$http.post("/whatsapp/gerarRelatorioSintetico", params)
        .then((response) => {
            if (response.status == 200) {
                console.log('response data',response.data)
                this.itensSintetico = response.data
            } else {
                 alert("Sem dados!")
            }
        })
        .catch((error) => {          
            console.log(error)
        });
    },
    gerarRelatorioAnalitico(){
        let params = {
            datainicial:this.filtroDataInicial,
            datafinal:this.filtroDataFinal
        }
        this.$http.post("/whatsapp/gerarRelatorioAnalitico", params)
        .then((response) => {
            if (response.status == 200) {
                this.itensAnalitico = response.data
            } else {
                 alert("Sem dados!")
            }
        })
        .catch((error) => {          
            console.log(error)
        });
    },
   
    
  },
};
</script>
<style scoped>
.item-group{
  display: flex;
  flex-direction: row;
}
.item-action{
  cursor: pointer;
  font-size: 0.7rem!important;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.item-value{
  font-size: 0.8rem!important;
}
</style>
<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <div class="wout-topo-pgto">
            <div style="width:15%;margin-top:10px;">
                <button class="menu-voltar-btn" style="display:flex;" @click="voltar()">
                    <v-icon color="blue" style="margin-right:10px;">mdi-arrow-left</v-icon> Voltar
                </button>
            </div>
            <!-- <div class="white--text text-subtitle-2 px-2" style="width:70%;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" v-if="dados">Apto.{{dados.uh}} Reserva: {{dados.numeroreserva}}</div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" @click="logout">mdi-power</v-icon>
            </div> -->
        </div>

        <div class="cc-principal" style="margin-top:20px;">
            <h1  style="font-size:26px;margin-bottom:20px;color:#545454;">Dados Para Pagamento Pix</h1>
            

            <!-- <div class="cc-center">Cartão de {{tipoPagto == 1?'Crédito':'Débito'}}</div> -->
            <div class="cc-form">
            <form autocomplete="off">
                <div class="cc-row"  style="padding-bottom:10px!important;">
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular" style="margin-right:10px;">Valor: </label>R$ {{dados.saldo}}<br>
                    </div>
                </div>
                <div class="cc-row" style="padding-bottom:10px!important;">
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular" style="margin-right:10px;">Tipo Chave:</label>{{tipoChave}}<br>
                    </div>
                </div>
                <div class="cc-row" style="padding-bottom:10px!important;">
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular"  style="margin-right:10px;">Valor Chave:</label>{{valorChave}}<br>
                    </div>
                </div>
                <div class="cc-row" style="padding-bottom:10px!important;">
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular" style="margin-right:10px;">Titular:</label>{{titularChave}}<br>
                    </div>
                </div>
                <div class="cc-row" >
                    <div class="cc-col-12" style="display:flex;align-items:center;justify-content:center;">
                        <img v-if="imgQRCode" :src="imgQRCode" class="" alt="QR CODE PIX" crossorigin  style="width:300px;"/>
                    </div>
                </div>
                <!-- <div class="cc-row" >
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular">Tipo Chave Pix:</label><br>
                        {{tipoChave}}<br><br>
                    </div>
                </div>
                <div class="cc-row" >
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular">Valor Chave:</label><br>
                        {{valorChave}}<br><br>
                        
                    </div>
                </div>
                
                <div class="cc-row" >
                    <div class="cc-col-12">
                        <label class="cc-label" for="i-nometitular">Nome Titular:</label><br>
                        {{titularChave}}<br><br>
                    </div>
                </div> -->

            </form>
            </div>
            
                        
        </div>
    </div>
</template>

<script>

const { PIX } = require('gpix/dist');
export default {
    name: 'DADOS_PIX_CP',
    props:["dados"],
    data: () =>{
        return{
            tipoChave: '',
            valorChave: '',
            titularChave: '', 
            imgQRCode:'', 
        }
    },
    components: {
	},
    computed: {
        validInput() {
            return (
                typeof(this.validarNome()) == 'boolean'
                && typeof(this.validarNumeroCartao()) == 'boolean'
                && typeof(this.validarValidade()) == 'boolean'
                && typeof(this.validarCVCCVV()) == 'boolean'
            )
        }
    },
    methods: {
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'confirmardadosvoucher',from:'dadospix'})
        },
        carregarDados(){
             this.$http.post('configuracao/buscarParametro',{chave:'PIXCONFIGCHECKINPAY'})
                .then(async resp =>{
                    this.tipoChave      = resp.data[0].valor2
                    this.valorChave     = resp.data[0].valor3
                    this.titularChave   = resp.data[0].valor4 
                   

                    let valorsaldo = parseFloat(this.dados.saldo)
                    

                    let pix = PIX.static()
                                  .setReceiverName(this.titularChave)
                                  .setReceiverCity('Natal')
                                  .setKey(this.valorChave)
                                  .setAmount(valorsaldo) // optional
                                  // .setReceiverName('HERLESSI NOGUEIRA BEZERRA')
                                  // .setReceiverCity('Fortaleza')
                                  // .setKey('02165680301')
                                  // .setAmount(5.0) // optional
                                        // .setDescription('Donation with defined amount - GPIX') // optional
                                        // .isUniqueTransaction(true) // optional
                                        
                                        // .setIdentificator('123') // optional

                    this.imgQRCode = await pix.getQRCode()
                }).catch(error =>{
                    alert(error)
                })
        }
    },
    mounted(){
        window.scrollTo(0, 0);
        this.carregarDados()   
    }
}
</script>

<style lang="scss" scoped>
.cc-principal {
  margin: 0px;
  width:100%;
  height: 100%;
  padding: 2em 1em;
  border-radius: 1em;
  max-width: 450px;
  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
  margin: 0 auto;
  @media screen and (min-width: 768px ) {
    height: auto;      
  }
}

.cc-label{
  font-size: 14px;
  text-transform: capitalize;
}

.credit-card-brand{
  width: 4em;
  height: auto;
}

.cc-cartao {
  width: 100%;
  margin: auto;
  padding: 1em;
  display: grid;
  grid-template-rows: .2fr 1fr .2fr;
  grid-template-columns: 1fr;
  border-radius: 1em;
  height: 12em;
  color: #666;
  margin-bottom: 1em;

  background: rgb(25,118,210);
  background: -moz-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: -webkit-linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  background: linear-gradient(31deg, rgba(25,118,210,1) 13%, rgba(19,119,242,0.7707457983193278) 56%, rgba(0,212,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1976d2",endColorstr="#00d4ff",GradientType=1);

  color: #f9f9f2;
  font-weight: bold;

  box-shadow: 0px 10px 20px rgba(128, 128, 128, .25);
//   backdrop-filter: blur(2px);

  .cc-cartao_corpo{
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1em;
  }
  .cc-cartao_rodape{
    z-index: 3;
    display: flex;
    justify-content: flex-end;
  }
}
.cc-pagar-btn {
  font-size: 1rem!important;
  font-weight: bold;
  background-color: #1976d2!important;
  color: white;
  padding: 1.5vh;
  line-height: 1.5;
  border-radius: 1em;
  width: 100%;
  height: 3em;
  display: flex;
}
.cc-pagar-btn:disabled {
  background-color: #80808020 !important;
  .cc-pagar-btn_texto{
    color: #BABAB5 !important;
  }
}



.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}



//css vuetify

.v-text-field--rounded {
  border-radius: 1em !important;
}


.v-application p {
  margin-bottom: unset !important;
}

.v-text-field__details {
    margin-bottom: 3px !important;
}
</style>
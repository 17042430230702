<template>
  <v-layout>
    <v-flex>
      <div>
        <v-card>

          <v-toolbar color="light-blue" class="ma-2" style="color: white;display:flex;flex-direction:column;">
            <div style="min-width:100%!important; display:flex;justify-content:space-between;align-items:center;">
              <div style="">
                <span class="toolbar-title">Reservas</span> 

                
              </div>
                <div style="display:flex;justify-content:space-between;padding-top:20px;width:100%;">
                    <v-row>
                      <v-col cols="12" md="6"></v-col>
                      <v-col cols="12" md="6" style="display:flex;flex-direction:row;justify-content: flex-end;">
                        
                        <v-select  v-model="tag" :items="tags" item-value="id" item-text="valor" return-object  
                            style="max-width:150px!important;margin-left:20px;">
                        </v-select>


                        <v-btn style="margin-left:20px;" small elevation="" color="error" @click="sincronizarTag()" v-if="!statusSincTag">Sincronizar</v-btn>
                        <v-btn style="margin-left:20px;" small elevation="" color="success" @click="desincronizarTag()" v-else>Desincronizar</v-btn>                    

                        <BotaoTutorial style="margin-left:20px;" urlVideo="" categoria="usuário" legenda="Dúvidas Sincronizar"/>

                      </v-col>
                    
                    </v-row>

                      
                      
                      
                      
                </div>
            </div>
          </v-toolbar>

          <v-card style="padding-left:20px">
            <v-row style="width: 100%;display:flex;justify-content:flex-start;">

                  <!-- Numero Reserva -->
                  <v-text-field class="ml-5 mr-2" type="text" label="Reserva" v-model="filtroReserva" data-cy="filtro-reserva" style="max-width:20%!important;" >
                  </v-text-field>

                  <!-- Nome -->
                  <v-text-field class="mr-2" type="text" label="Nome" v-model="filtroNome" data-cy="filtro-nome" style="max-width:20%!important;" >
                  </v-text-field>

                   <!-- Sobrenome -->
                  <v-text-field class="mr-2" type="text" label="Sobrenome" v-model="filtroSobrenome" data-cy="filtro-sobrenome" style="max-width:20%!important;" >
                  </v-text-field>

                  <!-- StatusReserva -->
                  <v-select class="mr-2" type="text" label="Status" v-model="filtroStatusReserva" :items="listadestatus" item-value="id" item-text="descricao" data-cy="filtro-status" return-object style="max-width:20%!important;">
                  </v-select>

                   <!-- Data Inicial -->
                  <v-text-field class="mr-2" style="max-width: 20%;" type="date" label="Check-IN Inicial" data-cy="filtro-datainicial" v-model="filtroDataInicial" >
                  </v-text-field>

                  <!-- Data Final -->
                  <v-text-field class="mr-2" style="max-width: 20%;margin-right:2%;" type="date" label="Check-IN Final" data-cy="filtro-datafinal" v-model="filtroDataFinal" >
                  </v-text-field>

            </v-row>

            <v-row style="width: 100%;display:flex;justify-content:flex-start;">
                  <div style="width:50%;padding-left:20px;">
                    <!-- <v-checkbox label="Somente Ativadas" v-model="somenteAtivas" value="true" style="margin:0px!important;padding:0px!important;"></v-checkbox>  -->
                  </div>
                  <div style="width:50%;display:flex;justify-content:flex-end">

                        <v-btn @click="buscarReservasPeloBtn()" small color="primary" class="mx-2" :disabled="disabledSearch" style="color:white;min-height:40px;font-weight:bold;" data-cy="btn-filtrar">
                            <v-icon color="white" v-if="textoBtnPequisar == 'Pesquisar'">mdi-magnify</v-icon>
                            {{textoBtnPequisar}}
                        </v-btn>
              
                        <v-btn @click="limparFiltro()" small color="warning" class="mx-2" data-cy="btn-limparfitro" style="color:white;min-height:40px;font-weight:bold;">
                            <v-icon color="white">mdi-broom</v-icon>
                            Limpar Filtros
                        </v-btn>
                    

                        <button  v-show="ativarImportacoes"  style="cursor:ponter;"  @click="setModalImportar(true)"  :disabled="isDisabledADMH" v-if="buttonEnabled" >
                            <v-icon small color="grey" class="mx-1">mdi-cog 
                            </v-icon>
                        </button>

                  </div>
                  
            </v-row>
            
            <v-row style="max-width: 100%;display:flex;align-items:center;justify-content:space-between;">
              
                  <!-- <v-text-field
                    class="ml-5 mr-2"
                    type="text"
                    label="Reserva"
                    v-model="filtroReserva"
                    data-cy="filtro-reserva"
                    style="max-width:13%!important;"
                  >

                  </v-text-field>
             
                  <v-text-field
                    class="mr-2"
                    type="text"
                    label="Nome"
                    v-model="filtroNome"
                    data-cy="filtro-nome"
                    style="max-width:13%!important;"
                  >
                  </v-text-field>
                
                  <v-text-field
                    class="mr-2"
                    type="text"
                    label="Sobrenome"
                    v-model="filtroSobrenome"
                    data-cy="filtro-sobrenome"
                    style="max-width:13%!important;"
                  >
                  </v-text-field>

                 
                  <v-select
                    class="mr-2"
                    type="text"
                    label="Status"
                    v-model="filtroStatusReserva"
                    :items="listadestatus"
                    item-value="id"
                    item-text="descricao"
                    data-cy="filtro-status"
                    return-object
                    style="max-width:13%!important;"
                  >
                  </v-select>

                 
                  <v-text-field
                    class="mr-2"
                    style="max-width: 13%;"
                    type="date"
                    label="Check-IN Inicial"
                    data-cy="filtro-datainicial"
                    v-model="filtroDataInicial"
                  >
                  </v-text-field>
                  
                  <v-text-field
                    class="mr-2"
                    style="max-width: 13%;margin-right:2%;"
                    type="date"
                    label="Check-IN Final"
                    data-cy="filtro-datafinal"
                    v-model="filtroDataFinal"
                  >
                  </v-text-field> -->

            <div style="display:flex;justify-content:center;padding-left:20px;width:20%;">
                  <!-- <v-btn @click="buscarReservasPeloBtn()" small color="primary" class="mx-2" :disabled="disabledSearch"
                    style="color:white;min-height:40px;font-weight:bold;"
                    data-cy="btn-filtrar"
                  >
                    <v-icon color="white" v-if="textoBtnPequisar == 'Pesquisar'">mdi-magnify</v-icon>
                    {{textoBtnPequisar}}
                  </v-btn>
             
                  <v-btn @click="limparFiltro()" small color="warning" class="mx-2" 
                    data-cy="btn-limparfitro"
                    style="color:white;min-height:40px;font-weight:bold;">
                    <v-icon color="white">mdi-broom</v-icon>
                    Limpar Filtros
                  </v-btn>
                  

                  <button 
                  v-show="ativarImportacoes" 
                  style="cursor:ponter;" 
                  @click="setModalImportar(true)" 
                  :disabled="isDisabledADMH"
                  v-if="buttonEnabled"
                  >
                    <v-icon small color="grey" class="mx-1">mdi-cog 
                    </v-icon>
                  </button> -->

                  <BotaoTutorial style="margin-left:20px;margin-top: 7px" urlVideo="" categoria="" legenda="Dúvidas Reserva"/>


            </div>


            
            </v-row>

            <v-row style="max-width: 100%;display:flex;align-items:center;justify-content:flex-start;padding-left:20px;">
              
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="legenda" style="display:flex;justify-content:center;">
                  <v-icon small color="purple" class="mx-1">mdi-login
                  </v-icon>Preencher ficha
                  <v-icon small color="#005893" class="mx-1">system_update
                  </v-icon>Visualizar e Baixar FNRH
                  <v-icon small color="brown" class="mx-1">mdi-email-send
                  </v-icon>Enviar notificação
                  <v-icon small color="blue" class="mx-1">mdi-chat-alert
                  </v-icon>Solicitações
                  <v-icon small color="green" class="mx-1" title="Ficha 100% preenchida!">
                    mdi-check-circle
                  </v-icon>/
                  <v-icon small color="red" class="mx-1" title="Ficha aguardando preenchimento!">
                    mdi-account-alert
                  </v-icon>Status da Ficha
                  <v-icon small color="orange" class="mx-1">mdi-clipboard-check
                  </v-icon>Status PMS
                  <v-icon small color="orange" class="mx-1">mdi-crown
                  </v-icon>Hóspede Principal
                </div>
              </v-col>
            </v-row>
          </v-card>
          <div class="pl-2 pr-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
          </div>

          <v-dialog v-model="dialog">
            <div v-if="step == 0">
              <ReservaFd v-bind:numeroreserva="editedItem.numeroreserva" 
                @update="buscaReservas()"
                @fechar="dialog=false"
                :ativarImportacoes="ativarImportacoes"
                :liberarNovoWebCheckin="liberarNovoWebCheckin"
              />
            </div>
            <!-- FIM PASSO INICIAL -->
          </v-dialog>

          <!-- DIALOG 2 -->
          <v-dialog v-model="dialog2" width="500">
            <v-card style="max-width: 500px">
              <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex;padding: 10px;">
                  <span>
                    Reserva: {{ editedItem.numeroreserva }}
                   <!-- {{ editedItem.datacheckinprevisto }}  ||
                    {{ editedItem.datacheckoutprevisto }} -->
                    <br />
                    Hóspede: {{ editedItem.nome }} {{ editedItem.sobrenome }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn x-small color="red" @click="dialog2 = false" title="Fechar"
                    ><v-icon x-small color="white">mdi-close</v-icon></v-btn
                  >
                </v-flex>
              </v-toolbar>
              <v-card-text>
                <div style="align-items: center">
                  <v-row style="">
                    <v-flex xs12  style="display: flex;">
                      <div style="display:flex;justify-content:center;align-items:center;width:60%;padding-left:10px;">
                        <v-text-field
                          v-model="email"
                          append-icon="mdi-email"
                          type="email"
                          label="Email"
                          style="min-width: 100% !important;"
                          class="mr-2"
                          :rules="emailRules"
                        />
                      </div>
                      <div style="display:flex;justify-content:center;align-items:center;padding-left:10px;">
                        <v-btn
                          x-small
                          class="mr-2"
                          color="#00bb2d"
                          title="Enviar e-mail direto..."
                          @click="setModalDirectMail(),salvarDadosEmail(editedItem)"
                          
                          ><v-icon small color="white">mdi-email-send</v-icon></v-btn
                        >

                        <v-btn
                          x-small
                          class="mr-2"
                          color="orange"
                          title="Enviar e-mail Web Check-IN"
                          @click="enviarEmail()"
                          
                          ><v-icon small color="white" >mdi-email-plus</v-icon></v-btn
                        >
                        <v-btn
                          x-small
                          class="mr-2"
                          color="purple"
                          title="Enviar e-mail Check-OUT Express"
                          ><v-icon small color="white" >mdi-cash-multiple</v-icon></v-btn
                        >
                        <v-btn
                          x-small
                          color="blue"
                          title="Enviar e-mail Check-IN Pay"
                          ><v-icon small color="white" >mdi-counter</v-icon></v-btn
                        >
                      </div>
                    </v-flex>
                  </v-row>
                  <v-row>
                    <v-flex xs12 style="display: flex">
                    <div style="display:flex;justify-content:center;align-items:center;width:60%;padding-left:5px;">
                        <v-text-field
                          v-model="ddi"
                          label="DDI"
                          style="min-width: 20px !important;"
                          class="mr-2"
                          @keypress="isNumber($event)"
                        />
                        <v-text-field
                          v-model="ddd"
                          label="DDD"
                          style="min-width: 20px !important;"
                          class="mr-2"
                          @keypress="isNumber($event)"
                        />
                        <v-text-field
                          v-model="telefone"
                          append-icon="mdi-whatsapp"
                          label="WhatsApp"
                          style="min-width: 200px !important;"
                          class="mr-2"
                          @keypress="isNumber($event)"
                        />
                    </div>

                    <div style="display:flex;justify-content:center;align-items:center;padding-left:10px;">
                        <v-btn
                          x-small
                          class="mr-2"
                          color="#00bb2d"
                          title="Enviar WhatsApp Direto"
                          :disabled="!isWhatsAppSet"
                          @click="setModalDirectWhatsApp(), salvarDadosFone(editedItem)"
                          ><v-icon small color="white">mdi-whatsapp</v-icon></v-btn
                        >
                        <v-btn
                          x-small
                          class="mr-2"
                          color="orange"
                          title="Enviar WhatsApp Web Check-IN"
                          :disabled="!isWhatsAppSet"
                          @click="eModalWebCheckin(editedItem), salvarDadosFone(editedItem)"
                          ><v-icon small color="white">mdi-email-plus</v-icon></v-btn
                        >
                        <v-btn
                          x-small
                          class="mr-2"
                          color="purple"
                          title="Enviar WhatsApp Check-OUT Express"
                          :disabled="!isWhatsAppSet"
                          ><v-icon small color="white">mdi-cash-multiple</v-icon></v-btn
                        >
                        <v-btn
                          x-small
                          class="mr-2"
                          color="blue"
                          title="Enviar WhatsApp Check-IN Pay"
                          :disabled="!isWhatsAppSet"
                          ><v-icon small color="white" >mdi-counter</v-icon></v-btn
                        >

                    </div>

                    </v-flex>
                  </v-row>
                  <div style="height : 20px!important;">
                    <span ><strong> {{ respostaAcao }}</strong></span>
                  </div>
                  <div style="height: 20px!important;">
                    {{ isWhatsAppSetText }}
                  </div>
                </div>
              </v-card-text>
              <v-btn color="green" class="respostasWhatsapp" @click="ModalRespostaWhatsapp(), salvarDadosFone(editedItem)">
                <v-icon small color="white">mdi-whatsapp</v-icon>
                  <span style="color: white;">Chat Whatsapp</span>
              </v-btn>
            </v-card>
          </v-dialog>

          <ModalWebCheckin  :hospede="hospedeMensagem" :numeroWhatsapp="this.ddi+this.ddd+this.telefone" :dadosWebCheckin="dadosWebCheckin" :exibirModalWebCheckin="exibirModalWebCheckin" @exibirModaWeb="eModalWebCheckin()" />

          <ModalRespostaWhatsapp :nomeHotel="nomeHotel" v-if="exibirModalRespostaWhatsapp"  :id_hospede="idhospedeWhatsapp" :nomeHospede="nomeHospede"
          :numeroWhatsapp="this.ddi+this.ddd+this.telefone"  :exibirModalRespostaWhatsapp="exibirModalRespostaWhatsapp" @respostasWhatsapp="ModalRespostaWhatsapp"/>
        
          <ModalImportar @importar="(valor) => importar(valor)" 
              @setModalImportar="(valor) => setModalImportar(valor)" 
              :exibirModalImportar="exibirModalImportar" 
              :processando="processando" :resultado="resultado" 
              :periodoDefault="periodoImportacaoDefault"
          />

          <ModalSolicitacoes @consultarSolicitacoes="(valor) => consultarSolicitacoes(valor)" 
              @setModalSolicitacoes="(valor) => setModalSolicitacoes(valor)" :exibirModalSolicitacoes="exibirModalSolicitacoes" 
              :solicitacoes="solicitacoes" :itemselecionado="itemselecionado" 
              :respostasExtras="respostasExtras" :horarioVoo="horarioVoo"
          />

          <ModalDirectMail  :email="email" :exibirModalDirectMail="exibirModalDirectMail" @setModalDirectMail="setModalDirectMail()"/>
          
          <ModalDirectWhatsApp :id_hospede="idhospedeWhatsapp" :numeroWhatsapp="this.ddi+this.ddd+this.telefone" :exibirModalDirectWhatsApp="exibirModalDirectWhatsApp" @setModalDirectWhatsApp="setModalDirectWhatsApp()"/>
          
          <ModalLogReservas v-if="exibirModalLogReserva" 
          :exibirModalLogReserva="exibirModalLogReserva" 
          :logNumeroReserva="logNumeroReserva" 
          :idReserva="idReserva"
          :idHospede="idHospede"
          :emailReserva="emailReserva"
          @setModalLogReserva="setModalLogReserva()"/>

          <ModalTutorial :exibirModalTutorial="exibirModalTutorial" @setModalTutorial="setModalTutorial()" 
            titulo="Como editar uma Ficha" url=" " />

          <!-- Para ordenar: -->
          <!-- :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" -->
          <v-data-table
            :headers="headers"
            :items="ReservasList"
            :search="search"
            class="elevation-1"
            no-data-text="Nenhum registro encontrado. Clique na vassoura para limpar o filtro de datas."
          >

            <template v-slot:[`item.numeroreserva`]="{ item }">
              <v-icon style="margin-bottom:1px;color:#1976d2;margin-right:2px;" :title="item.idhospedepms" small>mdi-qrcode</v-icon>
              <span @click="setQrCodeModal(item)" style="color:#1976d2;cursor:pointer;" data-cy="td-numeroreserva">{{item.numeroreserva}}</span>
            </template> 

            <template v-slot:[`item.actions`]="{ item }">
              
                  <v-icon color="orange"
                  class="mr-1"
                  :data-cy="`acao-log_${item.numeroreserva}`"
                    @click="[setModalLogReserva(), logNumeroReserva = item.numeroreserva, idReserva=item.idreserva,idHospede=item.idhospede, emailReserva = item.email]" title="Log Reserva">
                    mdi-eye
                  </v-icon>

                  <!-- <v-icon color="blue"
                    class="mr-1"
                    :data-cy="`acao-checkinpay_${item.numeroreserva}`"
                    @click="direcionarCheckinPay(item)" title="Iniciar o Checkin Pay.">
                    mdi-cash
                  </v-icon> -->

                  <!-- <v-icon color="green"
                    class="mr-1"
                    :data-cy="`acao-webcheckout_${item.numeroreserva}`"
                    @click="direcionarWebCheckout(item)" title="Iniciar o Checkout Express.">
                    mdi-cash
                  </v-icon> -->

                  <v-icon color="purple"
                  class="mr-1"
                    :data-cy="`acao-fichadigital_${item.numeroreserva}`"
                    @click="editItem(item)" title="Iniciar o preenchimento da Ficha Digital">
                    mdi-login
                  </v-icon>
            
                  <v-icon color="#005893"
                  class="mr-1"
                    :data-cy="`acao-fichadigital_${item.numeroreserva}`"
                    @click="frnhDownload(item)" title="Visualizar e Baixar FNRH">
                    system_update
                  </v-icon>

                  <v-icon color="brown"
                    :data-cy="`acao-notificar_${item.numeroreserva}`"
                    @click="notify(item), listarDados(item)" title="Envie notificações por email e WhatsApp">
                    mdi-email-send
                  </v-icon>
                  <v-icon color="blue" v-if="item.totalsolicitacoes>0"
                    @click="exibirSolicitacoes(item)" title="Solicitações do hóspede!">
                    mdi-chat-alert
                  </v-icon>

                  <v-icon color="green" v-if="item.numeroreserva>0"
                    @click="whatsappClickToChat(item)" title="Solicitações do hóspede!">
                    mdi-whatsapp
                  </v-icon>
            </template>

            <template v-slot:[`item.nome`]="{ item }">
              <v-icon class="pb-1" v-if="item.principal" small color="orange" title="Hóspede principal.">
                mdi-crown
              </v-icon>

               <v-icon class="pb-1 ml-2" v-if="item.webcheckineditado" small color="green" title="Realizado pelo web checkin.">
                mdi-cloud-upload
              </v-icon>
              
              <a @click="direcionarWebCheckin(item)" :title="item.idhospedepms" :data-cy="`td-nome_${item.numeroreserva}`">{{item.nome}}</a>

             

            </template>

            <template v-slot:[`item.sobrenome`]="{ item }">
              <a @click="direcionarWebCheckin(item)" >{{item.sobrenome}}</a>
            </template>

            <template v-slot:[`item.iconepreenchido`]="{ item }">
              <v-icon v-if="item.iconepreenchido" color="green" title="Ficha 100% preenchida!">
                mdi-check-circle
              </v-icon>
              <v-icon v-else color="red" :title="`${item.percentualpreenchido}% preenchido. ${item.faltampreencher}.`">
                mdi-account-alert
              </v-icon>
              <v-icon v-if="item.enviadopms" color="orange" title="Ficha enviada ao PMS.">
                mdi-clipboard-check
              </v-icon>
            </template>
              
            <template v-slot:[`item.datacheckinprevisto`]="{ item }">
              {{item.datacheckinprevisto | formatarDataYYHora}}
            </template>
            <template v-slot:[`item.datacheckoutprevisto`]="{ item }">
              {{item.datacheckoutprevisto | formatarDataYYHora}}
            </template>

     

      <template v-slot:[`footer.page-text`]>
        <v-btn color="primary" dark class="ma-2" @click="voltarPagina"> Voltar </v-btn>
        <v-btn color="primary" dark class="ma-2" @click="avancarPagina"> Avançar </v-btn>
      </template>

          </v-data-table>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                  <span>{{ snackText }}</span>
                  <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
        </v-card>
      </div>
    </v-flex>

    <ModalQrCodeWebcheckin @setModalQrCodeWC="(valor) => setModalQrCodeWC(valor)" :exibirModalQrCodeWC="exibirModalQrCodeWC"  :itemSelecionadoQRcode="itemSelecionadoQRcode" />

    <v-dialog v-model="modalBaixarFRNH" width="1000" max-width="80vw">
     <div v-if="modalBaixarFRNH">
      <FichaDigitalFRNH :id="reservaFRHN.id" :idhospede="reservaFRHN.idhospede" :possuiEmpresaReservante="reservaFRHN.empresa_reservante"   @update="updateList()"
      @fechar="modalBaixarFRNH=false" />
     </div>
    </v-dialog>

  </v-layout>
</template>

<script>

import {resizeImage} from '@/utils/serializar'
import ModalSolicitacoes from './Modals/ModalSolicitacoes'
import ReservaFd from "../stepfd/ReservaFd.vue";
import moment from "moment";
import ModalDirectMail from './Modals/ModalDirectMail.vue';
import ModalDirectWhatsApp from './Modals/ModalDirectWhatsApp.vue';
import ModalWebCheckin from './Modals/ModalWebCheckin.vue';
import orto from '../../utils/ortografia'
import ModalImportar from './Modals/ModalImportar'

import ModalQrCodeWebcheckin from './Modals/ModalQrCodeWebcheckin'
import dayjs from 'dayjs';

import ModalLogReservas from './Modals/ModalLogReserva.vue'
import { BUILD_TIPO_INTEGRACAO } from '../../constantes';
import ModalTutorial from './Modals/ModalTutorial.vue';

import BotaoTutorial from '../material/BotaoTutorial.vue';

import ModalRespostaWhatsapp from './Modals/ModalRespostasWhatsapp.vue';

import FichaDigitalFRNH from '../stepfd/FichaDigital.vue';

// import ModalChatWhatsapp from './Modals/ModalChatWhatsapp.vue';


export default {
  props: [],
  components: {
    ModalSolicitacoes,
    ModalDirectMail,
    ModalDirectWhatsApp,
    ReservaFd,
    ModalQrCodeWebcheckin,
    ModalLogReservas,
    ModalImportar,
    ModalTutorial,
    BotaoTutorial,
    ModalWebCheckin,
    ModalRespostaWhatsapp,
    FichaDigitalFRNH
    // ModalChatWhatsapp
},
  data: () => ({
    // sortBy: 'numeroreserva',
    // sortDesc: true,
    idhotelEs: null,
    dadosWebCheckin: null,
    somenteAtivas:'true',
    exibirModalWebCheckin: false,
    nomeHotel: 'carregando...',
    tag:{},
    emailReserva:null,
    hospedeMensagem: null,
    tags:[
      {id:1,valor:'TAG 1'},
      {id:2,valor:'TAG 2'},
      {id:3,valor:'TAG 3'},
      {id:4,valor:'TAG 4'},
      {id:5,valor:'TAG 5'},
      {id:6,valor:'TAG 6'},
      {id:7,valor:'TAG 7'},
      {id:8,valor:'TAG 8'},
      {id:9,valor:'TAG 9'},
      {id:10,valor:'TAG 10'},
      {id:11,valor:'TAG 11'},
      {id:12,valor:'TAG 12'},
      {id:13,valor:'TAG 13'},
      {id:14,valor:'TAG 14'},
      {id:15,valor:'TAG 15'},
      {id:16,valor:'TAG 16'},
      {id:17,valor:'TAG 17'},
      {id:18,valor:'TAG 18'},
      {id:19,valor:'TAG 19'},
      {id:20,valor:'TAG 20'}
    ],
    statusSincTag:false,
    exibirModalRespostaWhatsapp: false,
    pagina:1,
    itemSelecionadoQRcode:null,
    exibirModalQrCodeWC:false,
    textoBtnPequisar:'Pesquisar',
    idhospedeWhatsapp: null,
    respostaAcao : "",
    telefone : "",
    ddi : "",
    ddd : "",
    listadestatus:[],
    exibirModalSolicitacoes:false,
    exibirModalSolicitacoesResponder:false,
    exibirModalDirectMail : false,
    exibirModalLogReserva : false,
    modalBaixarFRNH: false,
    exibirModalDirectWhatsApp : false,
    exibirModalImportar : false,
    exibirModalTutorial: false,
    periodoImportacaoDefault: {
      DataInicial: dayjs(new Date()).format('YYYY-MM-DD'),
      DataFinal: dayjs(new Date()).add(7, 'day').format('YYYY-MM-DD'),
    },
    processando: (localStorage.getItem('processando')==='true'||localStorage.getItem('processando')==true),
    resultado: (localStorage.getItem('resultado')),
    isWhatsAppSet: false,
    isWhatsAppSetText : "",
    solicitacoes:[],
    solicitacao:null,
    itemselecionado:null,
    step: 0,
    maxStep: 10,
    filtroReserva:null,
    filtroSobrenome:null,
    filtroNome:null,
    filtroStatusReserva:null,
    reservaFRHN: {
      id: null,
      idhospede: null,
    },
    filtroDataInicial: moment().format('YYYY-MM-DD'),
    filtroDataFinal: moment().format('YYYY-MM-DD'),
    fichaDigital : {},
    
    logNumeroReserva : 0,
    idReserva : 0,
    idHospede: 0,

    descstatusreserva: "Confirmada",
    emailRules : [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    email: "",
    snack: false,
    snackColor: "",
    snackText: "",
    max25chars: (v) => v.length <= 25 || "Input too long!",
    pagination: {},
    ReservasList: [],
    checkboxAdmin: true,
    checkboxActive: true,
    numero9AntesDoTelefone: '9-',
    rowsAmount: [
      15,
      20,
      25,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
    ],
    dialog: false,
    dialog2: false,
    nomeHospede: null,
    search: "",
    headers: [
      { text: "Reserva", value: "numeroreserva", width: "120px", },
      { text: "Ações", value: "actions", width: "120px", sortable: false},
      { text: "UH", value: "uh", width: "50px" },
      { text: "Nome", value: "nome", width: "150px" },
      { text: "Sobrenome", value: "sobrenome", width: "150px" },
      { text: "Status", value: "iconepreenchido", width: "80px" },
      { text: "Status Reserva", value: "descricaostatusreserva", width: "80px" },
      { text: "Check-IN", value: "datacheckinprevisto", width: "130px" },
      { text: "Check-OUT", value: "datacheckoutprevisto", width: "130px" },
      { text: "Cliente", value: "empresareservante", width: "150px" },
      { text: "Tipo Hóspede", value: "tipohospede", width: "100px" },
      { text: "Pensão", value: "tipopensao", width: "190px" },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      sobrenome: "",
      email: "",
      isAdmin: true,
      isActive: true,
    },
    defaultItem: {},
    imgSrc:null,
    buttonEnabled: BUILD_TIPO_INTEGRACAO === 'ADMH' ? false : true,
    respostasExtras: [],
    horarioVoo: [],
    liberarNovoWebCheckin: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    disabledSearch(){ 
      return this.textoBtnPequisar != 'Pesquisar'
    },
    ativarImportacoes() {
      return localStorage.getItem('ativarimportacoes') == 'true'
    },
    isDisabledADMH(){
    return BUILD_TIPO_INTEGRACAO === 'ADMH' ? true : false;
  }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  // called when page is created before dom
  created() {
    this.buscaReservas();

  },

  async unmounted() {

  },

  async mounted(){
    await this.buscarLiberarWebCheckin()
    await this.verificarTagUsadaPorUsuario()

    if(localStorage.getItem('tag')>0){
      this.statusSincTag = true
      let tagfiltrada = this.tags.filter(x=>{
          return x.id == localStorage.getItem('tag')
      })
      if(tagfiltrada && tagfiltrada.length>0){
        this.tag = tagfiltrada[0]
      }
      
    }else{
      this.statusSincTag = false
    }

    this.$socket.disconnect();
    this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
    this.$socket.io.opts.query.tipo = 'DASHBOARD'
    this.$socket.io.opts.query.idhotel = 1
    this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
    this.$socket.connect();
    await this.listarStatusReservas()
    await this.buscarDadosAPI();
    window.addEventListener('processando-changed', (event) => {
        this.processando = (event.detail.processando==='true'||event.detail.processando==true)
        this.resultado = event.detail.resultado
    });
  },
  methods: {

    downloadBlob(blob, name = 'file.txt') {
        if (
          window.navigator && 
          window.navigator.msSaveOrOpenBlob
        ) return window.navigator.msSaveOrOpenBlob(blob);

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = data;
        link.download = name;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    },
    onFileChange(e) {
      
 
            var files = e.target.files || e.dataTransfer.file
            var src = URL.createObjectURL(files[0]);

            document.querySelector("#original").src = src;

            resizeImage(src, {width: 400}).then(function (blob) {
              console.log('ResizeImage: ',blob)

              var reader = new FileReader()
              reader.readAsDataURL(blob); 
              reader.onloadend = function() {
                var base64data = reader.result;                
                console.log('base64data: ',base64data);
              }

              document.querySelector("#resized").src = URL.createObjectURL(blob)
            })

        // resizeImage(e, {width:200}).then(resp =>{
        //     this.imgSrc = resp.dados
        //     // this.salvaLogo(resp.tipo);
        // }).catch(error =>{
        //     this.loading = false
        //     this.snack = true
        //     this.snackColor = "error" 
        //     this.snackText = error.message
        // })        
    },

    eModalWebCheckin(item) {
      console.log(item, 'chegou')
      if(item) {
        this.dadosWebCheckin = {
          idreserva: item.idreserva,
          idHotelEs: this.idhotelEs,
        };
      }

      this.exibirModalWebCheckin = !this.exibirModalWebCheckin;
    },
    verificarTagUsadaPorUsuario(){
        let objeto = {
          idusuario:localStorage.getItem('userid')
        }

        this.$http.post("/tag/verificarTagUsadaPorUsuario",objeto).then(respV=>{
              if(respV.data && respV.data.length > 0 ){

                    console.log("respV> >>> ",respV)

                    objeto = {
                      idusuario:respV.data[0].id,
                      idtag:respV.data[0].idtag,
                    }
                    
                    this.$http.post("/tag/atualizarUso",objeto).then(respup=>{
                        if(respup.status == 200){
                              this.tag ={
                                id:objeto.idtag,
                                valor:'TAG '+objeto.idtag
                              }
                              localStorage.setItem('tag',this.tag.id)
                              this.$socket.disconnect();
                              this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
                              this.$socket.io.opts.query.tipo = 'DASHBOARD'
                              this.$socket.io.opts.query.idhotel = localStorage.getItem('idhotelpms')
                              this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
                              this.$socket.io.opts.query.tag = 'TAG_'+this.tag.id
                              this.$socket.connect();

                              if(localStorage.getItem('tag')>0){
                                this.statusSincTag = true
                              }else{
                                this.statusSincTag = false
                              }
                        }else{
                            console.log("Sincronização NÃO Realizada")
                        }
                       
                    })

              }
            
        })
        
    },
    sincronizarTag(){
      let objeto = {
        idusuario:localStorage.getItem('userid'),
        idtag:this.tag.id
      }
      this.$http.post("/tag/verificarDisponibilidade",objeto).then(resp=>{
            if(resp.data && resp.data.length == 0 ){
                  console.log("Com Disponibilidade")
                  this.$http.post("/tag/criarUso",objeto).then(respcriar=>{
                      if(respcriar.status == 200){
                            localStorage.setItem('tag',this.tag.id)
                            this.$socket.disconnect();
                            this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
                            this.$socket.io.opts.query.tipo = 'DASHBOARD'
                            this.$socket.io.opts.query.idhotel = localStorage.getItem('idhotelpms')
                            this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
                            this.$socket.io.opts.query.tag = 'TAG_'+this.tag.id
                            this.$socket.connect();

                            if(localStorage.getItem('tag')>0){
                              this.statusSincTag = true
                            }else{
                              this.statusSincTag = false
                            }
                      }else{
                          console.log("Sincronização NÃO Realizada")
                      }
                      // console.log("RESPCRIAR", respcriar)
                  })

            }else{
              
              alert("Tag já está sendo utilizada pelo usuario "+resp.data[0].apelido)
            }
           
      })

      
    },
    desincronizarTag(){

        

        let objeto = {
          idusuario:localStorage.getItem('userid'),
          idtag:this.tag.id
        }

        this.$http.post("/tag/removerUso",objeto).then(respremover=>{
            if(respremover.status == 200){
                this.$http.post("/tag/verificarDisponibilidade",objeto).then(resp=>{
                      if(resp.data && resp.data.length == 0 ){
                              localStorage.removeItem('tag')
                              this.statusSincTag = false
                              this.tag =null

                              this.$socket.disconnect();
                              this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
                              this.$socket.io.opts.query.tipo = 'DASHBOARD'
                              this.$socket.io.opts.query.idhotel = localStorage.getItem('idhotelpms')
                              this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
                              this.$socket.io.opts.query.tag = null
                              this.$socket.connect();

                      }
                })
            }
        })



    },
    avancarPagina(){
      this.pagina = this.pagina + 1
      this.buscaReservas();
    },
    voltarPagina(){
      if(this.pagina > 1){
        this.pagina = this.pagina - 1
        this.buscaReservas();
      }
    },
    setQrCodeModal(item){
      this.setModalQrCodeWC(true)
      this.itemSelecionadoQRcode = item
    },
    setModalQrCodeWC(valor){
        this.exibirModalQrCodeWC = valor
    },
    async listarStatusReservas() {
      await this.$http
        .post("/geo/statusreserva/select",{orderby: "descricao", distinct: true})
        .then((response) => {
            this.listadestatus = response.data;
            let statusreservapadrao = {id:-1,descricao:'Todos'}
            this.listadestatus.push(statusreservapadrao)
            this.listadestatus = orto.ordenarArray(this.listadestatus, "descricao")
            this.filtroStatusReserva = statusreservapadrao
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async buscarLiberarWebCheckin() {
            try {
            const result = await this.$http.post("configWebCheckin/buscarLiberarNovoWebCheckin/")
            console.log('config webcheckin', result.data)
            if(result.data.length > 0) {
                this.liberarNovoWebCheckin = result.data[0].valor === 'true' ? true : false
            } else {
                this.liberarNovoWebCheckin = false
            }
            } catch (error) {
                console.log(error);
            }
        },

    direcionarWebCheckin(item){
        // alert(JSON.stringify(item.id))
        console.log(item);
        let urlwebcheckin;
        if(!this.liberarNovoWebCheckin) {
           urlwebcheckin = window.location.origin+'/webcheckin/'+item.id
        } else {
          urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${item.idreserva}`;
        }
        window.open(urlwebcheckin,'_blank')
    },
    direcionarWebCheckout(item){
        let urlwebcheckout = window.location.origin+'/webcheckout/'+item.hotel
        window.open(urlwebcheckout,'_blank')
    },
    direcionarCheckinPay(item){
        let urlcheckinpay = window.location.origin+'/checkinpay/'+item.token
        window.open(urlcheckinpay,'_blank')
    },
    setModalSolicitacoes(valor){
        this.exibirModalSolicitacoes = valor
    },
    setModalImportar(valor) {
        this.exibirModalImportar = valor
    },
    importar(periodo) {
      // importar reservas no período informado
      //console.log(JSON.stringify(periodo))
      const params = {
        id_hotel: localStorage.getItem('idhotelpms'),
        useremail: localStorage.getItem('useremail'),
        periodo: periodo
      }
      
      console.error(`importar(params): ${JSON.stringify(params)}`);

      this.$http.post('reserva/importar/periodo/',params)
      .then(async () => {
        localStorage.setItem('processando',true)
        this.processando = true
        localStorage.setItem('resultado','')
        this.resultado = ''
        // Este trecho foi colocado no App.vue para liberar o usuário para outras funções...
        this.sockets.subscribe("backend_to_dash_importar_reservas_retorno", (dados) => {
            this.sockets.unsubscribe("backend_to_dash_importar_reservas_retorno")
            //console.error(dados)
            this.processando = false
            this.resultado = dados.retorno+' '+JSON.stringify(dados.periodo)
        });
      })
    },    
    limparFiltro() {
      this.pagina = 1
      this.filtroReserva = null
      this.filtroNome = null
      this.filtroSobrenome = null
      this.filtroDataInicial = null
      this.filtroDataFinal = null
      let statusreservapadrao = {id:-1,descricao:'Todos'}
      this.filtroStatusReserva = statusreservapadrao

      // this.buscaReservas()
    },
    buscarReservasPeloBtn(){
      this.pagina = 1
      this.buscaReservas()
    },
    async buscaReservas() {

      
      
      this.textoBtnPequisar = "Pesquisando"

      if(!this.filtroDataInicial && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
        this.filtroDataInicial = dayjs().format('YYYY-MM-DD')
      }
      if(!this.filtroDataFinal && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
        this.filtroDataFinal = dayjs().format('YYYY-MM-DD')
      }
      let dados = {  
        "hotel" : localStorage.getItem('hotel'),
        "idhotelpms" : localStorage.getItem('idhotelpms'),
        "idioma" : "pt-BR",
         "datacheckinprevisto" : {
           "de": this.filtroDataInicial,
           "ate": this.filtroDataFinal
        },
        "orderby": 'numeroreserva desc, ordem asc',
        "importar": true,
      }

      if(this.somenteAtivas){
        dados.ativo = true
      }

      if(this.filtroReserva){
        dados.numeroreserva = this.filtroReserva
      }

      if(this.filtroNome){
        dados.nome = this.filtroNome
      }

      if(this.filtroSobrenome){
        dados.sobrenome = this.filtroSobrenome
      }

      if(this.filtroStatusReserva && this.filtroStatusReserva.id >= 0){
        dados.idstatusreserva = this.filtroStatusReserva.id
      }


    try {
      
      const result = await this.$http.post('/configWebCheckin/buscarIdHotelEs');
      this.idhotelEs = result.data[0].id_hotel_cliente_es;
      dados.pagina = this.pagina
      this.$http.post("/reserva/select", dados)
      .then((response) => {
        console.log(response.data)
          if (response.status == 200) {
            this.textoBtnPequisar = "Pesquisar"
            this.ReservasList = response.data;
            this.$forceUpdate();
            // if(response.data.length == 0){
            //     if(this.filtroReserva){
            //       //se não encontrar a reserva pesquisada pelo numero então solicita importação ao robô.
            //       this.$socket.emit('dash_backend_importa_reserva', {reserva:this.filtroReserva})  
            //     }
            // }
          }else{
            this.textoBtnPequisar = "Pesquisar"
            this.snackColor= "orange"
            this.snackText= "Nenhum registro encontrado"
            this.snack = true
          }
        })
        .catch((error) => {
          this.textoBtnPequisar = "Pesquisar"
          console.error(error)
          this.ReservasList = []
          //alert('Nenhuma Reserva foi Encontrada')
        });

      } catch (error) {
        this.textoBtnPequisar = "Pesquisar"
        alert(error)
      }
    },

    ModalRespostaWhatsapp() {
      if(this.telefone.length > 8) {
        return
      }
      this.exibirModalRespostaWhatsapp = !this.exibirModalRespostaWhatsapp;
    },

    avancar() {
      if (this.step < this.maxStep) {
        this.step++;
      }
    },

    voltar() {
      if (this.step > 0) {
        this.step--;
      }
    },
    isNumber($event){
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
          $event.preventDefault();
      }
    },    
    // object.assign fills in the empty object with the properties of item
    editItem(item, dbox = true) {
      this.editedIndex = this.ReservasList.indexOf(item);
      this.step = 0;
      this.editedItem = Object.assign({}, item);
      this.fichaDigital = this.editedItem
      this.dialog = dbox;
      localStorage.setItem('idHotelEs', this.idhotelEs);
      localStorage.setItem('idreserva', item.idreserva);
      localStorage.setItem('idpessoa', this.editedItem.idpessoa);
      console.log('setando local storage', this.editedItem)
    },
    async consultarSolicitacoes(reservahospede){
        await this.$http.post('reserva/solicitacao/select',{
                reservahospede: reservahospede,
                todas: true
            })
            .then(resp =>{
              //console.log(resp.data)
              this.solicitacoes = resp.data.filter(item=> item.tipo == 0 && item.solicitacao);
              this.respostasExtras = resp.data.filter(item=> item.tipo == 1);
              console.log('respostas extras >>>>>>>', resp.data)
              this.horarioVoo = resp.data.filter(item=> item.tipo == 2);
            })
    },

    async exibirSolicitacoes(item) {
      this.itemselecionado = item
      console.log("Chamando a exibirSolicitacoes")
      this.exibirModalSolicitacoes = !this.exibirModalSolicitacoes
      this.exibirModalSolicitacoesResponder = false
      await this.consultarSolicitacoes(item.id)
    },
    whatsappClickToChat(item){

      try {
        
        let ddi = item.celularddi.trim()
        let ddd = item.celularddd.trim()
        let telefone = item.celular.trim().replace('-','').replace('-','')
        let telefoneCompleto = ddi+ddd+telefone
        let urlwebcheckin;
        if(!this.liberarNovoWebCheckin) {
           urlwebcheckin = window.location.origin+'/webcheckin/'+item.id
        } else {
          urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${item.idreserva}`;
        }
        let urlzap = `https://wa.me/${telefoneCompleto}?text=Olá, evite filas ao chegar no hotel, segue o link para o seu web checkin no *${localStorage.getItem('nomehotel')}*: ${urlwebcheckin}.`
        window.open(urlzap,'_blank')

      } catch (error) {
        alert('Não foi possível enviar, verifique o telefone do hospede.') 
      }

    },
    notify(item, dbox = true) {
      this.hospedeMensagem = item;
      this.editedIndex = this.ReservasList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog2 = dbox;
      this.respostaAcao = ""
    },
    listarDados(item) {
      console.log(item)
      this.idhospedeWhatsapp = item.idhospede;
      this.nomeHotel = item.nomehotel;
      this.nomeHospede = item.nome
      console.log('Iniciando')
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: item.idhospede,
          idreserva: item.idreserva
          
        })
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          this.ddi = response.data[0].celularddi;
          this.ddd = response.data[0].celularddd;
          this.telefone = response.data[0].celular;
          this.email = response.data[0].email;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  async  salvarDadosFone(item) {
      // this.telefone = this.telefone.string.replace(/[^a-zA-Z0-9]/g, "");
      this.idhospedeWhatsapp = item.idhospede
      console.log(this.nomeHospede)
       if(!this.ddi.length > 0 && !this.ddd.length > 0 && !this.telefone.length > 0){
        //this.respostaAcao = "Dados de telefone inválidos. Verifique"
        return
      }

      let body = {
        reserva: {
          idreservapms:item.idreservapms,
          id: item.idreserva,
          hotel: localStorage.getItem("hotel"),
        },
        hospede: {
          idhospedepms:item.idhospedepms,
          id: item.idhospede,
          celularddi: this.ddi,
          celularddd: this.ddd,
          celular: this.telefone
        },
      };

      //console.log("body")
      //console.log(body)
     await this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            console.log("Telefone salvo na ficha do hóspede.")
            this.cadastrarWhatsappContato(item)
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          alert('Erro ao salvar dados na ficha do hóspede.')
        });

    },

    async cadastrarWhatsappContato(item) {

      const body = {
        id_pessoa: item.idhospede,
        nome_pessoa: item.nome,
        ddi: this.ddi,
        ddd: this.ddd,
        telefone: this.telefone,
      }

      console.log(body)

      this.$http
        .post("/whatsapp/cadastrarWhatsapp", body)
        .then(() => {
            console.log("whatsapp cadastrado.")

        })
        .catch((error) => {
          console.log(error);
          alert('erro ao cadastrar telefone.')
        });
    },
    setModalDirectMail(){
      if(/.+@.+\..+/.test(this.email)){
        this.exibirModalDirectMail = !this.exibirModalDirectMail
        this.respostaAcao = ""
        //this.salvarDadosEmail(item)
      }else{
        this.respostaAcao = 'Informe o e-mail corretamente.'
      }
    },
    salvarDadosEmail(item) {
      console.log("item")
      console.log(item)
      if(!this.email.length > 0){
        return
      }
      let body = {
        reserva: {
          id: item.idreserva,
          idreservapms:item.idreservapms,
          hotel: localStorage.getItem("hotel"),
        },
        hospede: {
          idhospedepms:item.idhospedepms,
          id : item.idhospede,
          email: this.email
        },
      };

      //console.log("body")
      //console.log(body)
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            console.log('Email salvo na ficha do hóspede.')
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          console.log('Erro ao salvar Email na ficha do hóspede')
        });
    },

    setModalLogReserva(){
        this.exibirModalLogReserva = !this.exibirModalLogReserva
    },
    setModalDirectWhatsApp(){
      if(this.telefone.length > 8) {
        return
      }

      if(this.telefone.length > 0){
        this.exibirModalDirectWhatsApp = !this.exibirModalDirectWhatsApp
        this.respostaAcao = ""
      }else{
        this.respostaAcao = 'Informe numero WhatsApp corretamente.'
      }
        
    
    },

    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.editedItem = Object.assign({}, item);
        let endpoint = `users/delete/${this.editedItem.username}`;
        let method = "delete";
        this.callTableAction(item, endpoint, method);
      }
    },

    close() {
      this.dialog = false;
      this.dialog2 = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    enviarEmail(){
      if(this.email.length > 0){
        console.log(this.editedItem)
        this.$http.post('/reserva/setReenvioWebcheckin', { 
            id:this.editedItem.id,
            idpessoa:this.editedItem.idpessoa,
            idtipo:7,
            email:this.email,
            }).then(response =>{
            console.log(response)
            this.respostaAcao = "E-mail enviado com sucesso."
             setTimeout(() => { 
             this.dialog2 = false
             }, 1000);
          }).catch(error=> {
            console.log(error)
            console.log(error.data)
          })

        // this.respostaAcao = ""
        // let link = window.location.origin+'/webcheckin/'+this.editedItem.id
        // let body = {
        //   destinatario : this.email,
        //   assunto : "Chekin sem filas",
        //   text :  link,
        //   nomeusuario : "Hotel Modelo",
        //   hotel:localStorage.getItem("hotel")
        // }
       

        //   this.$http.post('/email/enviarEmail', body).then(response =>{
        //     console.log(response)
        //     this.respostaAcao = "E-mail enviado com sucesso."
        //     setTimeout(() => { 
        //       this.dialog2 = false
        //     }, 1000);
            
            
        //     this.email = ""
        //   }).catch(error=> {
        //     console.log(error)
        //     console.log(error.data)
        //   })
        }else{
          this.respostaAcao = "E-mail não pode estar vazio."
          return
        }
        
    },
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.isWhatsAppSet = resp.data[0].valor == "true" ? true : false;
            if(!this.isWhatsAppSet){
              this.isWhatsAppSetText = "Verifique as configuração da API WhatsAPP."
            }
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setModalTutorial(){
        this.exibirModalTutorial = !this.exibirModalTutorial
    },

    frnhDownload(reserva) {
      const empresa = this.buscarEmpresaReservante()
      const dados = {
        id: reserva.id,
        idhospede: reserva.idhospede,
        empresa_reservante: empresa,
      }
      console.log(dados);
      this.reservaFRHN = dados;
      this.modalBaixarFRNH = true;
      console.log(this.modalBaixarFRNH)
    },
   
    buscarEmpresaReservante() {
      var retVal = null
      for (var i = 0; i < this.ReservasList.length; i++) {
        var er = this.ReservasList[i]['empresareservante']?this.ReservasList[i]['empresareservante']:''
        er = er.trim()
        if (er) {
          retVal = this.ReservasList[i]['empresareservante']
          break;
        }
      }
      console.log('funcao buscar empresa resevante >>>>>>', retVal)
      return retVal
    },

    possuiEmpresaReservante () {
      return this.buscarEmpresaReservante()
    },

    // disableButtonImportacao(){
    //   if(BUILD_TIPO_INTEGRACAO == 'ADMH'){
    //     return this.buttondisabled = true
    //   }
    // },

  },
}
</script>

<style>
table.v-table thead tr {
  color: red !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.card-outter {
  position: relative;
  padding-bottom: 50px;
  width: auto;
  height: 700px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  align-items: center;
}

.v-select__selections{
  padding-left:10px;
}

.respostasWhatsapp {
  margin: 10px;
}

.NotificacaoWhatsapp {
  width: 300px;
  border-radius: 15px 15px 0px 15px;
  box-shadow: 0px 5px 10px rgb(151, 151, 151);
  background-color: #0084FF;
  padding: 5px;
  color: white;
  position: fixed;
  right: 0;
}
</style>

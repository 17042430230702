<template>
  <v-card style="height: 400px !important; width: 500px;margin:auto;">
    <v-card-title class="primary">
      <span style="color:white;">Hotéis Disponíveis</span>
    </v-card-title>
    <v-card-text>
    <div style="width:100%;display:flex;flex-direction:row;flex-wrap:wrap;margin:1.5vh!important;">
        <v-btn @click="$emit('change',hotel)" v-for="(hotel, index) in listaHoteis" :key="index"
          style="min-width:90%;max-width:90%;min-height:30%;background-color:#2196f3;color:white!important;border-radius:5px;margin:10px;padding:10px;">
          <div style="max-height:50%;display:flex;justify-content:center;font-weight:bold;">
              {{hotel}}
          </div>
        </v-btn>
    </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["listaHoteis"]
}
</script>
<template>
  <v-dialog v-model="show" width="90vw" max-width="500px" persistent>
    <Loading v-if="loading"/>
    <div style="display: flex; flex-direction: column; background-color: white;" v-if="!loading">
      <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
        <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
          <span class="toolbar-title" style="width:80%;padding:10px;"> Email Web Check-in </span>
          <span style="width:5%"> 
            <v-btn x-small color="red" @click="() => this.$emit('fecharModalEmailWebCheckin')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <div style="padding: 10px; width: 100%;">
        <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
          <v-switch v-model="switch1" style="border-radius:5px;padding:5px;"></v-switch>
          <label>Enviar e-mail Web Check-in concluído?</label>  
        </div>
        <label>Texto Email:</label>  
        <textarea v-model="mensagem"  style="width: 100%;border: 1px solid black; border-radius: 10px; padding:8px" rows="4" cols="50"></textarea>
        <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
          <v-switch v-model="switch2" style="border-radius:5px;padding:5px;"></v-switch>
          <label >Enviar termo de serviço como anexo?</label>  
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; padding: 10px; width: 100%; align-items: flex-end; background-color: white;">    
      <p style="font-size: 12px; margin-top: 5px;" v-if="configSalvas">Configurações salvas com sucesso!</p>
      <button class="buttonsalvar" @click="salvarConfig">{{ textoBotao }}</button>
    </div>
   </v-dialog>
  </template>
  
  <script>
    import Loading from './Loading.vue';
   export default {
    props:['show'],
    components: {
      Loading
    },
    data() {
      return {
        loading: false,
        textoBotaoSalvar: 'Salvar',
        switch1: false,
        switch2: false,
        id: null,
        mensagem: null,
        textoBotao: 'Salvar',
        configSalvas: false,
      }
    },
    methods: {
  
      async requisitarConfig() {
        try {          
          const result = await this.$http.post('/configWebCheckin/buscarConfigEmailWebCheckin');
        if(result.data.length > 0) {
          this.mensagem = result.data[0].mensagem;
          this.switch1 = result.data[0].enviaremail;
          this.switch2 = result.data[0].anexo_termo;
          this.id = result.data[0].id
        }
        } catch (error) {
          console.log(error)
        }
      },

      async salvarConfig() {
        try {          
          this.textoBotao = 'Aguarde...'
          await this.$http.post('/configWebCheckin/salvarConfigEmailWebCheckin', {
            id: this.id,
            mensagem: this.mensagem,
            anexo_termo: this.switch2,
            enviaremail: this.switch1,
          });
          await this.requisitarConfig();
          this.textoBotao = 'Salvar'
          this.configSalvas = true;
         setTimeout(() => {
          this.configSalvas = false;
            console.log("ok");
          }, "4000")
        } catch (error) {
          console.log(error)
        }
      }
    },
    async mounted() {
      this.loading = true;
      await this.requisitarConfig();
      this.loading = false;
    }
   }
  </script>
  
  
  <style scoped>
  .buttonsalvar {
    background-color: #2B81D6;
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
    font-weight: 100;
    width: 100px;
  }
  </style>